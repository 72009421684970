import { Card, Col, ConfigProvider, Empty, Row, Spin, Table, Tag, theme } from "antd";
import './invoices.scss';
import ReactECharts from "echarts-for-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchInvoices, getAnalyticsTotal, setMonthInvoices } from "../../store/actions/invoice.action";
import Formatter from "../../utils/Formatter";


const Invoices = () => {
    // Extract required values from the theme token using useToken hook
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();
    // Define state variables using useState hook
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [graphLoading, setGraphLoading] = useState(false);
    // Extract relevant data from Redux store using useSelector hook
    const filter = useSelector((state) => state.filter);
    const analytics = useSelector((state) => state.invoice?.analytics);
    const invoice = useSelector((state) => state?.invoice);
    const state = useSelector(state => state);

    // Fetch data on initial load and when certain filters change using useEffect hook
    useEffect(() => {
        // setMonth('January')
        setLoading(true);
        dispatch(getAnalyticsTotal())
        .then(() => setLoading(false))
        .catch(() => setLoading(false))

        setGraphLoading(true);
        dispatch(fetchInvoices())
        .then(() => setGraphLoading(false))
        .catch(() => setGraphLoading(false))
    }, [filter.year, state.company?.selected, state.company?.client])

    // Update invoices based on the filter type using useEffect hook
    useEffect(() => {
        dispatch(setMonthInvoices())
    }, [filter.invoiceType])

    // Define columns for the table
    let columns = [
        {
            title: 'Invoice Id',
            dataIndex: 'InvoiceMasterId',
            sorter: (a, b) => a.InvoiceMasterId - b.InvoiceMasterId
        },
        {
            title: 'Invoice Date',
            dataIndex: 'TxnDate',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.TxnDate.localeCompare(b.TxnDate)
        },
        {
            title: 'Company Name',
            dataIndex: 'CustomerName',
        },
        // {
        //     title: 'Client ID',
        //     dataIndex: 'ClientId'
        // },
        {
            title: 'Amount',
            dataIndex: 'TotalAmt',
            sorter: (a, b) => a.TotalAmt - b.TotalAmt,
            render: (e) => Formatter.numberWithCommas((e || 0)?.toFixed(2))
        },
        {
            title: 'Currency',
            dataIndex: 'Currency',
        },
        {
            title: 'Due Date',
            dataIndex: 'DueDate'
        },
        {
            title: 'Payment Date',
            dataIndex: 'PaymentDate',
        },
        {
            title: 'Payment Status',
            dataIndex: 'invoiceStatus',
            render: (status) => <Tag color={ 
                status == "Paid" ? "#54c9e8" : 
                status == "Pending" ? "#226885" : "#ff876f"
            }
            >{status}</Tag>
        }
    ]

    // Define options for the scatter chart
    const getOption = () => {
        return {
            tooltip: {
                position: 'top',
                trigger: 'item',
                axisPointer: {
                type: 'cross'
                }
            },
            xAxis: {
                name: 'Invoice Date',
                nameLocation: 'bottom',
                nameGap: 0,
                nameTextStyle: {
                    verticalAlign: 'top',
                    padding: [25, 0, 0, 0],
                },
                type: 'time',
                axisLine: {
                    lineStyle: {
                        color: colorText, 
                    },
                },

                axisTick: {
                    show: true,
                },
                axisLabel: {
                    interval: 0,
                    formatter: (axisValue, index) => {
                      // Add logic to display alternate labels
                      if (index % 2 !== 0) {
                        return moment(axisValue).format("YYYY-MM-DD");
                      } else {
                        return ''; // Empty string for alternate labels
                      }
                    }
                  },
                  
            },
            yAxis: {
                name: 'Payment Date',
                type: 'time',
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: colorText, 
                      },
                  },
                axisTick: {
                    show: true,
                },
                splitLine: {
                    show: true,
                    lineStyle: {
                        color: colorText,
                    },
                },
                axisLabel: {
                    interval: 0,
                    formatter: (axisValue, index) => {
                      // Add logic to display alternate labels
                      if (index % 2 == 0) {
                        return moment(axisValue).format("YYYY-MM-DD");
                      } else {
                        return ''; // Empty string for alternate labels
                      }
                    }
                },
            },
            grid: {
                top: 30,
                left: 10,
                right: 40,
                bottom: 20,
                containLabel: true,
    
            },
            series: [{
                data: invoice.scatter,
                type: 'scatter'
            }]
        }
    };
    
    // Modify data for the bar chart
    const xAxisData = invoice?.bar?.map(({ month }) => month);

    // Modify xAxis to have December as the first element
    const reorderedXAxisData = ["December"].concat(xAxisData?.slice(0, -1));

    // Modify invoice data to have December as the first element
    const reorderedBar = [...(invoice?.bar || [])];
    if (reorderedBar.length > 0) {
        const decemberItem = reorderedBar.pop();
        reorderedBar.unshift(decemberItem);
    }
    
    // Define the data for each series in the chart
    const seriesData = [
        // Line chart for the number of invoices per month
        {
            name: 'Invoices',
            type: 'line',
            data: reorderedBar.map(({ invoices }) => invoices),
            itemStyle: {
                color: 'green'
            }
        },
        // Stacked bar chart for the number of paid invoices per month
        {
            name: 'Paid',
            type: 'bar',
            stack: 'status',
            data: reorderedBar.map(({ paid }) => paid),
            itemStyle: {
                color: '#54c9e8'
            }
        },
        // Stacked bar chart for the number of pending invoices per month
        {
            name: 'Pending',
            type: 'bar',
            stack: 'status',
            data: reorderedBar.map(({ pending }) => pending),
            itemStyle: {
                color: '#226885'
            }
        },
        // Stacked bar chart for the number of overdue invoices per month
        {
            name: 'Overdue',
            type: 'bar',
            stack: 'status',
            data: reorderedBar.map(({ overdue }) => overdue),
            itemStyle: {
                color: '#ff876f'
            }
        },
        // Stacked bar chart for the total amount of invoices per month
        {
            name: 'Total',
            type: 'bar',
            yAxisIndex: 1,
            stack: 'amount',
            data: reorderedBar.map(({ total }) => total),
            itemStyle: {
                color: '#203870'
            }
        }
    ];

  // Define options for the bar/line chart
    const option = {
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        legend: {
            data: ['Invoices', 'Paid', 'Pending', 'Overdue', 'Total'],
            align: 'left',
            top: 0,
            textStyle: {
                color: colorText
            },
        },
        grid: {
            left: '3%',
            right: '30',
            bottom: '3%',
            containLabel: true
        },
        xAxis: {
            type: 'category',
            boundaryGap: true,
            data: reorderedXAxisData,
            axisLine: {
                lineStyle: {
                  color: colorText, 
                },
            },
        },
        yAxis: [
            {
                type: 'value',
                name: 'Invoices',
                axisLabel: {
                formatter: '{value}',
                },
                axisLine: {
                    lineStyle: {
                      color: colorText, 
                    },
            },
        },
            {
                type: 'value',
                name: 'Amount ($)',
                axisLabel: {
                formatter: '${value}',
                },
                axisLine: {
                    lineStyle: {
                      color: colorText, 
                    },
                
            },
        },
            
        ],
        series: seriesData
    };

    return (
        <Row className="invoices-container" >
            <Col flex={"100%"}>
                <Row gutter={20}>
                    {invoice.scatter.length > 0 ? 
                    (
                        <Col flex={"50%"}>
                            <Spin spinning={graphLoading}>
                                <Card style={{backgroundColor: colorBgLayout}}><ReactECharts style={{width: '100%'}} option={getOption()} /></Card>
                            </Spin>
                        </Col>
                    ) : (
                        <Col flex={"50%"}>
                            <Card style={{ backgroundColor: colorBgLayout, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            No Payment Data Yet
                            </Card>
                        </Col>
                    )}
                    <Col flex={"50%"}>
                        <Spin spinning={graphLoading}>
                            <Card style={{backgroundColor: colorBgLayout}}><ReactECharts style={{width: '100%'}} option={option} /></Card>
                        </Spin>
                    </Col>
                </Row>
                <br/>
                <Row className="invoice-cards" wrap={false} gutter={20} >
                    <Col flex={"33.33%"}>
                        <Card loading={loading} className="invoice-widget-card" title={`Paid Inovices (${analytics?.paid?.count})`} bordered={false}>
                            <Row justify={"center"} style={{fontSize: 32}}>
                                ${Formatter.numberWithCommas((analytics?.paid?.totalAmt || 0)?.toFixed(2))}
                            </Row>
                        </Card>
                    </Col>
                    <Col flex={"33.33%"}>
                        <Card loading={loading} className="invoice-widget-card"  title={`Pending Inovices (${analytics?.pending?.count})`} bordered={false}>
                            <Row justify={"center"} style={{fontSize: 32}}>
                                ${Formatter.numberWithCommas((analytics?.pending?.totalAmt || 0)?.toFixed(2))}
                            </Row>
                        </Card>
                    </Col>
                    <Col  flex={"33.33%"}>
                        <Card loading={loading} className="invoice-widget-card" title={`Overdue Inovices (${analytics?.unpaid?.count})`} bordered={false}>
                            <Row justify={"center"} style={{fontSize: 32}}>
                                ${Formatter.numberWithCommas((analytics?.unpaid?.totalAmt || 0).toFixed(2))}
                            </Row>
                        </Card>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col flex={"auto"}>
                        <ConfigProvider
                            renderEmpty={() => <Empty 
                                image="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg"
                                imageStyle={{ height: 100 }}
                                description={
                                    <span>
                                        <b>
                                            Sorry, but there are currently no invoices available for this account. Please reach out to the company administrator for further assistance. 
                                            <span><i> Thank you!</i></span>
                                        </b>
                                    </span>
                                }
                            />}
                        >
                            <Table
                                size="middle"
                                className="voyce-custom-table" 
                                columns={columns}
                                dataSource={invoice.invoices}
                            ></Table>
                        </ConfigProvider>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default Invoices;