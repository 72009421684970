import ActionType from '../action.types';
import Service from '../../services';
import { message } from 'antd';
import moment from 'moment';

//action to set filter date
export function setFilterDate(date) {
    return {
        type: ActionType.SET_DATE,
        date
    };
}

export function setDateRange(dateRange) {
    return {
        type: ActionType.SET_DATE_RANGE,
        dateRange
    };
}

//action to set language type
export function setLanguageType(languageType) {
    return {
        type: ActionType.SET_LANGUAGE_TYPE,
        languageType
    };
}

//action to set invoice type
export function setInvoiceType(invoiceType) {
    return {
        type: ActionType.SET_INVOICE_TYPE,
        invoiceType
    };
}

//action to set year
export function setYear(year) {
    return {
        type: ActionType.SET_DATE_YEAR,
        year: {
            maxDate: moment(year).endOf("year").endOf("month").endOf("days").utc().toDate(),
            minDate: moment(year).startOf("year").startOf("month").add("days", 1).utc().toDate(),
        }
    };
}


// Actions for setting and resetting previous time range
export function setPrevTimeRange(timeRange) {
    return {
        type: ActionType.SET_PREV_TIME_RANGE,
        timeRange
    };
}

export function resetPrevTimeRange() {
    return {
        type: ActionType.RESET_PREV_TIME_RANGE,
    };
}
