import { combineReducers } from 'redux';
import profile from './profile.reducer';
import company from './company.reducer';
import filter from './filter.reducer';
import analytics from './analytics.reducer';
import selectedColumns from './selectedColumns';
import language from './language.reducer';
import userManagement from './userManagement.reducer';
import invoice from './invoice.reducer';

// import reducers and add them to the below object.
export default combineReducers({
    profile,
    company,
    filter,
    analytics,
    userManagement,
    selectedColumns,
    language,
    invoice
});
