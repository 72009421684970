import React, { useState } from "react";
import { Select } from "antd";
import "./dropdown.scss";

const DropDown = (props) => {
  return (
    <Select
      defaultValue={props.defaultValue}
      className="select-div"
      onSelect={props.handleTypeSelect}
      options={props.dropdownOptions}
    />
  );
};

export default DropDown;
