import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  Form,
  Input,
  Select,
  Avatar,
  message,
  Tabs,
  Tooltip,
  Spin,
  Typography,
} from "antd";
import { useEffect, useRef, useState } from "react";
import {
  EditOutlined,
  DeleteOutlined,
  UserOutlined,
  UserAddOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
  MinusCircleFilled,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ROLE, { SUPER_ADMIN, ADMIN } from "../../role";
import "./role.scss";
import {
  getUserList,
  deleteUser,
  addUser,
  updateUserInfo,
} from "../../store/actions/userManagement.action";
import Validator from "../../utils/validator";
import GetColumnSearchProps from "../../components/table/search";

const { TabPane } = Tabs;
const { Option } = Select;

const RoleHierarchy = () => {
  // State variables using hooks
  const [password, setPassword] = useState("");
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);

  const [selectedCompanyIds, setSelectedCompanyIds] = useState([]);
  const [selectedClientIds, setSelectedClientIds] = useState([]);

  // Redux hooks to access store and dispatch actions
  const activeCompanies = useSelector((state) => state.company?.active || []);
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state?.signin?.isLoading);
  const [filteredUserInfo, setFilteredUserInfo] = useState([]);
  const userCompany = useSelector(
    (state) => state?.profile?.user?.permissions?.company
  );
  const userRole = useSelector(
    (state) => state?.profile?.user.permissions.role
  );
  const userName = useSelector((state) => state?.profile?.user.name);
  const [required, setRequired] = useState(true);
  const userInfo = useSelector((state) => state.userManagement?.userList);
  const [visible, setVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [form] = Form.useForm();
  const [addUserAccessibilitySelect, setAddUserAccessibilitySelect] =
    useState("");
  const [selectedEditUser, setSelectedEditUser] = useState({
    key: "",
    firstname: "",
    lastname: "",
    email: "",
    role: "",
    profileImage: "",
    selectedCompanies: [],
  });
  const [selectedDeleteUser, setSelectedDeleteUser] = useState("");
  const [dataSource, setDataSource] = useState([]);
  const [currentTab, setCurrentTab] = useState("accountInfo");
  const [assignAll, setAssignAll] = useState(false);

  // Effect hook to fetch user list on initial load
  useEffect(() => {
    dispatch(getUserList(userRole));
  }, []);

  // Effect hook to filter user list based on user role and company
  useEffect(() => {
    // Filter the userInfo array based on the matching company IDs
    const filteredUsers = userInfo.filter(
      (user) => user?.company === userCompany
    );

    // Update the state with the filtered user information
    setFilteredUserInfo(filteredUsers);

    // If the user role is Super Admin, set dataSource to userInfo (all users)
    // Otherwise, set dataSource to the filteredUserInfo (users with matching company ID)
    setDataSource(userRole === "Super Admin" ? userInfo : filteredUsers);
  }, [userInfo, userCompany, userRole]);

  // Functions to handle edit, cancel, select all, clear all, and other actions
  const handleEdit = (record) => {
    const [firstname, ...lastnameArray] = record.name.split(" ");
    const lastname = lastnameArray.join(" ");
    const editUser = {
      ...record,
      firstname: firstname.trim(), // Remove leading and trailing spaces
      lastname: lastname.trim(), // Remove leading and trailing spaces
    };
    setEditMode(true);
    setSelectedEditUser(editUser);
    setAssignAll(record?.assignAll);
    const allClients = activeCompanies?.reduce((acc, company) => {
      if (company.clients) {
        acc.push(...company.clients);
      }
      return acc;
    }, []);

    const matchingCompany = activeCompanies.find(
      (company) => company._id === editUser?.company
    );
    setSelectedCompanies(matchingCompany ? matchingCompany.CompanyName : null);
    setSelectedCompanyIds(matchingCompany ? matchingCompany._id : null);

    const selectedClientNames =
      editUser?.clients?.map((clientId) => {
        const client = allClients.find((c) => c.ClientId === clientId);
        return client ? client.ClientName : null;
      }) || [];
    setSelectedClients(selectedClientNames);
    setSelectedClientIds(editUser?.clients || []);
    form.setFieldsValue(editUser);
    setVisible(true);
  };

  //function to handle cancel functionality
  const handleCancel = () => {
    setVisible(false);
    setDeleteModalVisible(false);
    setCurrentTab("accountInfo");
    setSelectedClients([]);
  };

  //function to render company in select company dropdown
  const renderCompanies = () => {
    return (
      activeCompanies?.map((company) => {
        return {
          value: company?._id?.toString(),
          label: company.CompanyName,
        };
      }) || []
    );
  };

  // Enable clients search when role is not super admin
  const renderClientsSearch = () => {
    let filters = [];
    // only admin / user have one active company
    if (activeCompanies && activeCompanies[0]?.clients) {
      filters = activeCompanies[0]?.clients?.map((client) => ({
        text: client.ClientName,
        value: client.ClientId,
      }));
    }

    return filters;
  };

  const MAX_CLIENTS_TO_DISPLAY = 3;

  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (rowKey) => {
    if (expandedRows.includes(rowKey)) {
      setExpandedRows((prevState) => prevState.filter((key) => key !== rowKey));
    } else {
      setExpandedRows((prevState) => [...prevState, rowKey]);
    }
  };

  // Function to render clients based on user's companies and client selections
  const renderClients = (clientIds, rowKey, companyid) => {
    const allClients = activeCompanies?.reduce((acc, company) => {
      if (company.clients) {
        acc.push(...(company.clients.map(e => ({...e, CompanyId: company._id}))));
      }
      return acc;
    }, []);
    let clientNames;
    if (!clientIds || clientIds?.length === 0) {
      if (companyid) {
        clientNames = allClients.filter(e => e.CompanyId == companyid).map((client) => client?.ClientName);
      } else {
        clientNames = allClients.map((client) => client?.ClientName);
      }
    } else {
      clientNames = clientIds.map((clientId) => {
        const client = allClients.find((c) => c?.ClientId === clientId);
        return client ? client.ClientName : null;
      });
    }
    if (
      clientNames.length > MAX_CLIENTS_TO_DISPLAY &&
      !expandedRows.includes(rowKey)
    ) {
      // If more clients than the max allowed and row is not expanded
      const displayedNames = clientNames.slice(0, MAX_CLIENTS_TO_DISPLAY);
      const moreCount = clientNames.length - MAX_CLIENTS_TO_DISPLAY;
      return (
        <span>
          {displayedNames.join(", ")} ... and {moreCount} more
          <span
            onClick={() => toggleRowExpansion(rowKey)}
            className="clickable-text"
          >
            (Click to expand all)
          </span>
        </span>
      );
    }
    return (
      <span
        onClick={() => {
          toggleRowExpansion(rowKey);
        }}
      >
        {!activeCompanies || activeCompanies.length === 0 ? (
          <Spin />
        ) : (
          clientNames.join(", ")
        )}
      </span>
    );
  };

  // Functions to handle OK, Delete OK, add user, button click, and role creation

  //function to select all clients in select clients dropdown
  const handleSelectAll = () => {
    setAssignAll(!assignAll);
  };

  //function to clear all clients in select client dropdown
  const handleClearAll = () => {
    setSelectedCompanies([]);
    setSelectedClients([]);
    setSelectedCompanyIds([]);
    setSelectedClientIds([]);
  };

  //function to handle additon of new or edited user bsed on validation parameters
  const handleOk = (event) => {

    if (required) {
      // Check if a company needs to be selected and hasn't been.
      if (selectedCompanies.length === 0 && userRole !== "Super Admin") {
        message.error("Please select a company.");
        return;
      }
      // If assignAll is false, check if a client need to be selected and hasn't been.
      if (!assignAll && selectedClients.length === 0 && userRole !== "Super Admin") {
        message.error("Please select at least one client.");
        return;
      }
    }

    form.validateFields().then((values) => {
      const { firstname, lastname, email } = values;

      // Check if the firstname and lastname exceed 20 characters
      if (firstname && firstname.length > 20) {
        message.error("First name cannot exceed 20 characters.");
        return;
      }

      if (lastname && lastname.length > 20) {
        message.error("Last name cannot exceed 20 characters.");
        return;
      }
      if (email && email.length > 80) {
        message.error("Email cannot exceed 80 characters.");
        return;
      }

      // Concatenate the first and last names into the full name
      const fullName = `${firstname} ${lastname}`;
      // Rest of form submission logic

      const clients = selectedClientIds;
      const company = selectedCompanyIds;
      const selectAll = assignAll;

      let requestbBody = {
        ...values,
        email,
        company,
        clients,
        assignAll: selectAll,
      };
      if (!editMode) {
        dispatch(addUser(requestbBody));
      } else {
        dispatch(updateUserInfo(requestbBody));
        message.success("User: " + email + " has been updated.");
      }
      form.resetFields();
      setCurrentTab("accountInfo");
      setSelectedCompanies([]);
      setSelectedClients([]);
      setVisible(false);
    });
  };

  //function to handle delete user functionality when ok clicked on delete modal
  const handleDeleteOk = (event) => {
    //handle delete request
    dispatch(deleteUser(selectedDeleteUser.email));
    setDeleteModalVisible(false);
  };

  //function to display delete user modal when delete button is clicked
  const handleDelete = (event) => {
    // Handle delete action
    setSelectedDeleteUser(event);
    setDeleteModalVisible(true);
  };

  //function to display add user modal when add button is clicked
  const handleAdd = () => {
    form.resetFields();
    setVisible(true);
    setEditMode(false);
    setSelectedCompanies([]);
    setSelectedClients([]);
    setSelectedCompanyIds([]);
    setSelectedClientIds([]);
    setAssignAll(false);
  };

  //handle button click on modal to either save, cancel or next
  const handleButtonClick = () => {
    if (currentTab === "accountInfo") {
      if (selectedEditUser.role === "Super Admin") {
        handleOk();
      } else {
        setCurrentTab("accessibility");
        // setVisitedAccessibilityTab(true);
      }
    } else {
      handleOk();
    }
  };

  //option in dropdown when selecting role for new or existing user
  const roleOptions = ROLE.filter((role) => {
    if (userRole === ADMIN) {
      return role.value === "User";
    } else {
      return true;
    }
  });

  //function to handle role creation
  const handleRoleCreation = (role) => {
    setAddUserAccessibilitySelect(role);
  };

  //define column for table
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      defaultSortOrder: "ascend",
      sorter: (a, b) => a.name.localeCompare(b.name),
      ...GetColumnSearchProps({ dataIndex: "name" }),
      render: (text, record) => (
        <div
          className="name-div"
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
        >
          <Tooltip title={text}>
            {record.profileImage ? (
              <img
                src={record.profileImage}
                alt="Profile"
                style={{
                  marginRight: 8,
                  borderRadius: "50%",
                  width: 60,
                }}
              />
            ) : (
              <Avatar
                size="large"
                icon={<UserOutlined />}
                style={{ width: 40 }}
              />
            )}
            <span className="name-div">{text}</span>
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) =>
        (a && a.email && a.email.toString()).localeCompare(
          b && b.email && b.email.toString()
        ),
      ...GetColumnSearchProps({ dataIndex: "email" }),
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => {
        if (a && a.role && b && b.role) {
          return a.role.toString().localeCompare(b.role.toString());
        } else if (a && a.role) {
          return -1;
        } else if (b && b.role) {
          return 1;
        } else {
          return 0;
        }
      },
      ...GetColumnSearchProps({ dataIndex: "role" }),
    },
    {
      title: "Clients",
      dataIndex: "clients",
      filters: userRole !== SUPER_ADMIN ? renderClientsSearch() : undefined,
      onFilter:
        userRole !== SUPER_ADMIN
          ? (value, record) => {
              return record.clients.includes(value);
            }
          : undefined,
      filterSearch: true,
      render: (clientIds, record) => renderClients(clientIds, record.id, record.company),
    },
    {
      title: "Action",
      className: "action-column",

      render: (text, record) => (
        <div>
          <Button
            shape="round"
            type="primary"
            className="button-div"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <Button
            shape="round"
            className="button-div delete-btn-div"
            type="primary"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record)}
          >
            Delete
          </Button>
        </div>
      ),
    },
  ];

  const renderCriterias = () => {
    return Validator.PasswordRules.map((criteria) => {
      return (
        <Row gutter={10}>
          <Col>
            {password.trim() == "" ? (
              <MinusCircleFilled />
            ) : password.match(criteria.rule) ? (
              <CheckCircleFilled style={{ color: "#49aa19" }} />
            ) : (
              <CloseCircleFilled style={{ color: "#dc4446" }} />
            )}
          </Col>
          <Col>{criteria.name}</Col>
        </Row>
      );
    });
  };

  // Get dynamic table scroll width
  const totalMinWidth = columns?.reduce((acc, column) => {
    if (column.width) {
      return acc + parseInt(column.width, 10);
    }
    return acc + 120;
  }, 0);

  // convert to lowercase + restore the previous cursor position
  const handleEmailInput = (e) => {
    const cursorPosition = e.target.selectionStart;
    const inputValue = e.target.value.toLowerCase();
    e.target.value = inputValue;
    e.target.setSelectionRange(cursorPosition, cursorPosition);
  };

  return (
    <div className="roles-container" style={{ padding: 24 }}>
      <Table
        title={() => (
          <div className="table-title-container">
            <span className="username user-bold">
              {userName} ({userRole})
            </span>
            <div className="add-btn-div">
              <Button type="primary" onClick={handleAdd} shape="round">
                <UserAddOutlined /> Add User
              </Button>
            </div>
          </div>
        )}
        loading={isLoading}
        columns={columns}
        scroll={{ x: totalMinWidth }}
        dataSource={dataSource}
        className="voyce-custom-table user-table"
        sticky
      />

      {/* Modals for edit and delete functionality */}
      <Modal
        className="edit-modal"
        title={editMode ? "Edit User" : "Add User"}
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        maskClosable={false}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            shape="round"
            className="button-div"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleButtonClick}
            shape="round"
            className="button-div"
          >
            {editMode ? "Save" : currentTab === "accountInfo" ? "Next" : "Add"}
          </Button>,
        ]}
      >
        <Tabs
          defaultActiveKey="accountInfo"
          tabBarGutter={32}
          activeKey={currentTab}
          onChange={setCurrentTab}
        >
          <TabPane tab="Account Info" key="accountInfo">
            <br />
            <Form form={form}>
              <Form.Item
                name="firstname"
                rules={[
                  {
                    required: true,
                    message: "Please input First name!",
                  },
                  {
                    max: 30,
                    message: "First name cannot be longer than 30 characters",
                  },
                  {
                    pattern: /^[a-zA-Z-]+(?:[a-zA-Z-]+)*$/,
                    message: "Only alphabets and hyphens are allowed",
                  },
                ]}
              >
                <Input placeholder="First Name" />
              </Form.Item>
              <Form.Item
                name="lastname"
                rules={[
                  {
                    required: true,
                    message: "Please input last name!",
                  },
                  {
                    max: 30,
                    message: "Last name cannot be longer than 30 characters",
                  },
                  {
                    pattern: /^[a-zA-Z-]+(?:[a-zA-Z-]+)*$/,
                    message: "Only alphabets and hyphens are allowed",
                  },
                ]}
              >
                <Input placeholder="Last Name" />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[
                  { required: true, message: "Please input your email" },
                  {
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.resolve(); // Skip further validation if the field is empty
                      }

                      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
                        return Promise.reject("Invalid email format");
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input
                  placeholder="E-mail"
                  disabled={editMode}
                  onInput={handleEmailInput}
                  maxLength={80}
                />
              </Form.Item>
              {editMode ? null : (
                <>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input the password!",
                      },
                      () => ({
                        validator(_, value) {
                          if (!value || Validator.validatePassword(value)) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error("Password entry does not meet criteria")
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password
                      onChange={(e) => setPassword(e.target.value)}
                      placeholder="Password"
                    />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm the password!",
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(
                            new Error(
                              "The two passwords that you entered do not match!"
                            )
                          );
                        },
                      }),
                    ]}
                  >
                    <Input.Password placeholder="Confirm Password" />
                  </Form.Item>
                </>
              )}
              <Form.Item name="role" rules={[{ required: true }]}>
                <Select
                  options={roleOptions}
                  placeholder="Select a role"
                  onChange={handleRoleCreation}
                />
              </Form.Item>
              {editMode ? null : <Col>{renderCriterias()}</Col>}
            </Form>
          </TabPane>
          <TabPane
            tab="Permissions"
            key="accessibility"
            disabled={selectedEditUser.role === "Super Admin"}
          >
            <br />
            <Select
              placeholder="Select company names"
              showSearch
              optionFilterProp="label"
              value={selectedCompanies}
              onChange={(value) => {
                // Find the selected company
                const selectedCompany = activeCompanies.find(
                  (company) => company._id.toString() === value
                );
                // Update the state with both name and ID
                setSelectedCompanies(
                  selectedCompany ? selectedCompany.CompanyName : null
                );
                setSelectedCompanyIds(
                  selectedCompany ? selectedCompany._id : null
                );
                setSelectedClients([]);
                setSelectedClientIds([]);
              }}
              style={{ width: "100%" }}
              disabled={addUserAccessibilitySelect === "Super Admin"}
            >
              {renderCompanies()
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((company) => (
                  <Option
                    key={company.value}
                    value={company.value}
                    label={company.label}
                  >
                    {company.label}
                  </Option>
                ))}
            </Select>
            <br />
            <br />

            {!assignAll ? (
              <Select
                mode="multiple"
                placeholder="Select client names"
                showSearch
                optionFilterProp="label"
                value={selectedClientIds}
                onChange={(selectedIds) => {
                  const selectedClientNames = selectedIds.map((id) => {
                    const allClients = activeCompanies?.reduce(
                      (acc, company) => {
                        if (company.clients) {
                          acc.push(...company.clients);
                        }
                        return acc;
                      },
                      []
                    );
                    const client = allClients.find((c) => c.ClientId === id);
                    return client ? client.ClientName : null;
                  });
                  setSelectedClients(selectedClientNames);
                  setSelectedClientIds(selectedIds);
                }}
                style={{ width: "100%" }}
                disabled={
                  addUserAccessibilitySelect === "Super Admin" &&
                  !selectedCompanies
                }
              >
                {activeCompanies
                  .find((e) => e._id == selectedCompanyIds)
                  ?.clients?.map((client) => (
                    <Option key={client.ClientId} value={client.ClientId}>
                      {client.ClientName}
                    </Option>
                  ))}
              </Select>
            ) : (
              <Typography.Text italic>
                *All clients are selected; any new client added to this company
                will also be automatically assigned to the user.
              </Typography.Text>
            )}
            <div style={{ marginTop: "16px" }}>
              <Button
                style={{ marginRight: 10 }}
                onClick={handleSelectAll}
                disabled={addUserAccessibilitySelect === "Super Admin"}
                type={assignAll ? "primary" : "default"}
                icon={
                  assignAll && <CheckCircleFilled style={{ color: "green" }} />
                }
              >
                {assignAll ? "Deselect All" : "Select All"}
              </Button>
              <Button
                onClick={handleClearAll}
                disabled={addUserAccessibilitySelect === "Super Admin"}
                danger
              >
                Clear
              </Button>
            </div>
            {addUserAccessibilitySelect === "Super Admin" && (
              <i>
                <p style={{ color: "white", marginBottom: 0 }}>
                  <span
                    style={{
                      color: "#6c40a6",
                      fontSize: 24,
                    }}
                  >
                    *
                  </span>{" "}
                  Super Admins will be automatically assigned with ALL COMPANIES
                </p>
              </i>
            )}
          </TabPane>
        </Tabs>
      </Modal>
      <Modal
        className="delete-modal"
        title="Delete User"
        visible={deleteModalVisible}
        onCancel={handleCancel}
        onOk={handleDeleteOk}
        footer={[
          <Button
            key="back"
            onClick={handleCancel}
            shape="round"
            className="button-div"
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={handleDeleteOk}
            shape="round"
            className="button-div"
          >
            Ok
          </Button>,
        ]}
      >
        Do you want to delete the user: <b>{selectedDeleteUser.email}</b>?
      </Modal>
    </div>
  );
};

export default RoleHierarchy;
