// Import necessary dependencies
import { useSelector } from "react-redux"; // Importing react-redux for selecting data from Redux store
import { Navigate, Outlet, useLocation } from "react-router-dom"; // Importing necessary utilities from React Router DOM
import { useEffect, useState } from "react"; // Importing hooks from React

// Importing components and role constants
import Page403 from "./403";
import { PROHIBITED_ACCESS } from "../role";

// Component for handling protected routes
const ProtectedRoutes = () => {
    // Selecting necessary data from Redux store
    const token = useSelector((state) => state.profile?.token);
    const user = useSelector((state) => state.profile?.user);

    // Getting the current location using useLocation hook from React Router
    const location = useLocation();

    // State to manage the current pathname
    const [pathname, setPathname] = useState("");

    // useEffect to update the pathname state when location changes
    useEffect(() => {
        setPathname(location.pathname);
    }, [location.pathname]);

    // Function to check if the user is unauthorized to access a particular route
    const UnauthorizeUser = () => {
        // Get the prohibited paths based on the user's role
        const prohibitedPaths = PROHIBITED_ACCESS[user.permissions.role];
        
        // Check if the current pathname is in the prohibited paths list
        return prohibitedPaths?.includes(pathname);
    };

    // Conditional rendering based on token and user authorization
    return token ? ( // If token exists
        UnauthorizeUser() ? ( // If user is unauthorized to access the current path
            <Page403 /> // Render the 403 Forbidden page
        ) : (
            <Outlet /> // Render the nested routes using Outlet
        )
    ) : (
        <Navigate to={"/auth/login"} /> // If no token, redirect to login page
    );
};

export default ProtectedRoutes;
