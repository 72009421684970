import ActionType from "../action.types";
import { message } from "antd";
import Service from "../../services";
import _ from 'lodash'

export const updateColumns = (columns, unselectedColumns, userId) => {
    console.log("updateColumns called unselectedColumns: ", columns, unselectedColumns);
    return async (dispatch) => {
        const reqColumns = {
            id: userId,
            selectedColumns: _.compact(columns).map((col) => col.dataIndex),
            unSelectedColumns: _.compact(unselectedColumns).map((col) => col.dataIndex),
        };

        await Service.Auth().updateCustomColumns(reqColumns);

        dispatch({
            type: ActionType.CHANGE_COLUMNS,
            payload: {
                selectedColumns: _.compact(columns),
                unselectedColumns: _.compact(unselectedColumns),
            },
        });
    };
};