import request from './axios';
import AuthService from "./auth.service";
import CompanyService from "./company.service";
import AnalyticsService from './analytics.service';
import TransactionService from './transaction.service';
import DeviceService from './device.service';
import InvoiceService from './invoice.service';

class Service {
	static Auth() {
		return new AuthService(request);
	}

	static Company() {
		return new CompanyService(request);
	}

	static Analytics() {
		return new AnalyticsService(request);
	}

	static Transaction() {
		return new TransactionService(request);
	}

	static Device() {
		return new DeviceService(request);
	}

	static Invoice() {
		return new InvoiceService(request);
	}
}

export default Service;
