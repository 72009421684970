import { HeatMapOutlined, ArrowRightOutlined,  DollarCircleFilled,  PieChartFilled, RadarChartOutlined,  UsergroupAddOutlined, WalletOutlined, CustomerServiceFilled, TranslationOutlined, FundFilled, VideoCameraFilled, DashboardOutlined} from "@ant-design/icons";
import { Col, Layout, Row, Select, Typography, theme } from "antd";
import {  useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getActiveCompanies, selectClient, selectRegion, selectSite } from "../../store/actions/company.action";
import FilterDate from "./filter.date";
import LanguageFilter from "./language.filter";
import YearFilter from "./filter.year";
import "./header.scss";
import InvoiceFilter from "./invoice.filter";
import nmRegions from './nm.regions.json';
import nyuDepartments from './nyu.departments.json';
import yaleDepartments from './yale_departments.json';
import ascDepartments from './asc.departments.json';
import upmcDepartments from './upmc.departments.json';

import ThemeFilter from "./theme.button";

const { Header } = Layout;
const CUSTOM_FILTER_COMPANIES = ["1598", "1506", "1899", "9001", "2102"];
const CUSTOM_FILTER_DATA = {
    "1598": {
        parentLabel: "Regions",
        childLabel: "Sites",
        data: nmRegions
    },
    "1506" : {
        parentLabel: "Sites",
        childLabel: "Departments",
        data: nyuDepartments
    },
    "1899" : {
        parentLabel: "Sites",
        childLabel: "Departments",
        data: yaleDepartments
    },
    "9001" : {
        parentLabel: "Regions",
        childLabel: "Sites",
        data: ascDepartments
    },
    "2102": {
        parentLabel: "Regions",
        childLabel: "Sites",
        data: upmcDepartments
    }
}

const CUSTOM_HEADER_NAME = {
    "1899": 'Yale New Haven Healthcare'
}

//describe what title and icon to display in header based on routes
const routes = {
    '/pages': {name: 'Dashboard', icon: <PieChartFilled style={{color: "#0a9191"}} />},
    '/pages/': {name: 'Dashboard', icon: <PieChartFilled style={{color: "#0a9191"}}/>},
    '/pages/transactions': {name: 'Transactions', icon: <WalletOutlined style={{color: "#0a9191"}}/>},
    '/pages/transactions/': {name: 'Transactions', icon: <WalletOutlined style={{color: "#0a9191"}}/>},
    '/pages/activity-monitor': {name: 'Activity Monitor', icon: <DashboardOutlined style={{color: "#0a9191"}}/>},
    '/pages/activity-monitor/': {name: 'Activity Monitor', icon: <DashboardOutlined style={{color: "#0a9191"}}/>},
    '/pages/invoices': {name: 'Invoices', icon: <DollarCircleFilled style={{color: "#0a9191"}}/>},
    '/pages/invoices/': {name: 'Invoices', icon: <DollarCircleFilled style={{color: "#0a9191"}}/>},
    '/pages/terp': {name: "Interpreter Dashboard", icon: <CustomerServiceFilled style={{color: "#0a9191"}}/>},
    '/pages/terp/': {name: "Interpreter Dashboard", icon: <CustomerServiceFilled style={{color: "#0a9191"}}/>},
    '/pages/role-hierarchy': {name: 'Role Hierarchy', icon: <UsergroupAddOutlined style={{color: "#0a9191"}}/>},
    '/pages/reports/interpreter-routing-history': {name: 'Interpreter Routing History', icon: <RadarChartOutlined style={{color: "#0a9191"}}/>},
    '/pages/reports/interpreter-routing-history/': {name: 'Interpreter Routing History', icon: <RadarChartOutlined style={{color: "#0a9191"}}/>},
    '/pages/reports/language-report': {name: 'Language Report', icon: <TranslationOutlined style={{color: "#0a9191"}}/>},
    '/pages/reports/language-report/': {name: 'Language Report', icon: <TranslationOutlined style={{color: "#0a9191"}}/>},
    '/pages/reports/language-heatmap-report': {name: 'Language Heatmap Report', icon: <HeatMapOutlined style={{color: "#0a9191"}}/>},
    '/pages/reports/language-heatmap-report/': {name: 'Language Heatmap Report', icon: <HeatMapOutlined style={{color: "#0a9191"}}/>},
    '/pages/reports/audio-report': {name: 'Audio vs Video Report', icon: <CustomerServiceFilled style={{color: "#0a9191"}}/>},
    '/pages/reports/audio-report/': {name: 'Audio vs VideoReport', icon: <CustomerServiceFilled style={{color: "#0a9191"}}/>},
    '/pages/reports/device-usage': {name: 'Device Usage', icon: <TranslationOutlined style={{color: "#0a9191"}}/>},
    '/pages/reports/device-usage/': {name: 'Device Usage', icon: <TranslationOutlined style={{color: "#0a9191"}}/>},
    '/pages/reports/preferred-video': {name: 'Product Report', icon: <FundFilled style={{color: "#0a9191"}}/>},
    '/pages/reports/preferred-video/': {name: 'Product Report', icon: <FundFilled style={{color: "#0a9191"}}/>},
    '/pages/reports/audio-video-report/': {name: 'Video Call Analysis Report', icon: <VideoCameraFilled style={{color: "#0a9191"}}/>},
    '/pages/reports/audio-video-report': {name: 'Video Call Analysis Report', icon: <VideoCameraFilled style={{color: "#0a9191"}}/>},
}

const HIDE_COMPLETE_FILTER_PANEL_PAGES= [
    '/pages/role-hierarchy',
    '/pages/role-hierarchy/',
]

const HIDE_PARENT_CHILD_FILTER_PAGES = [
    '/pages/reports/device-usage',
    '/pages/reports/device-usage/',
    '/pages/invoices',
    '/pages/invoices/'
]

const VIPHeader = ({}) => {
    // Retrieve necessary data from Redux store and manage component state
    const {token: {colorBgLayout}} = theme.useToken();
    const [pathname, setPath] = useState('/pages');
    const isLoading = useSelector((state) => state.profile?.isLoading);
    const [company, setCompany] = useState();
    const client = useSelector((state) => state.company?.client);
    const region = useSelector((state) => state.company?.region);
    const site = useSelector((state) => state.company?.site);
    const selectedCompany = useSelector(
        (state) => state?.profile?.user?.permissions?.company?.toString()
    );
    
    const location = useLocation();
    const dispatch = useDispatch();

    console.log(region)
    console.log(site)

    // Handle useEffect for updating path on location change
    useEffect(() => {
        setPath(location.pathname)
    }, [ location.pathname ]);

    //Handle useEffect for fetching active companies
    useEffect(() => {
        dispatch(getActiveCompanies()).then((companies) => {
            setCompany(companies?.find((e) => e._id.toString() == selectedCompany))
        });
    }, []);

    const handleRegionChange = (value) => {
        if (value == -1) {
          dispatch(selectRegion({ _id: '-1' }))
          return
        }
        dispatch(
          selectRegion(
            { _id: value },
            { SiteId: '-1' }
          )
        )
    }

    // Function to handle client change event
    const handleSiteChange = (value) => {
        if (value == -1) {
            dispatch(selectSite({SiteId: '-1'}));
            return;
        }
        dispatch(
            selectSite(
                {SiteId: value}
            )
        )
    }

    // Function to handle client change event
    const handleClientChange = (value) => {
        if (value == -1) {
            dispatch(selectClient({ClientId: '-1'}));
            return;
        }
        dispatch(
            selectClient(
                company.clients?.find((e) => e.ClientId.toString() === value)
            )
        )
    }

    // Function to render clients
    const renderClients = () => {
        return company?.clients
            ?.filter(client => client?.ClientName !== company?.CompanyName)
            .map((client) => {
            return ({
                value: client?.ClientId?.toString(),
                label: client?.ClientName
            });
        }) || [];
    }

    const renderSites = () => {
        const customFilter = CUSTOM_FILTER_DATA[selectedCompany];
        let reg = region?._id
        if (selectedCompany == '9001') {
            reg = Object.keys(customFilter.data).find((e) => e.includes(region?._id))
        }
        return (customFilter?.data[reg] || []).map((e) => {
            const splits =  e?.split(":")
            return ({
                value: splits?.length > 1 ? splits[1] : splits[0],
                label: e
            });
        }) || [];
    }

    const hasChild = () => {
        const customFilter = CUSTOM_FILTER_DATA[selectedCompany];
        if (selectedCompany == '9001') {
            const reg = Object.keys(customFilter.data).find((e) => e.includes(region?._id))
            return (customFilter?.data[reg] || []).length > 0
        }
        return (customFilter?.data[region?._id] || []).length > 0
    }

    const renderRegions = () => {
        const customFilter = CUSTOM_FILTER_DATA[selectedCompany];
        return Object.keys(customFilter?.data || {}).map((region) => {
            const splits =  region?.split(":")
            return ({
                value: splits?.length > 1 ? splits[1] : splits[0],
                label: splits[0]
            });
        }) || [];
    }

    const customFilter = CUSTOM_FILTER_DATA[selectedCompany];

    return (
        <Header className="header-container" id="header-container-id" style={{background: colorBgLayout}}>
            {/* Row for arranging header elements */}
            <Row style={{height: '100%'}} align={"middle"} justify={"end"} gutter={10}>
                {/* Column for displaying the header title and icon */}
                <Col className="title-row">
                    <div className="header-title">
                        <div className="icon-col" style={{ fontSize: 22 }}>
                        {routes[pathname]?.icon}
                        </div>
                        <div className="name-col" style={{ fontSize: 22, marginLeft: 10 }}>
                        {routes[pathname]?.name}
                        </div>
                    </div>
                </Col>

                {/* Column for displaying the company name */}
                <Col  style={{marginLeft: 'auto'}} className="title-row company-row">
                    <Typography.Text> {CUSTOM_HEADER_NAME[selectedCompany] || company?.CompanyName}</Typography.Text>
                </Col>

                {/* Conditionally render these elements if pathname doesnt include role-heirarchy */}
                {
                    !HIDE_COMPLETE_FILTER_PANEL_PAGES.includes(pathname) && 
                    (
                    <>
                    <Col hidden={!company} className="title-row">
                        <ArrowRightOutlined/>
                    </Col>
                
                    
                        {/* Conditionally render invoice filter if pathname include invoice else show languagefilter */}
                        <Col className="title-row">
                        {
                            pathname.includes('invoice') ? <InvoiceFilter/> : <LanguageFilter/>
                        }
                        </Col>
                        {/* Conditionally render year filter if pathname includes invoice else show filterdate  */}
                        <Col className="title-row">
                            { 
                                pathname.includes('invoice') ? <YearFilter/> : <FilterDate/>
                            }
                        </Col>
                    
                    
                    {CUSTOM_FILTER_COMPANIES.includes(selectedCompany) && !HIDE_PARENT_CHILD_FILTER_PAGES.includes(pathname) &&
                    <><Col className="title-row">
                        <Select
                            className="select-row"
                            showSearch
                            placeholder={`Select ${customFilter?.parentLabel}`}
                            optionFilterProp="children"
                            style={{ minWidth: 200 }}
                            filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            defaultValue={'-1'}
                            options={[
                            { value: '-1', label: `All ${customFilter?.parentLabel}` },
                            ...renderRegions()
                            ]}
                            onChange={handleRegionChange}
                            value={region == '-1' ? '-1' : region?._id}
                        />
                        </Col>
                    <Col hidden={region == '-1' || !hasChild()} className="title-row">
                        <ArrowRightOutlined />
                    </Col>
                </>
                }
                {/* Conditionally render the Select Client based on whether renderClients is empty or not */}
                {renderClients().length > 0 && (
                    <Col hidden={!client || CUSTOM_FILTER_COMPANIES.includes(selectedCompany)} className="title-row">
                        <Select 
                            className="select-row"
                            disabled={isLoading}
                            showSearch
                            placeholder="Select Client"
                            optionFilterProp="children"
                            style={{minWidth: 200}}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            defaultValue={"-1"}
                            options={[
                                { value: '-1', label: 'All Clients'},
                                ...renderClients()
                            ]}
                            value={client?.ClientId?.toString()}
                            onChange={handleClientChange}
                        />
                    </Col>
                )}
                {(
                    <Col hidden={!CUSTOM_FILTER_COMPANIES.includes(selectedCompany) || region == '-1'|| !hasChild()} className="title-row">
                        <Select 
                            className="select-row"
                            // disabled={isLoading}
                            showSearch
                            placeholder={`Select ${customFilter?.childLabel}`}
                            optionFilterProp="children"
                            style={{minWidth: 200}}
                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                            filterSort={(optionA, optionB) =>
                                (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                            }
                            defaultValue={"-1"}
                            options={[
                                { value: '-1', label: `All ${customFilter?.childLabel}`},
                                ...renderSites()
                            ]}
                            value={site == '-1' ? '-1' : site?.SiteId}
                            onChange={handleSiteChange}
                        />
                    </Col>
                )}
                </>
                    )
                    }
                <Col>
                    <ThemeFilter/>
                </Col>
            </Row>
        </Header>
    );
}

export default VIPHeader;