import { useCallback, useEffect, useRef } from 'react';

// Custom hook to handle asynchronous intervals
const useIntervalAsync = (fn, ms) => {
  // Ref to track whether the interval is running
  const running = useRef(false);

  // Ref to store the timeout ID
  const timeoutId = useRef();

  // Ref to track if the component is mounted
  const mountedRef = useRef(false);

  // Function to run the callback asynchronously
  const run = useCallback(async () => {
    // Check if the component is mounted and the interval is not already running
    if (mountedRef.current && !running.current) {
      running.current = true; // Mark interval as running
      await fn(); // Execute the provided function
      running.current = false; // Mark interval as not running
      scheduleNext(); // Schedule the next interval
    }
  }, [fn]);

  // Function to schedule the next interval
  const scheduleNext = useCallback(() => {
    // Check if the component is mounted
    if (mountedRef.current) {
      timeoutId.current = setTimeout(run, ms); // Schedule the next interval
    }
  }, [run, ms]);

  // Effect to run when the component mounts and clean up when unmounting
  useEffect(() => {
    mountedRef.current = true; // Mark component as mounted
    scheduleNext(); // Schedule the first interval

    return () => {
      mountedRef.current = false; // Mark component as unmounted
      clearTimeout(timeoutId.current); // Clear the timeout
    };
  }, [scheduleNext]);

  // Function to immediately execute the provided function and clear the timeout
  const flush = useCallback(() => {
    clearTimeout(timeoutId.current); // Clear the timeout
    run(); // Execute the provided function immediately
  }, [run]);

  return flush; // Return the function to execute immediately
};

export default useIntervalAsync; // Export the custom hook
