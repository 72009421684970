import { Card, Spin, theme } from "antd";
import ReactECharts from "echarts-for-react";
import * as echarts from 'echarts/core';
import { memo } from "react";

// UsageChart component definition
const UsageChart = ({ vodDevices, noUtilizationDevices, loading, heroTitle, height }) => {
    // Accessing theme tokens for color configuration
    const { token: { colorText, colorBgLayout } } = theme.useToken();

    // Function to set the chart options
    const getOption = () => {
        // Define colors for chart elements
        let colors = [
            '#54c9e8',
            '#203870'
        ];

        // Return ECharts options for the chart
        return {
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%'];
                },
                axisPointer: {
                    type: 'shadow',
                },
            },
            grid: {
                top: 60,
                left: 35,
                right: 35,
                bottom: 2,
                containLabel: true,
            },
            legend: {
                data: ['Unused Devices', 'VOD Devices'],
                textStyle: {color: colorText},
                // show: false
            },
            xAxis: [
                {
                    type: "category",
                    data: ['Devices'],
                    axisTick: {
                        alignWithLabel: true,
                        show: false
                    },
                    axisLine: {
                        lineStyle: {
                          color: colorText, 
                        },
                    },
                },
            ],
            yAxis: [
                {
                    type: "value",
                    name: 'Number of Devices',
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    },
                }
            ],
            series: [
                {
                    name: 'Unused Devices',
                    type: 'bar',
                    stack: 'devices',
                    data: [noUtilizationDevices],
                    itemStyle: {color: colors[1]},
                },
                {
                    name: 'VOD Devices',
                    type: 'bar',
                    stack: 'devices',
                    data: [vodDevices],
                    itemStyle: {color: colors[0]},
                }
            ]
        };
    };


    return (
       <Spin spinning={loading} >
        <Card style={{height: height ? (height+70) : 290, borderRadius: 4, backgroundColor: colorBgLayout}}>
            <ReactECharts option={getOption()} style={{height: height ? height : 230}} />
            <p style={{textAlign: 'center'}} >{heroTitle}</p>
        </Card>
       </Spin>
    )
}

// Export the UsageChart component and its memoized version
export default UsageChart;
export const MemoizedUsageChart = memo(UsageChart);
