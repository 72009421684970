import { Button, Card, Col, Rate, Row, Spin, Table, theme, Tooltip } from 'antd';
import { LinkOutlined } from "@ant-design/icons";
import './language.report.scss';
import CallVolumeChart from './volume.chart';
import LanguageDistributionBar from './distribution.chart';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { getLanguagesDetails } from '../../../store/actions/language.action';
import Formatter from '../../../utils/Formatter';
import { useNavigate } from 'react-router-dom';
import DropDown from '../../../components/dropdown';
import exportAsCSV from "../../../services/exportToCVS.service"
import jsPDF from "jspdf";
import GetColumnSearchProps from '../../../components/table/search';
import { setLanguageType } from '../../../store/actions/filter.action';
import useIntervalAsync from '../../../components/hooks/useInterval';
import "jspdf-autotable";
import moment from 'moment';
import { exportToExcel } from '../../../services/exportToExcel';
// LanguageReport component definition
const LanguageReport = () => {
    // Extract necessary variables and methods using React hooks
    const { token: { colorBgLayout } } = theme.useToken();
    const [loading, setLoading] = useState(false);
  
    // Retrieve states from Redux store
    const state = useSelector((state) => state);
    const filter = useSelector((state) => state.filter);
    const company = useSelector((state) => state.company);
    const language = useSelector((state) => state.language);
    // Define and manage local state variables
    const [downloadType, setDownloadType] = useState("EXCEL");
    const dispatch = useDispatch();
    const navigate = useNavigate()
  
    // Fetch language details callback function
    const fetchLanguagesCB = useCallback(async () => {
      dispatch(getLanguagesDetails(true))
        .then(() => setLoading(false))
        .catch(() => setLoading(false))
    }, []);
    
    // Fetch language details at an interval
    const fetchLanguages = useIntervalAsync(fetchLanguagesCB, filter?.fetchInterval);
  
    // Fetch language details on component mount or state change
    useEffect(() => {
      setLoading(true);
      fetchLanguages();
    }, [filter?.date, company?.client, company?.region, company?.site]);
    // Prepare data source for table
    let dataSource = language.rawData?.map((list) => {
        const totalCalls = list?.TotalCalls || 0;
        const serviceMinutes = list?.ServiceMinutes || 0;
        const waitingSeconds = list?.WaitingSeconds || 0;
      
        // Use a function to safely perform division and avoid NaN
        const safeDivision = (numerator, denominator) => {
          if(denominator === 0 || isNaN(denominator)) {
            return 0; // return 0 instead of NaN if denominator is 0 or not a number
          }
          return Math.round(numerator / denominator);
        }
      
        return {
          ...list,
          "TargetLanguage": list?.TargetLanguage,
          "totalCalls": Formatter.numberWithCommas(totalCalls),
          "serviceMins": Formatter.numberWithCommas(serviceMinutes),
          "CountSuccessAduioCalls": Formatter.numberWithCommas(list?.CountSuccessAudioCalls || 0),
          "CountSuccessVideoCalls": Formatter.numberWithCommas(list?.CountSuccessVideoCalls || 0),
          "avgWaitingSeconds": `${Formatter.numberWithCommas(safeDivision(waitingSeconds, totalCalls))} seconds`,
          "avgLength": `${Formatter.numberWithCommas(safeDivision(serviceMinutes, (list?.CountSuccessVideoCalls + list?.CountSuccessAudioCalls)))} minutes`,
          "avgRating": list?.AvgRating
        }
      });
      

    // Define dropdown options for file download type
    const downloadDropdownType = [
        {
            value: "PDF",
            label: "PDF",
        },
        {
            value: "EXCEL",
            label: "EXCEL",
        },
    ];

    // Function to download a PDF report
    const downloadPDF = () => {
        let downloadArray = [];
        
        for (let list of language.rawData) {
            const avg = list.CallQualityRatingStar / list.CountRatingStarCalls;
            const avgRating = isNaN(avg) ? '-' : avg.toFixed(2);
            let newList = {
                "Language Name": list?.TargetLanguage,
                "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
                "Total Minutes":  Formatter.numberWithCommas(list?.ServiceMinutes),
                "Serviced Audio Calls": Formatter.numberWithCommas(list?.CountSuccessAudioCalls),
                "Serviced Video Calls": Formatter.numberWithCommas(list?.CountSuccessVideoCalls),
                "Avg. Wait Time": `${Formatter.numberWithCommas(Math.round(list?.WaitingSeconds/list?.TotalCalls))} seconds`,
                "Avg. Rating": avgRating
            };
            downloadArray.push(newList);
        }
      
        const headerList = [
            "Language Name",
            "Total Calls",
            "Total Minutes",
            "Serviced Audio Calls",
            "Serviced Video Calls",
            "Avg. Wait Time",
            "Avg. Rating"
        ];
        const bodyList = downloadArray.map(item => Object.values(item).map(value => String(value)));
        const doc = new jsPDF("landscape", "px", "a4");
        doc.setFontSize(18);
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.dateRange;
        const filename = `Language_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}`;
        doc.text(filename, 30, 20);
        doc.setFontSize(12);
        doc?.autoTable({
            head: [headerList.map(header => [header])],
            body: bodyList,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33],
            },
            startY: 50
        });
        doc.save(filename);
    };
      

    // Function to handle file download based on selected type (PDF/CSV)
    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDF()
        } else if (downloadType === "EXCEL") {
            downloadEXCELFunction(); 
        }
    };
    
    // Function to download a CSV report
    const downloadCSVFunction = () => {
        let downloadArray = [];

        for (let list of language.rawData) {
            const avg = list.CallQualityRatingStar / list.CountRatingStarCalls;
            const avgRating = isNaN(avg) ? '-' : avg.toFixed(2);
            let newList = {
                "Language Name": list?.TargetLanguage,
                "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
                "Total Minutes":  Formatter.numberWithCommas(list?.ServiceMinutes),
                "Serviced Audio Calls": Formatter.numberWithCommas(list?.CountSuccessAudioCalls),
                "Serviced Video Calls": Formatter.numberWithCommas(list?.CountSuccessVideoCalls),
                "Avg. Wait Time": `${Formatter.numberWithCommas(Math.round(list?.WaitingSeconds/list?.TotalCalls))} seconds`,
                "Avg. Rating": avgRating != "-" ? avgRating + " stars" : avgRating

            };
            downloadArray.push(newList);
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.dateRange;
        const filename = `Language_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}`;
        exportAsCSV(
            downloadArray,
            filename,
            //new Date().toISOString().split("T")[0],
            [
                "Language Name",
                "Total Calls",
                "Total Minutes",
                "Serviced Audio Calls",
                "Serviced Video Calls",
                "Avg. Wait Time",
                "Avg. Rating",
            ],
            "Language Report"
        );
    };

    const downloadEXCELFunction = () => {
        let downloadArray = [];

        for (let list of language.rawData) {
            const avg = list.CallQualityRatingStar / list.CountRatingStarCalls;
            const avgRating = isNaN(avg) ? '-' : avg.toFixed(2);
            let newList = {
                "Language Name": list?.TargetLanguage,
                "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
                "Total Minutes":  Formatter.numberWithCommas(list?.ServiceMinutes),
                "Serviced Audio Calls": Formatter.numberWithCommas(list?.CountSuccessAudioCalls),
                "Serviced Video Calls": Formatter.numberWithCommas(list?.CountSuccessVideoCalls),
                "Avg. Wait Time": `${Formatter.numberWithCommas(Math.round(list?.WaitingSeconds/list?.TotalCalls))} seconds`,
                "Avg. Rating": avgRating != "-" ? avgRating + " stars" : avgRating
            };
            downloadArray.push(newList);
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.dateRange;
        const filename = `Language_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}.xlsx`;
        const sheetData = [downloadArray];
        const sheetNames = ["Language Report"];

        exportToExcel(sheetData, sheetNames, filename);
    };

    // Event handler for navigating to transaction details by language
    const handleNavigationClick = (record) => {
        let targetLanguage = record.TargetLanguage;
        dispatch(setLanguageType(
            targetLanguage.match(/spanish/i) ? "sp" : 
            targetLanguage.match(/ASL/i) ? "ASL": "LOTS"
        ));
        navigate(`/pages/transactions?dataIdx0=languageType&status${0}=${targetLanguage}&dataIdx1=Status&status${1}=Serviced`);
    }

    const parseValue = (value) => {
        if (value === null || value === undefined) {
            return 0;
        }
        if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''));
        }
        return value;
    };
    
    const parseSort = (index) => {
        return (a, b) => {
            const aValue = parseValue(a[index]);
            const bValue = parseValue(b[index]);
            return aValue - bValue;
        };
    };

    // Define columns for the main language report table
    const columns = [
        {
            title: 'Language Name',
            dataIndex: 'TargetLanguage',
            sorter: (a, b) => a?.TargetLanguage?.localeCompare(b?.TargetLanguage),
            ...GetColumnSearchProps({dataIndex: 'TargetLanguage', isDate:false})
        },
        {
            title: 'Total Calls',
            dataIndex: 'totalCalls',
            sorter: parseSort('totalCalls'),
            defaultSortOrder: 'descend',
            render: (text) => {
                return (
                    <div>
                        {text ? text : '0'}
                    </div>
                );
            }
        },
        {
            title: 'Total Minutes',
            dataIndex: 'serviceMins',
            sorter: parseSort('serviceMins'),
            render: (text) => {
                return (
                    <div>
                        {text ? text : '0'}
                    </div>
                );
            }
        },
        {
            title: 'Serviced Audio Calls',
            dataIndex: 'CountSuccessAudioCalls',
            sorter: parseSort('CountSuccessAudioCalls'),
            render: (text) => {
                return (
                    <div>
                        {text ? text : '0'}
                    </div>
                );
            }
        },
        {
            title: 'Serviced Video Calls',
            dataIndex: 'CountSuccessVideoCalls',
            sorter: parseSort('CountSuccessVideoCalls'),
            render: (text) => {
                return (
                    <div>
                        {text ? text : '0'}
                    </div>
                );
            }
        },
        {
            title: 'Avg. Wait Time',
            dataIndex: 'avgWaitingSeconds',
            sorter: (a, b) => {
                const aValue = parseFloat(a?.avgWaitingSeconds?.replace(/,/g, ''));
                const bValue = parseFloat(b?.avgWaitingSeconds?.replace(/,/g, ''));
                return aValue - bValue;
            },
        },
        {
            title: 'Avg. Call Length',
            dataIndex: 'avgLength',
            sorter: (a, b) => {
                const aValue = parseFloat(a?.avgLength?.replace(/,/g, ''));
                const bValue = parseFloat(b?.avgLength?.replace(/,/g, ''));
                return aValue - bValue;
            },
        },
        {
            title: 'Avg. Rating',
            dataIndex: 'avgRating',
            sorter: (a, b) => {
                let avgA = a.CallQualityRatingStar / a.CountRatingStarCalls;
                let avgB = b.CallQualityRatingStar / b.CountRatingStarCalls;
                let avgRatingA = isNaN(avgA) ? 0 : avgA;
                let avgRatingB = isNaN(avgB) ? 0 : avgB;
                return avgRatingA - avgRatingB;
            },
            render: (text, record) => {
                let avg = record.CallQualityRatingStar / record.CountRatingStarCalls;
                let avgRating = isNaN(avg) ? '-' : parseFloat(avg.toFixed(1));
                return <div>
                        <Tooltip title={avgRating} placement="right">
                            <Rate style={{color: '#54c9e8'}} allowHalf value={avgRating} disabled/> 
                        </Tooltip>
                    </div>
            }
        },
        {
            title: 'Completed Calls',
            dataIndex: 'TargetLanguage',
            align: "center",
            render: (text, record) => 
                <a 
                    onClick={() => handleNavigationClick(record)}
                    style={{textDecoration: "underline"}}>
                    {Number(record?.CountSuccessVideoCalls) + Number(record?.CountSuccessAudioCalls)} <LinkOutlined />
                </a>
        },
    ];

    // JSX to render the LanguageReport component
    return (
        <Row>
            <Col className='language-col' flex={"auto"}>
                <Row wrap={false} gutter={20}>
                    <Col flex={"100%"}>
                        <Spin spinning={loading}>
                        <Card className='volume-card' style={{borderRadius: 4, backgroundColor: colorBgLayout, minWidth: '600px'}}>
                            <CallVolumeChart data={language?.volume}/>
                        </Card>
                        </Spin>
                    </Col>
                </Row>
                <br/>
                <Row wrap={false} gutter={20}>
                    <Col className='volume-card' flex={"100%"}>
                       <Spin spinning={loading}>
                        <Card style={{borderRadius: 4, backgroundColor: colorBgLayout, minWidth: '600px'}}>
                                <LanguageDistributionBar 
                                    data={language?.distribution || []} 
                                    interpretation={language?.interpretationType || {}}
                                    avgTime={language?.avgTime || {}}
                                />
                            </Card>
                       </Spin>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col flex={"auto"}>
                        <Table
                            title={() => (
                                        <Row
                                            gutter={[8, 8]}
                                            justify="end"
                                            align="middle"
                                        >
                                            <Col>
                                                <DropDown
                                                    defaultValue={downloadType}
                                                    dropdownOptions={
                                                        downloadDropdownType
                                                    }
                                                    handleTypeSelect={(
                                                        downloadType
                                                    ) =>
                                                        setDownloadType(
                                                            downloadType
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Button
                                                    loading={loading}
                                                    type="primary"
                                                    onClick={downloadFile}
                                                >
                                                    Download
                                                </Button>
                                            </Col>
                                        </Row>
                            )}
                            className="voyce-custom-table"
                            style={{width: '100%', backgroundColor: colorBgLayout}}
                            dataSource={dataSource}
                            columns={columns}
                            size="small"
                            pagination={false}
                            loading={loading}
                            sticky
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default LanguageReport;