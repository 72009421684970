import React from "react";
import { Progress } from "antd";
import { useNavigate } from "react-router-dom";
import "./starRatingBarGroup.scss";
import Formatter from "../../../utils/Formatter";


// Single bar component
const StarRatingProgressBar = ({
    starCount,
    percent,
    status,
    totalCount,
    handleStarRatingNav,
}) => (
    <div className="single-bar" onClick={() => handleStarRatingNav(starCount)}>
        <div className="bar-content">
            <div style={{ minWidth: 32, marginTop: -5 }}>
                {
                    starCount !== 0 ? `${starCount} ⭐️` : "✖⭐️"
                }
            </div>
            <Progress percent={percent} status={status} strokeColor="#74a8cf" />
            <div className="bar-number">({totalCount})</div>
        </div>
    </div>
);

// Bar group component
const StarRatingBarGroup = ({ data }) => {
    const navigate = useNavigate();

    // navigate to different star rating to transaction page
    const handleStarRatingNav = (starRating) => {
        const url = `/pages/transactions?dataIdx0=CallQualityRatingStar&status0=${starRating}`;
        navigate(url);
    };

    // calculation for various counts
    const totalCount = data?.reduce((sum, item) => sum + item.totalCount, 0);
    const nullCount = data?.find((item) => item.ans === null)?.totalCount || 0;
    const totalRating = totalCount - nullCount;

    // create star mapping
    const starCountMap = new Map(
        data?.map((item) => [item.ans, item.totalCount])
    );

    // star mapping re-order and single bar render function
    const starRatingProgressBars = [5, 2, 4, 1, 3, 0].map((starCount) => {
        const count = starCountMap?.get(starCount) || 0;
        const percent = totalCount ? Math.ceil((count / totalCount) * 100) : 0;
        return (
            <StarRatingProgressBar
                key={starCount}
                starCount={starCount}
                percent={percent}
                totalCount={count}
                showInfo={false}
                handleStarRatingNav={handleStarRatingNav}
            />
        );
    });

    return (
        <div className="bar-list">
            {starRatingProgressBars}
        </div>
    );
};

export default StarRatingBarGroup;
