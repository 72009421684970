import { useEffect, useState } from "react";
import { Table, Row, Col, Spin, Button } from "antd";
import "./deviceUsage.report.scss";
import { useSelector, useDispatch } from "react-redux";
import GetColumnSearchProps from "../../../components/table/search";
import Formatter from "../../../utils/Formatter";
import DropDown from "../../../components/dropdown";
import exportAsCSV from "../../../services/exportToCVS.service";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate} from "react-router-dom";
import { fetchDeviceInfo } from "../../../store/actions/device.action";
import { MemoizedSiteMinuteChart } from "./minutes.bar";
import { setLanguageType } from '../../../store/actions/filter.action';
import { exportToExcel, getSafeTitleName } from "../../../services/exportToExcel";
import moment from "moment";



function formatDataFoPieChartsDevices(d=[], slice) {
    const legends = [],
        transactions = [],
        minutes = [],
        rawData = [];
    let data = JSON.parse(JSON.stringify(d));
    data.forEach((clientSite) =>
      clientSite.Departments.forEach((Department) =>
        Department.Devices.forEach((serialNumber) => rawData.push(serialNumber))
      )
    );
    rawData
    .sort((a, b) => b.ServiceMinutes - a.ServiceMinutes)
    .forEach((serialNumber) => {
        legends.push(serialNumber.DeviceName);
        transactions.push(serialNumber.TotalCalls);
        minutes.push(serialNumber.ServiceMinutes);
    })
    return slice ? {
        legends: legends.slice(0, slice), 
        minutes: minutes.slice(0, slice), 
        transactions: transactions.slice(0, slice)
    } : { legends, minutes, transactions };
}


const DeviceSiteDeptUsage = () => {
    // Necessary hooks and states
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [usage, setUsage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloadType, setDownloadType] = useState("EXCEL");
    const navigate = useNavigate()

    //fetch data on component mount
    useEffect(() => {
        setLoading(true)
        dispatch(fetchDeviceInfo())
        .then((data=[]) => setUsage(data.sort((a, b) => b.ServiceMinutes - a.ServiceMinutes)))
        .then(() => setLoading(false))
    }, [
        state.filter?.languageType, 
        state.filter?.date, 
        state.company?.client,
        state?.profile?.user?.permissions?.company
    ]);

    // Select Download Type
    const downloadFile = () => {
        if (downloadType === "EXCEL") {
            downLoadExcel();
        }
    };

    //options in download dropdown
    const downloadDropdownType = [
        {
            value: "EXCEL",
            label: "EXCEL",
        },
    ];

    const downLoadExcel = () => {
        const departments = [];
        const sites = [];
        const sitesDeptsDevices = [];
        const dynamicTitles = []

        for (let i = 0; i < usage.length; i++) {
            const siteRow = usage[i];
            sites.push({
                "Client Site": siteRow.Site,
                "Minutes Used": siteRow.ServiceMinutes,
                "Number Of Transactions": siteRow.TotalCalls
            });
            for (let j = 0; j < siteRow.Departments?.length; j++) {
                const deptRow = siteRow.Departments[j];
                departments.push({
                    "Department": deptRow.Department,
                    "Minutes Used": deptRow.ServiceMinutes,
                    "Number Of Transactions": deptRow.TotalCalls
                })
                const siteDeptDevices = [];
                for (let k = 0; k < deptRow.Devices?.length; k++) {
                    const deviceRow = deptRow.Devices[k];
                    siteDeptDevices.push({
                        "Device Name": deviceRow.DeviceName,
                        "RFID": deviceRow.RFID,
                        "Serial Number": deviceRow.IOSSerialNumber,
                        "Minutes Used": deviceRow.ServiceMinutes,
                        "Device Owner": deviceRow.DeviceOwner,
                        "Number Of Transactions": deviceRow.TotalCalls
                    })
                }
                let siteacr = siteRow.Site?.split(" ").map(e => e[0]).join("");
                let deptacr = deptRow.Department?.split(" ").map(e => e[0]).join("");
                let safeName = getSafeTitleName(`${siteacr}->${deptacr}`);
                dynamicTitles.push(dynamicTitles.includes(safeName) ? safeName+j : safeName);
                sitesDeptsDevices.push(siteDeptDevices);
            }
        }


        // Create File Name
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.date?.dateRangeLabel;
        const filename = `Device_Usage_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}.xlsx`;


        exportToExcel(
            [sites, departments, ...sitesDeptsDevices],
            ["Sites", "Departments", ...dynamicTitles],
            filename
        )
    };  
  
    //function to handle navigation to transaction page based on client site
    const handleNavigationClick = (ClientSite) => {
        dispatch(setLanguageType("-1"));
        navigate(`/pages/transactions?dataIdx0=Site&status0=${ClientSite}`)
    }
   
    //function to handle navigation to transaction page based on IOSSerialNumber of a device
    const handleDeviceNavigationClick = (IOSSerialNumber) => {
        dispatch(setLanguageType("-1"));
        navigate(`/pages/transactions?dataIdx0=IOSSerialNumber&status0=${IOSSerialNumber}`)
    }

    const DepartmentRowRender = (records) => {
        const columns = [
            {
                title: "Department",
                dataIndex: "Department",
                key: "Department",
                sorter: (a, b) => a.Department.localeCompare(b.Department),
          
            },
            {
                title: "Minutes Used",
                dataIndex: "ServiceMinutes",
                key: "ServiceMinutes",
                sorter: (a, b) => a.ServiceMinutes - b.ServiceMinutes,
                defaultSortOrder: 'descend'
            
            },
            {
                title: "Number Of Transactions",
                dataIndex: "TotalCalls",
                key: "TotalCalls",
                sorter: (a, b) => a.TotalCalls - b.TotalCalls,
                render: (text) => text
                // render: (text, record) => (
                //     <a
                //         onClick={() => handleNavigationClick(record.ClientSite)}
                //     >
                //         {text}
                //     </a>
                // ),
            }
        ];
    
        return (
            <>
                <Table
                    bordered
                    size="small"
                    title={() => <>Departments</>}
                    footer={() => <></>}
                    columns={columns} 
                    dataSource={records?.Departments || []} 
                    pagination={false} 
                    rowKey={(record) => record?.Department}
                    expandable={{
                        expandedRowRender: DeviceRowRender,
                        defaultExpandAllRows: false
                    }}
                />
            </>
        )
        ;
    };

    //define expanded row columns
    const DeviceRowRender = (records) => {
        const columns = [
            {
                title: "Device Name",
                dataIndex: "DeviceName",
                key: "DeviceName",
                sorter: (a, b) => a.DeviceName.localeCompare(b.DeviceName),
          
            },
            {
                title: "RFID",
                dataIndex: "RFID",
                key: "RFID",
                sorter: (a, b) => a.RFID.localeCompare(b.RFID),
          
            },
            {
                title: "Serial Number",
                dataIndex: "IOSSerialNumber",
                key: "IOSSerialNumber",
                sorter: (a, b) => a.IOSSerialNumber.localeCompare(b.IOSSerialNumber),
          
            },
            {
                title: "Minutes Used",
                dataIndex: "ServiceMinutes",
                key: "ServiceMinutes",
                sorter: (a, b) => a.ServiceMinutes - b.ServiceMinutes,
                defaultSortOrder: 'descend'
            
            },
            {
                title: "Device Owner",
                dataIndex: "DeviceOwner",
                key: "DeviceOwner",
            },
            {
                title: "Number Of Transactions",
                dataIndex: "TotalCalls",
                key: "TotalCalls",
                sorter: (a, b) => a.TotalCalls - b.TotalCalls,
                render: (text, record) => (
                    <a
                        onClick={() => handleDeviceNavigationClick(record.IOSSerialNumber)}
                    >
                        {text}
                    </a>
                ),
            },
        ];
    
        return (
            <>
                <Table
                    bordered
                    size="small"
                    title={() => <>Devices</>}
                    footer={() => <></>}
                    columns={columns} 
                    dataSource={records?.Devices || []} 
                    pagination={false} 
                    rowKey={(record) => record?.IOSSerialNumber}
                />
            </>
        )
        ;
    };

    // Define the Department columns for the table
    const SiteColumns = [
        {
            title: "Client Site",
            dataIndex: "Site",
            key: "Site",
            sorter: (a, b) => a.Site.localeCompare(b.Site),
            ...GetColumnSearchProps({dataIndex: 'Site', isDate:false}),
        },
        {
            title: "Minutes Used",
            dataIndex: "ServiceMinutes",
            key: "ServiceMinutes",
            sorter: (a, b) => a.ServiceMinutes - b.ServiceMinutes,
            defaultSortOrder: 'descend'
        
        },
        {
            title: "Number Of Transactions",
            dataIndex: "TotalCalls",
            key: "TotalCalls",
            sorter: (a, b) => a.TotalCalls - b.TotalCalls,
            render: (text) => text
            // render: (text, record) => (
            //     <a
            //         onClick={() => handleNavigationClick(record.ClientSite)}
            //     >
            //         {text}
            //     </a>
            // ),
        }
    ];

    return (
    <Row>
        <Col className='language-col' flex={"auto"}>
        {loading ? ( // Check if loading state is true
            <div className="loading-container">
            <Spin size="large" />
            <span className="loading-text">Cooking up your data...</span>
            </div> // Show the loader while data is being fetched
        ) : (
            <>
            <Row wrap={false} gutter={20}>
                <Col flex={"50%"}>
                    <MemoizedSiteMinuteChart
                        {...{
                            legends: usage?.map(e => e.Site),
                            transactions: usage?.map(e => e.TotalCalls),
                            minutes: usage?.map(e => e.ServiceMinutes),
                            loading
                        }}
                        heroTitle={"Usage by Client Site"}
                    />
                </Col>
                <Col flex={"50%"}>
                    <MemoizedSiteMinuteChart
                        {...formatDataFoPieChartsDevices(usage, 10)}
                        heroTitle={"Usage by Top Devices"}
                        loading={loading}
                    />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col flex={"auto"}>
                <Table
                    bordered
                    size="middle"
                    title={() => (
                        <Row
                            gutter={[8, 8]}
                            justify="end"
                            align="middle"
                        >   
                            <Col>
                                <DropDown
                                    defaultValue={downloadType}
                                    dropdownOptions={
                                        downloadDropdownType
                                    }
                                    handleTypeSelect={(
                                        downloadType
                                    ) =>
                                        setDownloadType(
                                            downloadType
                                        )
                                    }
                                />
                            </Col>
                            <Col>
                                <Button
                                    loading={loading}
                                    type="primary"
                                    onClick={downloadFile}
                                >
                                    Download
                                </Button>
                            </Col>
                        </Row>
                    )}
                    footer={() => <></>}
                    sticky
                    dataSource={usage}
                    columns={SiteColumns}
                    pagination={false}
                    rowKey={(record) => record?.Site}
                    expandable={{
                        expandedRowRender: DepartmentRowRender,
                        defaultExpandAllRows: false
                    }}
                />
                </Col>
            </Row>
            </>
        )}             
        </Col>
    </Row>
)
};

export default DeviceSiteDeptUsage; // Export the DeviceSiteUsage component
