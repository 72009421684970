// Import necessary libraries and utilities
import { theme } from 'antd';
import ReactECharts from 'echarts-for-react';
import PropTypes from 'prop-types';

// LanguageDistributionBar component definition
const LanguageDistributionBar = ({ data, interpretation, avgTime }) => {
    // Extract color configurations from the theme
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();

    // Log interpretation data for debugging purposes
    console.log(interpretation);

    // Define the getOption function to set ECharts options
    const getOption = () => {
        // Define colors for different chart elements
        let colors = [
            '#0f9191',
            '#0f9191',
            '#0f9191',
            '#0f9191'
        ];

        // Return the options object for the chart
        return {
            // Toolbox configuration for chart actions
            toolbox: {
                right: 100,
                feature: {
                    saveAsImage: {
                        backgroundColor: colorBgLayout, // Set background color for saved image
                        color: colorText
                    },
                }
            },
            // Legend settings for data categories
            legend: {
                data: ['Total Mins', 'Audio Mins', 'Video Mins', 'Avg. Length'],
                align: 'left',
                top: 0,
                textStyle: { color: colorText },
            },
            // Tooltip settings for displaying information on hover
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%'];
                },
                axisPointer: {
                    type: 'shadow',
                },
                itemStyle: {
                    color: colorText,
                }
                // formatter: ([{data,axisValueLabel}]) => {
                //   return `${axisValueLabel}</br>${Formatter.numberWithCommas(Number(data.value))} Minutes`
                // }
            },
            // Grid settings for positioning and style
            grid: {
                top: 40,
                left: 10,
                right: 10,
                bottom: 50,
                containLabel: true,
                background: 'black'
            },
            // X-axis configuration
            xAxis: [
                {
                    type: "category",
                    data: data?.legends,
                    axisTick: {
                        alignWithLabel: true,
                        color: colorText,
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                        interval: 1,
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                },
            ],
            // Y-axis configurations
            yAxis: [
                {
                    name: "Total Mins",
                    type: "log",
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                        formatter: '{value} min'
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                },
                {
                    type: 'value',
                    name: 'Avg. call length',
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                        formatter: '{value} min'
                    },
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                }
            ],
            // Series data for the chart
            series: [
                {
                    name: "Total Mins",
                    type: "bar",
                    data: data?.seriesData,
                    itemStyle: {
                        color: '#54c9e8'
                    }
                },
                {
                    name: 'Audio Mins',
                    type: 'bar',
                    stack: 'interpretation',
                    data: interpretation?.audio?.seriesData,
                    itemStyle: {
                        color: '#203870'
                    }
                },
                {
                    name: 'Video Mins',
                    type: 'bar',
                    stack: 'interpretation',
                    data: interpretation?.video?.seriesData,
                    itemStyle: {
                        color: '#ff876f'
                    }
                },
                {
                    name: 'Avg. Length',
                    type: 'bar',
                    stack: 'Average Call Length',
                    yAxisIndex: 1,
                    data: avgTime?.seriesData,
                    itemStyle: {
                        color: '#226885'
                    }
                }
            ],
            // Data zoom settings for zoom functionality
            dataZoom: [
                {
                    show: true,
                    start: 0,
                    left: 4,
                    right: 4,
                    end: data.seriesData?.length > 10 ? Math.round((9 / data.seriesData.length) * 100) : 100,
                },
                {
                    show: true,
                    type: 'inside',
                    start: 0,
                    left: 10,
                    right: 10,
                    end: data.seriesData?.length > 10 ? Math.round((9 / data.seriesData.length) * 100) : 100,
                },
            ],
        };
    };

    // Render the chart using ReactECharts component
    return (
        <ReactECharts
            option={getOption()}
            style={{ height: '255px' }}
        />
    );
};

// PropTypes for LanguageDistributionBar component
LanguageDistributionBar.propTypes = {
    data: PropTypes.array.isRequired, // Ensure 'data' prop is an array and is required
}

export default LanguageDistributionBar;
