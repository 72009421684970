import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setInvoiceType } from "../../store/actions/filter.action";
import { InvoiceOptions } from "../../invoice.enum";

//invoice filter
const InvoiceFilter = (props) => {

    const dispatch = useDispatch();
    //get data from redux for type of invoice
    const type = useSelector((state) => state?.filter?.invoiceType)

    //display invoice filter component 
    return (
        <Radio.Group 
            buttonStyle="solid"
            options={InvoiceOptions}
            optionType="button"
            value={type}
            onChange={(e) => dispatch(setInvoiceType(e.target.value))}
        />
    )
}

export default InvoiceFilter