import { Card } from 'antd';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDrag } from 'react-dnd'

// Component for draggable square within a card
const SquareDrag = ({ size, loading, children, title, style={}, onClick, edit, onDrag, id, className}) => {

    // Use the 'useDrag' hook from react-dnd to enable dragging functionality
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "widgets",
        collect: (monitor) => ({
            isDragging: !!monitor.isDragging()
        })
    }))

    // Trigger effect on drag state change
    useEffect(() => {
        if (isDragging) {
            onDrag(id)
        }
    }, [isDragging])

    return (
        // Set class names based on size, edit mode, and provided className
        <Card
            className={ 
                (size == "large" ? "active-devices-card" :
                size == "medium" ? "widget-cards" : 
                size == "small" ? "calls-card" : "stars-card")
                + (edit ? " widget-cards-no-hover" : "") + (className ? (" " + className) : "")
            }
            // Reference drag if in edit mode, otherwise undefined
            ref={ edit ? drag : undefined}
            bordered={false}
            loading={loading}
            title={title}
            style={{
                ...style, 
                position: "absolute", 
                top: 0, 
                width: "100%",
                opacity: isDragging ? 0.5 : 1,
                cursor: edit ? 'move' : "pointer",
            }}
            onClick={onClick}
        >
            {children}
        </Card>
    )
    
}

// Prop type definitions for SquareDrag component
SquareDrag.propTypes = {
    size: PropTypes.oneOf(['large', 'small', 'medium']),  // Size of the square
    loading: PropTypes.bool.isRequired                    // Loading state
}

export default SquareDrag; // Export the SquareDrag component