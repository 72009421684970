import { theme } from 'antd';
import ReactECharts from 'echarts-for-react';
import PropTypes from 'prop-types';

// Component for displaying Language Distribution Bar using ECharts
const LanguageDistributionBar = ({ data, audioType, videoType }) => {
    // Extracting necessary values from the Ant Design theme
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();

    // Function to generate options for ECharts bar chart
    const getOption = () => {
        // Array of colors for the chart
        let colors = [
            '#226885',
            '#0f9191',
            '#61636f',
            '#6c40a6'
        ];

        // Initial zoom settings for the chart
        const initialZoomStart = 0;
        return {
            toolbox: {        
                right: 90,
                feature: {      
                    saveAsImage: {
                        backgroundColor: colorBgLayout, // Set background color for saved image
                        color: colorText 
                    },      
                }      
            },
            legend: {
                data: ['Total', 'Audio', 'Video',],
                align: 'left',
                top: 0,
                textStyle: {color: colorText},
            },
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                  return [pt[0], '10%'];
                },
                axisPointer: {
                  type: 'shadow',
                },
                itemStyle: {
                    color: colorText,
                }
                // formatter: ([{data,axisValueLabel}]) => {
                //   return `${axisValueLabel}</br>${Formatter.numberWithCommas(Number(data.value))} Minutes`
                // }
            },
            grid: {
                top: 40,
                left: 10,
                right: 40,
                bottom: 50,
                containLabel: true,
                background: 'black'
            },
            xAxis: [
                {
                    type: "category",
                    data: data?.legends,
                    axisTick: {
                        alignWithLabel: true,
                        color: colorText,
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    }
                    
                },
            ],
            yAxis: [
                {
                    name: "Total Mins",
                    type: "value",
                    offset: 1,
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                        formatter: '{value} min'
                    },
                
                },

            ],
            series: [
                {
                    name: "Total",
                    type: "bar",
                    data: data?.seriesData,
                    itemStyle: {
                        color: '#54c9e8'
                    }
                },
                {
                    name: 'Audio',
                    type: 'bar',
                    stack: 'audioType',
                    data: audioType?.audio?.seriesData,
                    itemStyle: {
                        color: '#203870'
                    }
                },
                {
                    name: 'Video',
                    type: 'bar',
                    stack: 'Average Call Length',
                    data: videoType?.video?.seriesData,
                    itemStyle: {
                        color: '#ff876f'
                    }
                },
            ],
            dataZoom: [
                {
                  show: true,
                  start: initialZoomStart,
                  start: 0,
                  left: 4,
                  right: 4,
                  end: 100,
                },
                {
                  show: true,
                  type: 'inside',
                  start: initialZoomStart,
                  start: 0,
                  left: 10,
                  right: 10,
                  end: 100,
                  end: data.seriesData?.length > 10 ? Math.round((9 / data.seriesData.length) * 100) : 100,
                },
              ],
          
        };
    };

  return (
    <ReactECharts
      option={getOption()}
      style={{ height: '300px' }}
    />
  );
};

// Prop types definition for the LanguageDistributionBar component
LanguageDistributionBar.propTypes = {
    data: PropTypes.array.isRequired, // Required prop for data (array)
};

export default LanguageDistributionBar; // Exporting the LanguageDistributionBar component
