import { Button, Card, Col, Divider, Form, Input, Row, message } from "antd";
import { useEffect, useRef, useState } from "react";
import dots from '../../images/dlogo1.png';
import { Link, useNavigate } from "react-router-dom";
import { CheckCircleFilled, CloseCircleFilled, MinusCircleFilled } from "@ant-design/icons";
import { signup } from "../../store/actions/profile.action";
import { useDispatch } from "react-redux";
import Validator from "../../utils/validator";

const Signup = () => {
    const [width, setWidth] = useState(700);
    const elementRef = useRef(null);
    const [password, setPassword] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        setWidth(elementRef.current.clientWidth);
    }, [elementRef.current?.clientWidth]);

    const renderCriterias = () => {
        return Validator.PasswordRules.map((criteria) => {
            return (
                <Row style={{paddingLeft: 11}} gutter={10}>
                    <Col>
                        {
                            password.trim() == '' ? <MinusCircleFilled /> : 
                            password.match(criteria.rule) ? 
                            <CheckCircleFilled style={{color: '#49aa19'}}/> : 
                            <CloseCircleFilled style={{color: '#dc4446'}}/>
                        }
                    </Col>
                    <Col>{criteria.name}</Col>
                </Row>
            )
        });
    }

    const handleFormSubmit = async (values) => {
        let email = values.email.split('@')[0]+'@voyceglobal.com'
        let created = await dispatch(signup({
            ...values,
            email
        }));
        if (!created) return;
        message.success("We sent you an activation mail to "+email+".", 3000);
        navigate("/auth/signin");
    }

    return (
        <div className="login-container">
            <Row className="login-row" justify={"center"} align={"middle"}>
                <Col style={{width: 500, padding: 20}}>
                <Card ref={elementRef} bordered={false}>
                    <Row gutter={20} className="title-row" justify={"center"} align={"middle"} >
                        <img src={dots} height={24}/>
                        <Col>VIP Admin</Col>
                    </Row>
                    <br/>
                    <Form onFinish={handleFormSubmit} style={{width: '100%'}}>
                    <Row align={"stretch"}>
                        <Col flex={"auto"} >
                            <Form.Item
                                name="firstname"
                                rules={[{ required: true, message: 'Please input your first name!' }]}
                            >
                                <Input placeholder="First Name" />
                            </Form.Item>
                            <Form.Item
                                name="lastname"
                                rules={[{ required: true, message: 'Please input your last name!' }]}
                            >
                                <Input placeholder="Last Name" />
                            </Form.Item>
                            <Form.Item
                                name="email"
                                rules={[{ required: true}]}
                            >
                                <Input placeholder="E-mail" addonAfter="@voyceglobal.com"  />
                            </Form.Item>
                        {/* </Col> */}
                        {/* <Col flex={ width > 690 ? "4%" : "100%"}/> */}
                        {/* <Col flex={width < 690 ? "auto" : "48%"} > */}
                            <Form.Item
                                name="password"
                                rules={[{ 
                                        required: true, 
                                        message: 'Please input your password!' 
                                    },
                                    () => ({
                                        validator(_, value) {
                                            if (!value || Validator.validatePassowrd(value)) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('Password entry does not meet criteria'));
                                        },
                                    })
                                ]}
                            >
                                <Input.Password onChange={((e) => setPassword(e.target.value))} placeholder="Password" />
                            </Form.Item>
                            
                            <Form.Item
                                name="confirm"
                                dependencies={['password']}
                                hasFeedback
                                rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!',
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The two passwords that you entered do not match!'));
                                    },
                                }),
                                ]}
                            >
                                <Input.Password placeholder="Confirm Password" />
                            </Form.Item>
                            <Col>{renderCriterias()}</Col>
                            <br/>
                            <Row justify={"center"}>
                                <Form.Item>
                                    <Button style={{width: 180}} shape="round" type="primary" htmlType="submit" >Create an account</Button>
                                </Form.Item>
                            </Row>
                        </Col>
                    </Row>
                    </Form>
                    <p style={{"textAlign": "center", color: "grey"}}>
                        Already have an account? <i><Link to="/auth/login">Sign In</Link></i>
                    </p>
                </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Signup;