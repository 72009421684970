import { useEffect, useState } from "react";
import { Table, Row, Col, Spin, Button } from "antd";
import "./deviceUsage.report.scss";
import { useSelector, useDispatch } from "react-redux";
import { getTotalActiveDevices } from "../../../store/actions/analytics.action";
import Service from "../../../services";
import GetColumnSearchProps from "../../../components/table/search";
import Formatter from "../../../utils/Formatter";
import DropDown from "../../../components/dropdown";
import exportAsCSV from "../../../services/exportToCVS.service";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate, useLocation } from "react-router-dom";
import { MemoizedSiteMinuteChart } from "./minutes.bar";
import { MemoizedUsageChart } from "./usage.bar";
import { setLanguageType } from "../../../store/actions/filter.action";
import moment from "moment";
import { exportToExcel } from "../../../services/exportToExcel";
const DeviceUsage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const state = useSelector((state) => state);
  const [kandjiDeviceInfo, setKandiDeviceInfo] = useState(null);
  const navigate = useNavigate();

  const userCompany = useSelector(
    (state) => state?.profile?.user?.permissions?.company
  );
  const activeCompanies = useSelector((state) => state.company?.active || []);

  const [loading, setLoading] = useState(false);
  const [downloadType, setDownloadType] = useState("EXCEL");

  // Function to get dashboard filters from the URL
  const getDashboardFilters = () => {
    const searchParams = new URLSearchParams(location.search);
    let filters = {};

    for (let i = 0; ; i++) {
      const dataIdx = searchParams.get(`dataIdx${i}`);
      const status = searchParams.get(`status${i}`);

      if (dataIdx && status) {
        filters[dataIdx] = status;
      } else {
        break;
      }
    }

    return filters;
  };

  const dashboardFilters = getDashboardFilters();

  // Function to fetch Kandji device information
  const fetchDeviceInfo = async (data) => {
    try {
      setLoading(true);
      const response = await Service.Device().getDeviceInfo(data);
      setKandiDeviceInfo(response);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    let code = activeCompanies.find(
      (companyFullObject) =>
        companyFullObject?._id?.toString() == userCompany?.toString()
    )?.CompanyCode;
    if (code) {
      const data = { blueprintName: code };
      fetchDeviceInfo(data);
      dispatch(getTotalActiveDevices({ RequestCompanyId: userCompany }));
    }
  }, [
    state.filter?.languageType,
    state.filter?.date,
    state.company?.client,
    userCompany,
    activeCompanies,
  ]);

  // Access the data from the Redux store using useSelector
  const deviceUsageData = useSelector(
    (state) => state.analytics.totalActiveCarts
  );

  // Initialize counters
  let customerOwnedCount = 0;
  let voyceOwnedCount = 0;

  // Filter the data based on the CompanyId
  let filteredDeviceUsageData = deviceUsageData
    ?.filter((item) => item.CompanyId === userCompany)
    .map((item) => {
      // Find the corresponding device info from kandjiDeviceInfo based on serial number
      const deviceInfo = kandjiDeviceInfo?.find(
        (device) => device.serialNumber === item.IOSSerialNumber
      );

      // If deviceInfo is found, update the item with device name and type
      if (deviceInfo) {
        // Increment the voyceOwnedCount for each "Voyce Owned" device
        voyceOwnedCount++;

        return {
          ...item,
          deviceName: deviceInfo.deviceName,
          macAddress: deviceInfo.mac_address,
          deviceType: "Voyce Owned",
        };
    } else if (item.DeviceName && item.DeviceName !== 'Unknown') {
      // If we have a device name from the query, it's a customer-owned device with a name
      customerOwnedCount++;
      return {
        ...item,
        deviceName: item.DeviceName,
        deviceType: "Customer Owned",
      };
    } else {
      // If no device info is found, set the device name as "Unknown" and device type as "External"
      customerOwnedCount++;
      return {
        ...item,
        deviceName: "Unknown",
        deviceType: "Customer Owned",
      };
    }
  });

  // Select Download Type
  const downloadFile = () => {
    if (downloadType === "PDF") {
      downloadPDF();
    } else if (downloadType === "EXCEL") {
      downloadEXCELFunction();
    }
  };

  const downloadDropdownType = [
    {
      value: "PDF",
      label: "PDF",
    },
    {
      value: "EXCEL",
      label: "EXCEL",
    },
  ];

  // PDF download functionality
  const downloadPDF = () => {
    let downloadArray = [];
    for (let list of filteredDeviceUsageData) {
      let newList = {
        "Device Name": list?.deviceName,
        "Serial Number": list?.IOSSerialNumber,
        "Mac Address": list?.macAddress,
        "Minutes Used": Formatter.numberWithCommas(list?.ServiceMinutes),
        "Device Owner": list?.deviceType,
        "Number of Transactions": list.NumberOfServices,
      };
      downloadArray.push(newList);
    }

    const headerList = [
      "Device Name",
      "Serial Number",
      "Mac Address",
      "Minutes Used",
      "Device Owner",
      "Number of Transactions",
    ];
    const bodyList = downloadArray.map((item) =>
      Object.values(item).map((value) => String(value))
    );
    const doc = new jsPDF("landscape", "px", "a4");
    doc.setFontSize(18);
    const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
    const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
    const donwloadDateRange = state?.filter?.dateRange;
    const filename = `Device_Usage_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}`;
    doc.text(filename, 30, 20);
    doc.setFontSize(12);
    doc?.autoTable({
      head: [headerList.map((header) => [header])],
      body: bodyList,
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: [33, 33, 33],
      },
      startY: 50,
    });
    doc.save(filename);
  };

  // CSV Download Functionality
  const downloadCSVFunction = () => {
    let downloadArray = [];
    for (let list of filteredDeviceUsageData) {
      let newList = {
        "Device Name": list?.deviceName,
        "Serial Number": list?.IOSSerialNumber,
        "Mac Address": list?.macAddress,
        "Minutes Used": Formatter.numberWithCommas(list?.ServiceMinutes),
        "Device Owner": list?.deviceType,
        "Number of Transactions": list.NumberOfServices,
      };
      downloadArray.push(newList);
    }
    const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
    const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
    const donwloadDateRange = state?.filter?.dateRange;
    const filename = `Device_Usage_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}`;
    exportAsCSV(
      downloadArray,
      filename,
      [
        "Device Name",
        "Serial Number",
        "Mac Address",
        "Minutes Used",
        "Device Owner",
        "Number of Transactions",
      ],
      "Device Usage Report"
    );
  };

  const downloadEXCELFunction = () => {
    let downloadArray = [];
    for (let list of filteredDeviceUsageData) {
      let newList = {
        "Device Name": list?.deviceName,
        "Serial Number": list?.IOSSerialNumber,
        "Mac Address": list?.macAddress,
        "Minutes Used": Formatter.numberWithCommas(list?.ServiceMinutes),
        "Device Owner": list?.deviceType,
        "Number of Transactions": list.NumberOfServices,
      };
      downloadArray.push(newList);
    }
    const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
    const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
    const donwloadDateRange = state?.filter?.dateRange;
    const filename = `Device_Usage_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}.xlsx`;
    const sheetData = [downloadArray];
    const sheetNames = ["Device Report"];

    exportToExcel(sheetData, sheetNames, filename);
  };

  const handleNavigationClick = (IOSSerialNumber) => {
    dispatch(setLanguageType("-1"));
    navigate(
      `/pages/transactions?dataIdx0=IOSSerialNumber&status0=${IOSSerialNumber}`
    );
  };
  // Define the columns for the table
  const columns = [
    {
      title: "Device Name",
      dataIndex: "deviceName",
      key: "deviceName",
      sorter: (a, b) => a.deviceName.localeCompare(b.deviceName),
      ...GetColumnSearchProps({ dataIndex: "deviceName", isDate: false }),
    },
    {
      title: "Serial Number",
      dataIndex: "IOSSerialNumber",
      key: "IOSSerialNumber",
      sorter: (a, b) => a.IOSSerialNumber.localeCompare(b.IOSSerialNumber),
      ...GetColumnSearchProps({ dataIndex: "IOSSerialNumber", isDate: false }),
    },
    {
      title: "Mac Address",
      dataIndex: "macAddress",
      key: "macAddress",
      sorter: (a, b) => a.macAddress?.localeCompare(b?.macAddress),
      ...GetColumnSearchProps({ dataIndex: "macAddress", isDate: false }),
    },
    {
      title: "Minutes Used",
      dataIndex: "ServiceMinutes",
      key: "ServiceMinutes",
      sorter: (a, b) => a.ServiceMinutes - b.ServiceMinutes,
      defaultSortOrder: "descend",
    },
    {
      title: "Device Owner",
      dataIndex: "deviceType",
      key: "deviceType",
      sorter: (a, b) => a.deviceType.localeCompare(b.deviceType),
      ...GetColumnSearchProps({ dataIndex: "deviceType", isDate: false }),
    },
    {
      title: "Number Of Transactions",
      dataIndex: "NumberOfServices",
      key: "NumberOfServices",
      sorter: (a, b) => a.NumberOfServices - b.NumberOfServices,
      render: (text, record) => (
        <a onClick={() => handleNavigationClick(record.IOSSerialNumber)}>
          {text}
        </a>
      ),
    },
  ];

  //function to format data for usage chart
  function formatDataForUsageChart(d = [], slice = 5) {
    const legends = [],
      transactions = [],
      minutes = [];
    let data = JSON.parse(JSON.stringify(d));
    data
      .sort((a, b) => b.ServiceMinutes - a.ServiceMinutes)
      .slice(0, slice)
      .forEach((clientSite) => {
        legends.push(clientSite.IOSSerialNumber);
        transactions.push(clientSite.NumberOfServices);
        minutes.push(clientSite.ServiceMinutes);
      });
    return { legends, minutes, transactions };
  }

  return (
    <Row className="device-container">
      <Col className="language-col" flex={"auto"}>
        {loading ? ( // Check if loading state is true
          <div className="loading-container">
            <Spin size="large" />
            <span className="loading-text">Cooking up your data...</span>
          </div> // Show the loader while data is being fetched
        ) : (
          <>
            <Row wrap={false} gutter={20} className="charts-row">
              <Col flex={"70%"} className="minutes-chart">
                <MemoizedSiteMinuteChart
                  {...formatDataForUsageChart(filteredDeviceUsageData, 10)}
                  heroTitle={"Usage by Top Devices"}
                  loading={loading}
                />
              </Col>
              <Col flex={"30%"} className="usage-chart">
                <MemoizedUsageChart
                  vodDevices={kandjiDeviceInfo?.length}
                  noUtilizationDevices={
                    kandjiDeviceInfo?.length - voyceOwnedCount
                  }
                  loading={loading}
                  heroTitle={"Device Utilization"}
                />
              </Col>
            </Row>
            <br />
            <Row className="irh-container">
              <Col flex={"100%"}>
                <Table
                  className="voyce-custom-table "
                  title={() => (
                    <Row gutter={[8, 8]} justify="end" align="middle">
                      <Col>
                        <DropDown
                          defaultValue={downloadType}
                          dropdownOptions={downloadDropdownType}
                          handleTypeSelect={(downloadType) =>
                            setDownloadType(downloadType)
                          }
                        />
                      </Col>
                      <Col>
                        <Button
                          loading={loading}
                          type="primary"
                          onClick={downloadFile}
                        >
                          Download
                        </Button>
                      </Col>
                    </Row>
                  )}
                  dataSource={filteredDeviceUsageData}
                  columns={columns}
                  pagination={false}
                  sticky
                />
              </Col>
            </Row>
          </>
        )}
      </Col>
    </Row>
  );
};

export default DeviceUsage; // Export the DeviceUsage component
