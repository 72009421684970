import { Card, Spin, theme } from "antd";
import ReactECharts from "echarts-for-react";
import { memo } from "react";

// SiteMinuteChart component definition
const SiteMinuteChart = ({ legends, transactions, minutes, loading, heroTitle, height }) => {
    // Accessing theme tokens for color configuration
    const { token: { colorText, colorBgLayout } } = theme.useToken();

    // Function to set the chart options
    const getOption = () => {
        // Define colors for chart elements
        let colors = [
            '#203870',
            '#54c9e8',
            '#203870',
            '#203870',
            '#ff9933b4'
        ];
        
        // Return ECharts options for the chart
        return {
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                    return [pt[0], '10%'];
                },
                axisPointer: {
                    type: 'shadow',
                },
            },
            grid: {
                top: 30,
                left: 30,
                right: 20,
                bottom: 2,
                containLabel: true,
            },
            legend: {
                data: ['Transactions', 'Service Minutes'],
                textStyle: {color: colorText},
            },
            xAxis: [
                {
                    type: "category",
                    data: legends || [],
                    axisTick: {
                        alignWithLabel: true,
                        show: false
                    },
                    axisLabel: {
                        overflow: "break",
                        width: 125
                    },
                    axisLine: {
                        lineStyle: {
                          color: colorText, 
                        },
                    },
                },
            ],
            yAxis: [
                {
                    type: "value",
                    name: "Transactions",
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    },
                },
                {
                    type: "value",
                    name: "Service Minutes",
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    },
                    splitLine: {
                        show: false, // Hide y-axis grid lines (horizontal lines)
                    },
                },
            ],
            series: [
                {
                    name: "Transactions",
                    type: "bar",
                    yAxisIndex: 0,
                    data: transactions || [],
                    itemStyle: {color: colors[1]},
                },
                {
                    name: "Service Minutes",
                    type: "bar",
                    yAxisIndex: 1,
                    data: minutes || [],
                    itemStyle: {color: colors[3]},
                }
            ],
            // dataZoom: [
            //     {
            //       show: false,
            //       start: 0,
            //       left: 4,
            //       right: 4,
            //       end: transactions?.length > 10 ? Math.floor((10 / transactions.length) * 100) : 100,
            //     },
            // ],
        };
    };


    return (
       <Spin spinning={loading} >
        <Card style={{height: height ? (height+70) : 290, borderRadius: 4, backgroundColor: colorBgLayout}}>
            <ReactECharts option={getOption()} style={{height: height ? height : 220}} />
            <p style={{textAlign: 'center'}} >{heroTitle}</p>
        </Card>
       </Spin>
    )
}

// Export the SiteMinuteChart component and its memoized version
export default SiteMinuteChart;
export const MemoizedSiteMinuteChart = memo(SiteMinuteChart);
