import ActionType from '../action.types'; // Importing action types
import Service from '../../services'; // Importing service for authentication
import jwtDecode from 'jwt-decode'; // Library to decode JWT tokens
import { message } from 'antd'; // Ant Design message component for displaying notifications

/*
Actions related to user authentication:

- loginError
    Action to dispatch in case of login error.
- operationInProgress
    Action to dispatch when a login operation is in progress.
- loginSuccess
    Action to dispatch upon successful login.
- logout
    Action to log out of the system.
- setTheme
    Action to set the theme.

*/

// Action creators for handling login error
export function loginError(bool, message) {
    return {
        type: ActionType.LOGIN_ERROR,
        hasError: bool,
        message,
        isLoading: false
    };
}

// Action creator for indicating an operation (login) in progress
export function operationInProgress(bool) {
    return {
        type: ActionType.OPERATION_INPROGRESS,
        isLoading: bool
    };
}

// Action creator for successful login
export function loginSuccess({ token, sessionId }, user) {
    return {
        type: ActionType.LOGIN_SUCCESS,
        isLoading: false,
        token,
        sessionId,
        user,
        role: user.permissions.role
    };
}

// Action creator for logout
export function _logout() {
    return {
        type: ActionType.LOGOUT,
        hasError: false,
        isLoading: false,
        token: null,
        sessionId: null,
        user: {}
    };
}

// Action creator for setting the theme
export function setTheme(theme) {
    return {
        type: ActionType.THEME,
        theme
    }
}

/**
 * Signin action for authenticating the user.
 * @param {*} username
 * @param {*} password 
 * @returns 
 */
export function signin({ email, password }) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().login(email.toLowerCase(), password)
            let user = jwtDecode(data.token);
            sessionStorage.setItem('token', data?.token);
            sessionStorage.setItem('session', data?.sessionId);
            dispatch(loginSuccess(data, user));
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
            dispatch(loginError(true, 'error communicating with the server'));
        }
    };
}

// Action creator for user signup
export function signup(userObj) {
    return async (dispatch) => {
        try {
            // Preparing user object for signup
            userObj.designation = "portal-signin";
            userObj.name = `${userObj.firstname} ${userObj.lastname}`;
            userObj.comment = "Create using VIP Admin portal";
            delete userObj.firstname;
            delete userObj.lastname;
            delete userObj.confirm;

            let data = await Service.Auth().signup(userObj);
            return true;
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
            dispatch(loginError(true, 'error communicating with the server'));
        }
    };
}

// Action creator for generating reset password link
export function forgotPassword({ email }) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().forgotPassword(email.toLowerCase());
            if (data) {
                message.success("Email has been successfully sent to " + email);
            } else {
                message.error("Email is not available on the server");
            }
            return data;
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
            dispatch(loginError(true, "error communicating with the server"));
        }
    };
}

// Action creator for resetting password
export function resetPassword(values) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().resetPassword(values);
            if (data) {
                message.success("Password has been successfully updated");
            }
            return data;
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
            dispatch(loginError(true, "error communicating with the server"));
        }
    };
}

// Action creator for logout
export function logout() {
    return async (dispatch) => {
        try {
            let sessionId = sessionStorage.getItem("session");
            await Service.Auth().logout(sessionId);
            sessionStorage.clear();
            dispatch(_logout());
        } catch (err) {
            console.log(err);
        }
    };
}

/**
 * Signin action for authenticating the user.
 * @param {*} username
 * @param {*} password 
 * @returns 
 */
export function ssoSignin({ token }) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().ssoLogin(token)
            let user = jwtDecode(data.token);
            sessionStorage.setItem('token', data?.token);
            sessionStorage.setItem('session', data?.sessionId);
            dispatch(loginSuccess(data, user));
            return data
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
            dispatch(loginError(true, 'error communicating with the server'));
            return false
        }
    };
}
