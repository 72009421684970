import moment from "moment-timezone"; // Library for handling date and time
import timezones from "../timezone_map.json"; // JSON file containing timezone data

class Formatter {
    // Formats a number with commas and adds a prefix if provided
    static numberWithCommas(x = 0, prefix = "") {
        let num = x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (prefix && prefix != "" && num != undefined) {
            return prefix + num;
        }
        return num;
    }

    // Formats a phone number to a specific pattern
    static formatPhoneNumber(phoneNumberString = "") {
        var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            var intlCode = (match[1] ? '+1 ' : '');
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
        }
        return null;
    }

    // Creates a local date and time based on a specified timezone
    static createLocalDateTime(datetime, msTimezone = "") {
        // Finds the timezone type based on the provided timezone string
        let timezone = timezones.find((e) => e.other.toLowerCase() == msTimezone.toLowerCase())?.type;
        // Converts UTC datetime to the specified timezone
        return moment.utc(datetime).tz(timezone);
    }
}

export default Formatter;
