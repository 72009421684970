// Importing axios, store, constants, and necessary actions from respective sources
import axios from 'axios';
import { store } from '..'; // Assuming this path refers to the Redux store
import constants from '../constants';
import { logout, operationInProgress } from '../store/actions/profile.action';

const CUSTOM_FILTER_COMPANIES = ["1598", "1506", "1899", "9001", "2102"]

// Function to create and configure an axios instance
const request = () => {

    // Setting default options for API requests
    const defaultOptions = {
        baseURL: constants.API_BASE_URL, // Base URL for API requests
        headers: {
            'Content-Type': 'application/json', // Setting default content type
        },
    };

    // Function to create a filter for requests based on application state
    const createFilter = () => {
      let state = store.getState(); // Getting the current Redux state
      const company =
        state?.profile?.user?.permissions?.company?.toString() ||
        state?.profile?.user?.permissions?.company;
      // Determining the type of filter based on the company or client state
      let type =
        state?.company?.client && state?.company?.client?.ClientId !== "-1"
          ? "client"
          : "company";
      let id =
        type === "client"
          ? state.company.client.ClientId
          : state?.profile?.user?.permissions?.company;
      if (CUSTOM_FILTER_COMPANIES.includes(company)) {
        type =
          state?.company?.site && state?.company?.site?.SiteId !== "-1"
            ? "site"
            : (state?.company?.region && state?.company?.region?._id !== "-1" && state?.company?.region !== "-1" )
            ? "region"
            : "company";
        id =
          type === "site"
            ? state.company.site.SiteId
            : type === "region"
            ? (state.company.region?._id || "-1")
            : company;
      }
      return (
        {
          filterType: type,
          id: id,
        } || {}
      ); // Returning an empty object if conditions aren't met
    };

    // Create an axios instance with default options
    let instance = axios.create(defaultOptions);

    // Setting up request interceptors
    instance.interceptors.request.use(function (config) {
        const token = sessionStorage.getItem('token'); // Fetching token from session storage
        // Setting authorization header with the retrieved token (if exists)
        config.headers.Authorization = token ? `Bearer ${token}` : '';

        // If 'config.data' and 'config.filter' exist, merge them with the created filter
        if (config.data && config.filter) {
            config.data = {
                ...config.data,
                ...createFilter()
            }
        }

        // Check for the abort signal
        if (config.signal) {
            config.cancelToken = new axios.CancelToken((cancel) => {
                config.signal.addEventListener("abort", cancel);
            });
        }

        // Dispatching an action to indicate an ongoing operation/loading state (unless 'hideLoader' is set)
        if (!config || (config && !config.hideLoader)) {
            /** TODO - ADD LOADER CODE HERE */
            store.dispatch(operationInProgress(true));
        }

        return config;
    }, function (error) {
        console.log('Error');
        return Promise.reject(error);
    });

    // Setting up response interceptors
    instance.interceptors.response.use(function (response) {
        /** TODO - ADD LOADER CODE HERE */
        // Dispatching an action to indicate the operation has finished (loading state set to false)
        store.dispatch(operationInProgress(false));
        return response;
    }, function (error) {
        /** TODO - ADD LOADER CODE HERE */
        // Dispatching an action to indicate the operation has finished (loading state set to false)
        store.dispatch(operationInProgress(false));

        // Check if the error response status is unauthorized (401), then trigger logout action
        if (error.response.status === 401) {
            store.dispatch(logout());
        }
        console.log('Error fetching the data');
        return Promise.reject(error);
    });

    return instance; // Returning the configured axios instance
};

export default request(); // Exporting the configured axios instance
