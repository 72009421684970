// Importing necessary components and styles
import { Outlet } from "react-router-dom";
import { Col, Layout, Row } from "antd";
import { SideMenu, Header } from "../../components/layout";
import "./layout.scss"; // Styles for the layout
import dots from "../../images/dlogo1.png"; // Importing an image
import { useState } from "react"; // Importing useState hook
import Spinner from "../../components/spinner"; // Spinner component

const { Content, Footer } = Layout;

// Main component representing the core layout of the application
const Main = () => {
    // State to manage the collapse/expand state of the SideMenu component
    const [collapsed, setCollapsed] = useState(true);

    return (
        <Layout
            hasSider
            style={{
                minHeight: "100vh", // Setting minimum height to full viewport height
            }}
        >
            {/* Spinner component indicating loading */}
            <Spinner />

            {/* Side menu component */}
            <SideMenu {...{ collapsed, setCollapsed }} />

            {/* Layout for the main content and header */}
            <Layout className="site-layout">
                {/* Header component */}
                <Header {...{ collapsed, setCollapsed }} />

                {/* Content section */}
                <Content className="content-container">
                    {/* Outlet for rendering nested routes */}
                    <Outlet />
                </Content>

                {/* Footer section */}
                <Footer
                    className="footer-container"
                    style={{ textAlign: "center" }}
                >
                    {/* Footer content */}
                    <Row justify={"center"} align={"middle"} gutter={5}>
                        {/* Logo */}
                        <Col>
                            <img
                                style={{ marginTop: 5 }}
                                alt="logo"
                                src={dots}
                                height={14}
                            />
                        </Col>

                        {/* Brand name */}
                        <Col>Voyce.</Col>

                        {/* Year */}
                        <Col>
                            <span>© {new Date().getFullYear()} </span>
                        </Col>

                        {/* Rights */}
                        <Col>All rights reserved.</Col>
                    </Row>
                </Footer>
            </Layout>
        </Layout>
    );
};

export default Main; // Exporting the Main component
