import {  Typography, Col, Rate, Row, Spin, Table, theme } from "antd";
import { LinkOutlined } from "@ant-design/icons";
import "./language.report.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useState } from "react";
import { getLanguagesDetailsTz } from "../../../store/actions/language.action";
import Formatter from "../../../utils/Formatter";
import { useNavigate } from "react-router-dom";
import exportAsCSV from "../../../services/exportToCVS.service";
import jsPDF from "jspdf";
import GetColumnSearchProps from "../../../components/table/search";
import { setLanguageType } from "../../../store/actions/filter.action";
import "jspdf-autotable";
import HeatMap from "./heatmap.chart";

// LanguageHeatmapReport component definition
const LanguageHeatmapReport = () => {
  // Extract necessary variables and methods using React hooks
  const {
    token: { colorBgLayout },
  } = theme.useToken();
  const [loading, setLoading] = useState(false);
  // Retrieve states from Redux store
  const filter = useSelector((state) => state.filter);
  const company = useSelector((state) => state.company);
  const language = useSelector((state) => state.language);
  // Define and manage local state variables
  const [downloadType, setDownloadType] = useState("CSV");
  const [selectedHour, setHour] = useState({});
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Fetch language details callback function
  const fetchLanguagesCB = useCallback(async () => {
    // Dispatch action to retrieve language details
    dispatch(getLanguagesDetailsTz(true))
      .then(() => setLoading(false))
      .catch(() => setLoading(false));
  }, []);

  // Fetch language details on component mount or state change
  useEffect(() => {
    setLoading(true);
    fetchLanguagesCB();
  }, [filter?.date, company?.client, company?.region, company?.site]);

  // Handle setting selected hour's details
  const setSelectedHour = (response) => {
    setHour({
      ...response,
      data: response.data?.map((list) => {
        const avg = list?.CallQualityRatingStar / list?.CountRatingStarCalls;
        return {
          ...list,
          TargetLanguage: response.language || list?.language,
          totalCalls: Formatter.numberWithCommas(list?.TotalCalls),
          serviceMins: Formatter.numberWithCommas(list?.ServiceMinutes),
          CountSuccessAudioCalls: Formatter.numberWithCommas(
            list?.CountSuccessAudioCalls
          ),
          CountSuccessVideoCalls: Formatter.numberWithCommas(
            list?.CountSuccessVideoCalls
          ),
          avgWaitingSeconds: `${
            list?.TotalCalls
              ? Formatter.numberWithCommas(
                  Math.round(list?.WaitingSeconds / list?.TotalCalls)
                )
              : 0
          } seconds`,
          avgLength: `${
            list?.TotalCalls
              ? Formatter.numberWithCommas(
                  Math.round(list?.ServiceMinutes / list?.TotalCalls)
                )
              : 0
          } minutes`,
          avgRating: avg,
        };
      }),
    });
  };

  let dataSource = language.rawData?.map((list) => {
    const avg = list.CallQualityRatingStar / list.CountRatingStarCalls;
    return {
      ...list,
      TargetLanguage: list?.TargetLanguage,
      totalCalls: Formatter.numberWithCommas(list?.TotalCalls),
      serviceMins: Formatter.numberWithCommas(list?.ServiceMinutes),
      CountSuccessAudioCalls: Formatter.numberWithCommas(
        list?.CountSuccessAudioCalls
      ),
      CountSuccessVideoCalls: Formatter.numberWithCommas(
        list?.CountSuccessVideoCalls
      ),
      avgWaitingSeconds: `${Formatter.numberWithCommas(
        Math.round(list?.WaitingSeconds / list?.TotalCalls)
      )} seconds`,
      avgLength: `${Formatter.numberWithCommas(
        Math.round(list?.ServiceMinutes / list?.TotalCalls)
      )} minutes`,
      avgRating: avg,
    };
  });

    // Define columns for the table displaying hourly details
    const hourColumns = [
      {
        title: "Language Name",
        dataIndex: "TargetLanguage",
      },
      {
        title: "Total Calls",
        dataIndex: "totalCalls",
      },
      {
        title: "Total Minutes",
        dataIndex: "serviceMins",
      },
      {
        title: "Audio Calls",
        dataIndex: "CountSuccessAudioCalls",
      },
      {
        title: "Video Calls",
        dataIndex: "CountSuccessVideoCalls",
      },
      {
        title: "Avg. Wait Time",
        dataIndex: "avgWaitingSeconds",
      },
      {
        title: "Avg. Call Length",
        dataIndex: "avgLength",
      },
      {
        title: "Avg. Rating",
        dataIndex: "avgRating",
        width: '150px',
        render: (text, row) => (
          <Rate style={{ color: "#54c9e8" }} allowHalf value={text} disabled />
        ),
      },
      {
        title: "Completed Calls",
        dataIndex: "TargetLanguage",
        align: "center",
        render: (text, record) => (
          <a
            onClick={() => handleNavigationHourClick(record)}
            style={{ textDecoration: "underline" }}
          >
            {Number(record?.CountSuccessVideoCalls) +
              Number(record?.CountSuccessAudioCalls)}{" "}
            <LinkOutlined />
          </a>
        ),
      },
    ];
  
    // Define columns for the main language report table
    const columns = [
      {
        title: "Language Name",
        dataIndex: "TargetLanguage",
        sorter: (a, b) => a?.TargetLanguage?.localeCompare(b?.TargetLanguage),
        ...GetColumnSearchProps({ dataIndex: "TargetLanguage", isDate: false }),
      },
      {
        title: "Total Calls",
        dataIndex: "totalCalls",
        sorter: (a, b) => {
          const aValue = parseFloat(a?.totalCalls?.replace(/,/g, ""));
          const bValue = parseFloat(b?.totalCalls?.replace(/,/g, ""));
          return aValue - bValue;
        },
        defaultSortOrder: "descend",
      },
      {
        title: "Total Minutes",
        dataIndex: "serviceMins",
        sorter: (a, b) => {
          const aValue = parseFloat(a?.serviceMins?.replace(/,/g, ""));
          const bValue = parseFloat(b?.serviceMins?.replace(/,/g, ""));
          return aValue - bValue;
        },
      },
      {
        title: "Serviced Audio Calls",
        dataIndex: "CountSuccessAudioCalls",
        sorter: (a, b) => {
          const aValue = parseFloat(a?.CountSuccessAudioCalls?.replace(/,/g, ""));
          const bValue = parseFloat(b?.CountSuccessAudioCalls?.replace(/,/g, ""));
          return aValue - bValue;
        },
      },
      {
        title: "Serviced Video Calls",
        dataIndex: "CountSuccessVideoCalls",
        sorter: (a, b) => {
          const aValue = parseFloat(a?.CountSuccessVideoCalls?.replace(/,/g, ""));
          const bValue = parseFloat(b?.CountSuccessVideoCalls?.replace(/,/g, ""));
          return aValue - bValue;
        },
      },
      {
        title: "Avg. Wait Time",
        dataIndex: "avgWaitingSeconds",
        sorter: (a, b) => {
          const aValue = parseFloat(a?.avgWaitingSeconds?.replace(/,/g, ""));
          const bValue = parseFloat(b?.avgWaitingSeconds?.replace(/,/g, ""));
          return aValue - bValue;
        },
      },
      {
        title: "Avg. Call Length",
        dataIndex: "avgLength",
        sorter: (a, b) => {
          const aValue = parseFloat(a?.avgLength?.replace(/,/g, ""));
          const bValue = parseFloat(b?.avgLength?.replace(/,/g, ""));
          return aValue - bValue;
        },
      },
      {
        title: "Avg. Rating",
        dataIndex: "avgRating",
        sorter: (a, b) => a?.avgRating - b?.avgRating,
        width: '300px !important',
        render: (text, row) => (
          <Rate style={{ color: "#54c9e8" }} allowHalf value={text} disabled />
        ),
      },
      {
        title: "Completed Calls",
        dataIndex: "TargetLanguage",
        align: "center",
        render: (text, record) => (
          <a
            onClick={() => handleNavigationClick(record)}
            style={{ textDecoration: "underline" }}
          >
            {Number(record?.CountSuccessVideoCalls) +
              Number(record?.CountSuccessAudioCalls)}{" "}
            <LinkOutlined />
          </a>
        ),
      },
    ];

  const downloadDropdownType = [
    {
      value: "PDF",
      label: "PDF",
    },
    {
      value: "CSV",
      label: "CSV",
    },
  ];

  const downloadPDF = () => {
    let downloadArray = [];

    for (let list of language.rawData) {
      const avg = list.CallQualityRatingStar / list.CountRatingStarCalls;
      const avgRating = isNaN(avg) ? "-" : avg.toFixed(2);
      let newList = {
        "Language Name": list?.TargetLanguage,
        "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
        "Total Minutes": Formatter.numberWithCommas(list?.ServiceMinutes),
        "Serviced Audio Calls": Formatter.numberWithCommas(
          list?.CountSuccessAudioCalls
        ),
        "Serviced Video Calls": Formatter.numberWithCommas(
          list?.CountSuccessVideoCalls
        ),
        "Avg. Wait Time": `${Formatter.numberWithCommas(
          Math.round(list?.WaitingSeconds / list?.TotalCalls)
        )} seconds`,
        "Avg. Rating": avgRating,
      };
      downloadArray.push(newList);
    }

    const headerList = [
      "Language Name",
      "Total Calls",
      "Total Minutes",
      "Serviced Audio Calls",
      "Serviced Video Calls",
      "Avg. Wait Time",
      "Avg. Rating",
    ];
    const bodyList = downloadArray.map((item) =>
      Object.values(item).map((value) => String(value))
    );
    const doc = new jsPDF("landscape", "px", "a4");
    doc.setFontSize(18);
    doc.text("Language Report", 30, 20);
    doc.setFontSize(12);
    doc?.autoTable({
      head: [headerList.map((header) => [header])],
      body: bodyList,
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: [33, 33, 33],
      },
      startY: 50,
    });
    doc.save(new Date().toISOString().split("T")[0]);
  };

  const downloadFile = () => {
    if (downloadType === "PDF") {
      downloadPDF();
    } else if (downloadType === "CSV") {
      downloadCSVFunction();
    }
  };

  const downloadCSVFunction = () => {
    let downloadArray = [];

    for (let list of language.rawData) {
      const avg = list.CallQualityRatingStar / list.CountRatingStarCalls;
      const avgRating = isNaN(avg) ? "-" : avg.toFixed(2);
      let newList = {
        "Language Name": list?.TargetLanguage,
        "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
        "Total Minutes": Formatter.numberWithCommas(list?.ServiceMinutes),
        "Serviced Audio Calls": Formatter.numberWithCommas(
          list?.CountSuccessAudioCalls
        ),
        "Serviced Video Calls": Formatter.numberWithCommas(
          list?.CountSuccessVideoCalls
        ),
        "Avg. Wait Time": `${Formatter.numberWithCommas(
          Math.round(list?.WaitingSeconds / list?.TotalCalls)
        )} seconds`,
        "Avg. Rating": avgRating != "-" ? avgRating + " stars" : avgRating,
      };
      downloadArray.push(newList);
    }
    exportAsCSV(
      downloadArray,
      "Language Report",
      //new Date().toISOString().split("T")[0],
      [
        "Language Name",
        "Total Calls",
        "Total Minutes",
        "Serviced Audio Calls",
        "Serviced Video Calls",
        "Avg. Wait Time",
        "Avg. Rating",
      ],
      "Language Report"
    );
  };

  // Event handler for navigating to transaction details by language
  const handleNavigationClick = (record) => {
    let targetLanguage = record.TargetLanguage;
    dispatch(
      setLanguageType(
        targetLanguage.match(/spanish/i)
          ? "sp"
          : targetLanguage.match(/ASL/i)
          ? "ASL"
          : "LOTS"
      )
    );
    navigate(
      `/pages/transactions?dataIdx0=languageType&status${0}=${targetLanguage}&dataIdx1=Status&status${1}=Serviced`
    );
  };

  // Format hour-nexthour string
  const convertHourRange = (timeString) => {

    const hourPart = parseInt(timeString);
    const amOrPm = timeString?.slice(-1);

    let hour24Format = hourPart;
    if (amOrPm === 'p' && hourPart !== 12) {
        hour24Format += 12;
    }

    if (amOrPm === 'a' && hourPart === 12) {
        hour24Format = 0;
    }

    const nextHour = (hour24Format + 1) % 24;
    return `${hour24Format}-${nextHour}`;
  }

  // Event handler for navigating to transaction details by hour
  const handleNavigationHourClick = (record) => {
    let targetLanguage = record.TargetLanguage;
    dispatch(
      setLanguageType(
        targetLanguage.match(/spanish/i)
          ? "sp"
          : targetLanguage.match(/ASL/i)
          ? "ASL"
          : "LOTS"
      )
    );
    navigate(
      `/pages/transactions?dataIdx0=languageType&status${0}=${targetLanguage}&dataIdx1=Status&status${1}=Serviced&dataIdx${2}=${"ExtractedTime"}&status${2}=${convertHourRange(selectedHour.hour)}`
    );
  };

  // JSX to render the LanguageHeatmapReport component
  return (
    <Row>
      <Col className="language-col" flex={"auto"}>
        <Row wrap={false} gutter={20}>
          <Col className="volume-card" flex={"100%"}>
            <Spin spinning={loading}>
              <HeatMap setSelectedHour={setSelectedHour} />
            </Spin>
          </Col>
        </Row>
        <br />
        <Typography.Title level={4}>
          Serviced Call Details by Hour{" "}
          {selectedHour.hour && (
            <>
              :&nbsp; ({selectedHour.language || "Total"} {"->"}{" "}
              {selectedHour.hour || ""})
            </>
          )}
        </Typography.Title>
        <Row>
          <Col flex={"auto"}>
            <Table
              className="voyce-custom-table"
              style={{ width: "100%", backgroundColor: colorBgLayout }}
              dataSource={selectedHour.data || []}
              columns={hourColumns}
              size="small"
              pagination={false}
              sticky
              locale={{
                emptyText: (
                  <Row justify={"start"} align={"middle"}>
                    <Col>
                      <Typography.Text strong italic>
                        "Please select a cell from the HeatMap above to view
                        details by hour."
                      </Typography.Text>
                    </Col>
                  </Row>
                ),
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

// Export the LanguageHeatmapReport component
export default LanguageHeatmapReport;
