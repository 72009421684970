import { Axios } from "axios";
import constants from "../constants";

class CompanyService {
    /**
     * Company Services
     * @param {Axios} request
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    //call api to get active company for the user
    async allActive() {
        try {
            // const companyList = { selectedCompanies: data };

            let response = await this.request.post("/company/active");
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    //call api to fetch timezone based on company
    async getTimezone(companyId) {
        try {
            let response = await this.request.get(`/company/${companyId}/timezone`);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

        //call api to handle get Interpreter by company
        async getTerpByCompany(filter, hideLoader) {
            try {
                let response = await this.request.post(
                    `/company/interpreter`,
                    {
                        ...filter,
                    },
                    { filter: true, hideLoader }
                );
                return response.data;
            } catch (err) {
                console.log(err);
                // throw err;
                return {}
            }
        }
}

export default CompanyService;
