// Import necessary components and libraries
import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect } from "react";
import dots from '../../images/dlogo1.png';
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { forgotPassword } from "../../store/actions/profile.action";
import { useDispatch } from "react-redux";

// Component for Forgot Password page
const ForgotPassword = () => {
    const dispatch = useDispatch(); // Accessing Redux dispatch function
    const navigate = useNavigate(); // Accessing the navigation function from React Router

    // Function to handle form submission for password reset
    const handleFormSubmit = async (values) => {
        let email = values.email;
        let flag = dispatch(forgotPassword({ ...values, email }));
        if (flag) {
            navigate("/auth/login"); // Navigate to login page upon successful password reset request
        }
    }

    return (
        // Container for the forgot password page
        <div className="login-container">
            {/* Row for the login content */}
            <Row className="login-row" justify={"center"} align={"middle"}>
                <Col style={{ width: 550, padding: 20 }}>
                    {/* Card for the form */}
                    <Card bordered={false}>
                        {/* Row for the title */}
                        <Row gutter={20} className="title-row" justify={"center"} align={"middle"}>
                            <img src={dots} height={24} />
                            <Col>VIP Customer</Col>
                        </Row>
                        <br />
                        {/* Form for resetting the password */}
                        <Form onFinish={handleFormSubmit} style={{ width: '100%' }}>
                            {/* Back to Sign In link */}
                            <p onClick={() => navigate("/auth/login")} style={{ position: 'absolute', zIndex: 1, top: 20, cursor: 'pointer' }}>
                                <ArrowLeftOutlined /> Back to Sign In
                            </p>
                            {/* Row to align form elements */}
                            <Row align={"stretch"}>
                                <Col flex={"auto"}>
                                    {/* Input for email */}
                                    <Form.Item
                                        name="email"
                                        rules={[{ required: true }]}
                                    >
                                        <Input placeholder="E-mail" />
                                    </Form.Item>
                                    {/* Information about password reset */}
                                    <p style={{ color: "grey", marginTop: 0 }}>
                                        Enter your email and we'll send you a link to reset your password.
                                    </p>
                                    {/* Button to submit password reset request */}
                                    <Row justify={"center"}>
                                        <Form.Item>
                                            <Button style={{ width: 180 }} shape="round" type="primary" htmlType="submit">Submit</Button>
                                        </Form.Item>
                                    </Row>
                                </Col>
                            </Row>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default ForgotPassword;
