import { Axios } from "axios";
import constants from "../constants";

class TransactionService {
    /**
     * Company Services
     * @param {Axios} request
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    //call api to fetch all completed transactions for a specifc timeframe
    async getTransactions(filter, offset, limit, sorter, hideLoader) {

        try {
            let response = await this.request.post(
                `/detail-transactions/language/by-date?offset=${offset}&limit=${limit}`,
                {
                    ...filter,
                    sorter,
                },
                { filter: true, hideLoader }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            // throw err;
            return {}
        }
    }

    //call api to fetch active calls for the specific timeframe
    async getActiveTransactions(filter, sorter, hideLoader) {

        try {
            let response = await this.request.post(
                `/detail-transactions/activity-monitor`,
                {
                    ...filter,
                    sorter,
                },
                { filter: true, hideLoader }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            // throw err;
            return {}
        }
    }

    //call this api to get total number of transactions in a specific timeframe to help in pagination purpose
    async getTransactionsTotal(filter, hideLoader) {

        try {
            let response = await this.request.post(
                `/detail-transactions/language/by-date/total`,
                {
                    ...filter
                },
                { filter: true, hideLoader }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async getFilterItems(filter, hideLoader) {

        try {
            let response = await this.request.post(
                `/detail-transactions/filter-items`,
                {
                    ...filter
                },
                { filter: true, hideLoader }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    //call api to handle CSV download for all completed transaction in a specified timeframe
    async downloadCSV(filter, sorter, header) {
        try {
            let response = await this.request.post(
                `/detail-transactions/download/CSV`,
                {
                    ...filter,
                    sorter,
                    header,
                },
                { filter: true, observe: "response", responseType: "blob" }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
    async downloadEXCEL(filter, sorter, header) {
        try {
            let response = await this.request.post(
                `/detail-transactions/download/EXCEL`,
                {
                    ...filter,
                    sorter,
                    header,
                },
                { filter: true, observe: "response", responseType: "blob" }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    //call api to handle PDF download for all completed transaction in a specified timeframe
    async downloadPDF(filter, sorter, header) {
        try {
            let response = await this.request.post(
                `/detail-transactions/download/PDF`,
                {
                    ...filter,
                    sorter,
                    header,
                },
                { filter: true, observe: "response", responseType: "blob" }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
    
    //call api to handle CSV download for all active transaction in a specified timeframe
    async downloadActivityCSV(filter, sorter, header) {
        try {
            let response = await this.request.post(
                `/detail-transactions/activity-monitor/download/EXCEL`,
                {
                    ...filter,
                    sorter,
                    header,
                },
                { filter: true, observe: "response", responseType: "blob" }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    //call api to handle PDF download for all active transaction in a specified timeframe
    async downloadActivityPDF(filter, sorter, header) {
        try {
            let response = await this.request.post(
                `/detail-transactions/activity-monitor/download/PDF`,
                {
                    ...filter,
                    sorter,
                    header,
                },
                { filter: true, observe: "response", responseType: "blob" }
            );
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
    
}

export default TransactionService;
