import { Button, DatePicker, Select } from "antd";
import moment from "moment";
import { useDispatch } from "react-redux";
import { setFilterDate, setDateRange, setPrevTimeRange, resetPrevTimeRange  } from "../../store/actions/filter.action";
import { useState, useEffect, useCallback } from "react";
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import "./filter.date.scss";

const { RangePicker } = DatePicker;

const DropDown = (props) => {
    return (
        <Select
            style={{width: 120}}
            value={props.selectedValue}
            defaultValue={props.defaultValue}
            className="select-div"
            onSelect={props.handleTypeSelect}
            options={props.dropdownOptions}
        />
    );
};

const FilterDate = (props) => {

    const dispatch = useDispatch();
    const [customDateDialog, setCustomDateDialog] = useState(false);
    const [selectedTimeRange, setSelectedTimeRange] = useState('Today');
    const location = useLocation()
    const prevRange = useSelector(state => state.filter.prevSelectedTimeRange);

    const ONLY_SHOW_LIVE_DATA = [
        'activity-monitor',
    ]

    const isLiveDataOnly = useCallback(() => {
      for (let i = 0; i < ONLY_SHOW_LIVE_DATA.length; i++) {
        if (location.pathname.includes(ONLY_SHOW_LIVE_DATA[i])) {
          return true
        }
      }
    }, [location.pathname])
    
    useEffect(() => {
      if (isLiveDataOnly()) {
        setSelectedTimeRange('Today');
        dispatch(setFilterDate({
            maxDate: moment().endOf('day').toDate(),
            minDate: moment().startOf('day').toDate(),
            fetchInterval: 12000,
            currentDateRangeLabel: 'Today',
        }));
        // Set previous time range only when navigating to 'activity-monitor'
        dispatch(setPrevTimeRange(selectedTimeRange));
    } else {
        // Ensure that previous time range is used only when moving away from 'activity-monitor'
        if (prevRange) {
            handleTimeRage(prevRange);
            dispatch(resetPrevTimeRange());
        }
    }
        }, [location.pathname]) // Simplify the dependency to just the path
    
        let timeRangeDropdownType = [
          {
            value: 'Today',
            label: 'Today'
          }
        ]
    
        if (!isLiveDataOnly()) {
          timeRangeDropdownType = timeRangeDropdownType.concat([
            {
              value: 'Yesterday',
              label: 'Yesterday'
            },
            {
              value: 'This Week',
              label: 'This Week'
            },
            {
              value: 'Last Week',
              label: 'Last Week'
            },
            {
              value: 'This Month',
              label: 'This Month'
            },
            {
              value: 'Last 30 Days',
              label: 'Last 30 Days'
            },
            {
              value: 'Last Month',
              label: 'Last Month'
            },
            {
              value: 'This Year',
              label: 'This Year'
            },
            {
              value: 'Last Year',
              label: 'Last Year'
            },
            {
              value: 'Custom Date',
              label: 'Custom Date'
            }
          ])
        }


    //Function to dispatch setFilterDate to fetch data based on the time range selected in dropdown
    const handleTimeRage = (value) => {
        setSelectedTimeRange(value);
        if (value === "Custom Date") {
            setCustomDateDialog(true);
        } else if (value === "Today") {
            dispatch(setFilterDate({
                maxDate: moment().endOf("day").toDate(),
                minDate: moment().startOf("day").toDate(),
                fetchInterval: 12000
            }))
            dispatch(setDateRange("Today"))
        } else if (value === "Yesterday") {
            dispatch(setFilterDate({
                maxDate: moment().subtract(1, "day").endOf("day").toDate(),
                minDate: moment().subtract(1, "day").startOf("day").toDate(),
                fetchInterval: 30000 // seconds
            }))
            dispatch(setDateRange("Yesterday"))

        } else if (value === "This Week") {
            dispatch(setFilterDate({
                maxDate: moment().endOf("isoWeek").toDate(),
                minDate: moment().startOf("isoWeek").toDate(),
                fetchInterval: 30000 // seconds
            }))
            dispatch(setDateRange("This Week"))

        } else if (value === "Last Week") {
            dispatch(setFilterDate({
                maxDate: moment().subtract(1, "weeks").endOf("isoWeek").toDate(),
                minDate: moment().subtract(1, "weeks").startOf("isoWeek").toDate(),
                fetchInterval: 1 * 60 * 1000 // minutes
            }))
            dispatch(setDateRange("Last Week"))

        } else if (value === "This Month") {
            dispatch(setFilterDate({
                maxDate: moment().endOf("month").toDate(),
                minDate: moment().startOf("month").toDate(),
                fetchInterval: 30000 // seconds
            }))
            dispatch(setDateRange("This Month"))

        } else if (value === "Last 30 Days") {
            dispatch(setFilterDate({
                maxDate: moment().endOf("day").toDate(),
                minDate: moment().subtract(30, "days").startOf("day").toDate(),
                fetchInterval: 30000 // seconds
            }))
            dispatch(setDateRange("Last 30 Days"))

        } else if (value === "Last Month") {
            dispatch(setFilterDate({
                maxDate: moment().subtract(1, "months").endOf("month").toDate(),
                minDate: moment().subtract(1, "months").startOf("month").toDate(),
                fetchInterval: 2 * 60 * 1000 // minutes
            }))
            dispatch(setDateRange("Last Month"))

        } else if (value === "This Year") {
            dispatch(setFilterDate({
                maxDate: moment().endOf("year").toDate(),
                minDate: moment().startOf("year").toDate(),
                fetchInterval: 30000 // seconds
            }))
            dispatch(setDateRange("This Year"))

        } else if (value === "Last Year") {
            dispatch(setFilterDate({
                maxDate: moment().subtract(1, "years").endOf("year").toDate(),
                minDate: moment().subtract(1, "years").startOf("year").toDate(),
                fetchInterval: 10 * 60 * 1000 // minutes
            }))
            dispatch(setDateRange("Last Year"))

        }
    };

    //function to call when reset button is hit to reset the data back to default 
    const handleReset = () => {
        setCustomDateDialog(false);
        dispatch(setFilterDate({
            maxDate: moment().endOf("day").toDate(),
            minDate: moment().startOf("day").toDate(),
            fetchInterval: 12000,
        }));
        dispatch(setDateRange("Today"))
    };

    //display the date filter 
    return !customDateDialog ? (
            <DropDown
                defaultValue={'Today'}
                selectedValue={selectedTimeRange}
                dropdownOptions={timeRangeDropdownType}
                handleTypeSelect={(timeRange) => handleTimeRage(timeRange)}
            />
        ) : (
        <div className="range-picker-div">
            <RangePicker
                onChange={(x) => {
                    dispatch(setDateRange("Custom Date"))
                    if (x[1].isSame(x[0])) {
                        dispatch(setFilterDate({
                            maxDate: x[1].add(1, "days").$d,
                            minDate: x[0].$d,
                            fetchInterval: 30000 // seconds
                        }))
                        return
                    }
                    dispatch(setFilterDate({
                        maxDate: x[1].$d,
                        minDate: x[0].$d,
                        fetchInterval: 30000 // seconds
                    }))
                }}
            />
            &nbsp;
            <Button
                className="done-btn-div"
                type="primary"
                onClick={handleReset}
            >
            Reset
            </Button>
        </div>
        )
}

export default FilterDate;