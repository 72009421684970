import ActionType from '../action.types';

/*
    Reducer function responsible for managing language-related state changes based on different actions
*/

export default function language(state = {
        // Initial state structure for language-related data
        volume: [], // Volume data
        avgTime: {legends: [], seriesData: []}, // Average time data
        distribution: {legends: [], seriesData: []}, // Distribution data
        interpretationType: {
            audio: {
                legends: [],
                seriesData: []
            },
            video: {
                legends: [],
                seriesData: []
            }
        },
        audioType: {
            audio: {
                legends: [],
                seriesData: []
            },
        },
        videoType: {
            video: {
                legends: [],
                seriesData: []
            },
        },
        rawData: [] // Raw data
    }, action) {
    switch (action.type) {
        // Set language analytics data
        case ActionType.SET_LANGUAGE_ANALYTICS:
            return {
                ...state, 
                volume: action.volume, 
                rawData: action.rawData, 
                distribution: action.distribution,
                avgTime: action.avgTime,
                interpretationType: action.interpretationType,
                audioType: action.audioType,
                videoType: action.videoType
            };
        
        // Reset state on logout
        case ActionType.LOGOUT: 
            return {
                // Resetting language-related data to initial state on logout
                volume: [],
                avgTime: {legends: [], seriesData: []},
                distribution: {legends: [], seriesData: []},
                interpretationType: {
                    audio: {
                        legends: [],
                        seriesData: []
                    },
                    video: {
                        legends: [],
                        seriesData: []
                    }
                },
                audioType: {
                    audio: {
                        legends: [],
                        seriesData: []
                    },
                },
                videoType: {
                    video: {
                        legends: [],
                        seriesData: []
                    },
                },
                rawData: []
            };
        
        default:
            return state;
    }
}
