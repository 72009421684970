import { Route, Routes, Navigate } from "react-router-dom";
import Page404 from "./pages/404";
import { Auth, Layout, ProtectedRoutes } from "./pages";
import Login from "./pages/auth/login";
import Signup from "./pages/auth/signup";
import Dashboard from "./pages/dashboard";
import ForgotPassword from "./pages/auth/forgot.password";
import ResetPassword from "./pages/auth/reset.password";
import Transactions from "./pages/transactions";
import ActivityMonitor from "./pages/transactions/activityMonitor"
import RoleHierarchy from "./pages/role";
import LanguageReport from "./pages/reports/language.report/language.report";
import AudioVideoReport from "./pages/reports/audioVideo.report/audioVideo.report";
import DeviceUsage from "./pages/reports/deviceUsage.report/deviceUsage.report"
import Invoices from "./pages/invoices";
import { useSelector } from "react-redux";
import DeviceSiteUsage from "./pages/reports/deviceUsage.report/deviceUsage.site.report";
import LanguageHeatmapReport from "./pages/reports/language.heatmap.report/language.heatmap.report";
import Terp from "./pages/terp";
import DeviceSiteDeptUsage from "./pages/reports/deviceUsage.report/deviceUsage.dept.site.report";
import SSOlogin from "./pages/auth/sso.login";
import DeviceRegionSiteUsage from "./pages/reports/deviceUsage.report/deviceUsage.reg.site.report";

// Constant specifying a particular column for a site
const SITE_COLUMN = ["1598"]
export const DEPARTMENT_SITE_COLUMN = ["1899"]
export const REGION_SITE_COLUMN = ["9001"]

const Router = () => {
    // Fetching selected company from Redux state
    const selectedCompany = useSelector(
      (state) => state?.profile?.user?.permissions?.company?.toString()
    );
    
    return (
        <>
          <Routes>
            {/* Authentication routes */}
            <Route path="/auth" element={<Auth />}>
              <Route path={"signup"} element={<Signup />}></Route>
              <Route path={"login"} element={<Login />}></Route>
              <Route path={"forgot-password"} element={<ForgotPassword />} ></Route>
              <Route path={"reset-password/:token"} element={<ResetPassword />} ></Route>
              <Route path={"sso/:token"} element={<SSOlogin />} ></Route>
              <Route index path="*" element={<Navigate to="/auth/login" />} ></Route>
            </Route>

            {/* Protected routes */}
            <Route path="/pages" element={<ProtectedRoutes />}>
              {/* Layout and protected pages */}
              <Route path="/pages" element={<Layout />}>
                {/* Dashboard and related routes */}
                <Route index element={<Dashboard />} />
                <Route path={"transactions"} element={<Transactions />} />
                <Route path={"activity-monitor"} element={<ActivityMonitor />} />
                
                {/* Reports section */}
                <Route path="reports" >
                  <Route index path={"language-report"} element={<LanguageReport/>} />
                  <Route index path={"language-heatmap-report"} element={<LanguageHeatmapReport/>} />
                  <Route index path={"audio-report"} element={<AudioVideoReport/>} />
                  {/* Differentiating device usage reports based on selected company */}
                  <Route index path={"device-usage"} element={
                    SITE_COLUMN.includes(selectedCompany) ? <DeviceSiteUsage/> :
                    DEPARTMENT_SITE_COLUMN.includes(selectedCompany) ? <DeviceSiteDeptUsage/> :
                    REGION_SITE_COLUMN.includes(selectedCompany) ? <DeviceRegionSiteUsage/> :
                    <DeviceUsage/>
                  } />
                </Route>
                
                {/* Role hierarchy and invoices and terp */}
                <Route path={"role-hierarchy"} element={<RoleHierarchy />} />
                <Route path={"invoices"} element={<Invoices/>}/>
                <Route path={"terp"} element={<Terp />} />
              </Route>
              
            </Route>

            {/* Default redirection and 404 handling */}
            <Route index path="/" element={<Navigate to="/auth/login" />} />
            <Route path="*" element={<Page404 />} />
          </Routes>
        </>
    );
};

export default Router;
