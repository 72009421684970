import { message } from "antd";
import ActionType from "../action.types";

/*
    Reducer function managing different profile-related actions in the store
*/

// Initial state for the reducer
export default function userManagement(
    state = {
        isLoading: false,
        token: null,
        sessionId: null,
        userList: [],
        theme: "light",
        message: "",
    },
    action
) {
    switch (action.type) {
        // Handles action to get the user list
        case ActionType.GET_USER_LIST:
            return {
                ...state,
                userList: action.userList,
            };
        
        // Handles action to update user information
        case ActionType.UPDATE_USER:
            return {
                ...state,
                userList: action.userList,
            };
        
        // Handles action for successful user deletion
        case ActionType.USER_DELETE_SUCCESS:
            return {
                ...state,
                message: action.message,
                isLoading: action.isLoading,
            };
        
        // Handles action for user deletion error
        case ActionType.USER_DELETE_ERROR:
            return {
                ...state,
                message: action.message,
                error: action.hasError,
                isLoading: action.isLoading,
            };
        
        // Default case returns the current state
        default:
            return state;
    }
}
