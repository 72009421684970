import ActionType from '../action.types';
import Service from '../../services';
import { message } from 'antd';
import { store } from '../..';
import moment from 'moment';
import { DEPARTMENT_SITE_COLUMN } from '../../route';
import _ from 'lodash';

//action to set widget data
export function setWidgets(res, isToday) {

    const aggregated = {};
    aggregated.avgWaitTime = (res[0].WaitingSeconds && res[0].TotalCalls) ? res[0].WaitingSeconds / res[0].TotalCalls : 0;
    aggregated.successVideoCalls = res[0].CountSuccessVideoCalls ? res[0].CountSuccessVideoCalls : 0;
    aggregated.successAudioCalls = res[0].CountSuccessAudioCalls ? res[0].CountSuccessAudioCalls : 0;
    aggregated.totalMins = res[0].ServiceMinutes ? res[0].ServiceMinutes : 0;
    aggregated.totalCalls = res[0].TotalCalls ? (res[0].TotalCalls - res[0]?.CountPendingAudioCalls - res[0]?.CountInServiceAudioCalls - res[0]?.CountNewAudioCalls - res[0]?.CountPendingVideoCalls - res[0]?.CountInServiceVideoCalls - res[0]?.CountNewVideoCalls) : 0;
    aggregated.totalCallServiced = (res[0].CountSuccessVideoCalls || res[0].CountSuccessAudioCalls) ? res[0].CountSuccessVideoCalls + res[0].CountSuccessAudioCalls : 0;
    aggregated.totalCallCancelled = (res[0].CountFailedAudioCalls + res[0].CountFailedVideoCalls);
    aggregated.avgWaitTime = (res[0].WaitingSeconds && res[0].TotalCalls) ? res[0].WaitingSeconds / res[0].TotalCalls : 0;
    aggregated.avgCallLength = (res[0].ServiceMinutes && res[0].TotalCalls) ? res[0].ServiceMinutes / res[0].TotalCalls : 0;
    aggregated.toalPrice = res[0].TotalPrice ? res[0].TotalPrice : 0;
    aggregated.avgRating = res[0].CallQualityRatingStar / res[0].CountRatingStarCalls;
    aggregated.totalBackupCals = res[0].CountBackupCalls || 0;
    aggregated.avgBackupWaitTime = (res[0].BackupWaitingSeconds || 0) /  aggregated.totalBackupCals;
    aggregated.avgWaitTimeVideoSp = res[0]?.AvgSpanishWaitingSeconds || 0;
    aggregated.avgWaitTimeVideoLots = res[0]?.AvgLotsWaitingSeconds || 0;
        
    aggregated.totalInServiceCalls = isToday ? (res[0]?.CountInServiceAudioCalls || 0) + (res[0]?.CountInServiceVideoCalls || 0) : 0;
    aggregated.totalNewCalls = isToday ? (res[0]?.CountNewAudioCalls || 0) + (res[0]?.CountNewVideoCalls || 0) : 0;
    aggregated.estimatedCost = (res[0]?.EstimatedCost || 0).toFixed(2)

    return {
        type: ActionType.SET_WIDGETS,
        widgets: aggregated
    };
}

//action to set top 5 languages data
export function setTop5Languages(res) {

    const legends = [];
    const seriesData = [];

    res.forEach((element) => {
        legends.push(element.TargetLanguage)
        seriesData.push(element.ServiceMinutes)
    });

    return {
        type: ActionType.SET_TOP5,
        top5Languages: { 
            legends: legends,
            seriesData: seriesData
        }
    }
}


//action to set total active devices calculcated from total transactions in the specified timeframe
export function setTotalActiveDevices(result) {
    const state = store.getState();
    const selectedCompany = state?.profile?.user?.permissions?.company?.toString()
    if (DEPARTMENT_SITE_COLUMN.includes(selectedCompany)) {
        result = _.uniqBy(result?.map((e) => e?.Departments?.map((j) => j?.Devices)?.flat())?.flat()?.map((e) => ({
            ...e,
            deviceType: 'Voyce Owned',
            CompanyId: Number(selectedCompany)
        }))|| [], 'IOSSerialNumber');

        console.log(result, "hasnen")
    }
    return {
        type: ActionType.SET_TOTAL_ACTIVE_CARTS,
        totalActiveCarts: result // Assuming the API response is an array
    };
}

// thunk function for star rating
export function setStarRating(res) {
    return {
        type: ActionType.SET_STAR_RATING,
        starRating: res
    }
}

/**
 * action to get widget data.
 * @returns 
 */
export function getWidgetsData(hideLoader) {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            const isToday = moment(filter.start).isSame(moment().startOf('day'), 'day') && moment(filter.end).isSame(moment().endOf('day'), 'day');
            let data = await Service.Analytics().getTotalAggData(filter, hideLoader);
            dispatch(setWidgets(data, isToday));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}


/**
 * action to get top 5 language data.
 * @returns 
 */
export function getTop5Languages(hideLoader) {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getTopLanguages(filter, hideLoader);
            dispatch(setTop5Languages(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}



//action to getTotalActiveDevice calculated from total transactions in that timeframe
export function getTotalActiveDevices(body = {}) {
    return async (dispatch, getState) => {
      try {
        const { date } = getState().filter;
        if (!date || !date.minDate || !date.maxDate) {
          throw new Error("Both start and end parameters are required.");
        }
  
        const state = store.getState();
        const userCompany = state?.profile?.user?.permissions?.company;
        const filter = {
          start: state.filter?.date?.minDate,
          end: state.filter?.date?.maxDate,
        };
  
        if (!filter.start || !filter.end) {
          throw new Error("Invalid start or end date.");
        }
  
        // Pass the RequestCompanyId as part of the body
        const requestData = { ...body, RequestCompanyId: userCompany };
        const data = await Service.Analytics().getTotalActiveDevices(filter, requestData);
        dispatch(setTotalActiveDevices(data));
      } catch (err) {
        console.log(err);
        let msg = err?.response?.data?.error?.message || err.message;
        msg && message.error(msg);
      }
    };
}
  
  


//action to fetch call by hour data for call by hour for heatmap chart
export function getCallsByHoursLanguage() {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
            }
            let data = await Service.Analytics().getCallsByHoursLang(filter);
            return data;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

// action to get star rating with date filter
export function getStarRating() {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                
            }
            let data = await Service.Analytics().getStarRatingList(filter);
            dispatch(setStarRating(data));
            return data;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    }
}