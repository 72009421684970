import { Col, FloatButton, Row, theme, Tooltip, Progress, Card } from "antd";
import "./dashboard.scss";
import { useEffect, useState, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getWidgetsData,
  getStarRating,
} from "../../store/actions/analytics.action";
import { MemoizedTopLanguagesChart } from "./top.10.chart";
import Formatter from "../../utils/Formatter";
import { useNavigate } from "react-router-dom";
import Service from "../../services";
import { setLanguageType } from "../../store/actions/filter.action";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import Square from "./draggable/box.droppable";
import SquareDrag from "./draggable/box.draggable";
import { CloseOutlined, EditFilled } from "@ant-design/icons";
import useIntervalAsync from "../../components/hooks/useInterval";
import { getTotalActiveDevices } from "../../store/actions/analytics.action"; // Import the action that fetches data from the API
import blueprintMap from "../../blueprint_map.json";
import { MemoizedHeatMap } from "./heatmap.chart";
import StarRatingBarGroup from "./rating/starRatingBarGroup";
import { DEPARTMENT_SITE_COLUMN } from "../../route";

// Define IDs for static widgets
const STATIC_WIDGETS_ID = {
  LARGE: 12,
  LEFT_SMALL: 18,
  RIGHT_SMALL: 17,
};

// Define the Dashboard component
const Dashboard = () => {
  // Extract color tokens from the theme
  const {
    token: { colorText },
  } = theme.useToken();
  // Extract necessary Redux state variables
  const state = useSelector((state) => state);
  const widget = state.analytics?.widgets;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [edit, setEdit] = useState(false);
  const [drop, setDrop] = useState();
  const [currentDrag, setDrag] = useState();
  const currentTheme = useSelector((state) => state.profile?.theme);
  const [kandjiDeviceInfo, setKandiDeviceInfo] = useState(null);
  const userCompany = useSelector(
    (state) => state?.profile?.user?.permissions?.company
  );
  const activeCompanies = useSelector((state) => state.company?.active || []);
  const starRating = state.analytics?.starRating;
  // Function to fetch widget data asynchronously
  const fetchWidgetsCB = useCallback(async () => {
    dispatch(getStarRating(true)); // Fetch star rating from redux
    dispatch(getWidgetsData(true))
      .then(() => setLoading(false)) // Set loading to false on successful data fetch
      .catch(() => setLoading(false)); // Set loading to false if fetching encounters an error
  }, []);

  const fetchWidgets = useIntervalAsync(
    fetchWidgetsCB,
    state?.filter?.fetchInterval
  );

  // Extract device usage data from Redux state
  const deviceUsageData = useSelector(
    (state) => state.analytics?.totalActiveCarts
  );

  // Initialize counters for different types of devices
  let customerOwnedCount = 0;
  let voyceOwnedCount = 0;

  // Check for Site Column Format Data
  let usageData = JSON.parse(JSON.stringify(deviceUsageData || []));
  usageData =
    usageData.length > 0 && usageData[0].SerialNumbers
      ? usageData
          .map((item) =>
            item.SerialNumbers.map((serial) => ({
              ...serial,
              CompanyId: userCompany,
            }))
          )
          .flat()
      : usageData;

  // Filter the data based on the CompanyId
  const filteredDeviceUsageData = usageData
    ?.filter((item) => item.CompanyId === userCompany)
    .map((item) => {
      // Find the corresponding device info from kandjiDeviceInfo based on serial number
      const deviceInfo =
        DEPARTMENT_SITE_COLUMN.includes(userCompany?.toString()) ? ({
          ...item,
          deviceName: item.DeviceName,
          deviceType: "Voyce Owned"
        }): ((kandjiDeviceInfo &&
        kandjiDeviceInfo?.find(
          (device) => device.serialNumber === item.IOSSerialNumber
        )));

      // If deviceInfo is found, update the item with device name and type
      if (deviceInfo) {
        // Increment the voyceOwnedCount for each "Voyce Owned" device
        voyceOwnedCount++;
        return {
          ...item,
          deviceName: deviceInfo?.deviceName,
          macAddress: deviceInfo?.mac_address,
          deviceType: "Voyce Owned",
        };
      } else {
        // Increment the customerOwnedCount for each "Customer Owned" device
        customerOwnedCount++;
        // If deviceInfo is not found, set the device name as "Unknown" and device type as "External"
        return {
          ...item,
          deviceName: "Unknown",
          deviceType: "Customer Owned",
        };
      }
    });

  // Function to fetch Kandji device information
  const fetchDeviceInfo = async () => {
    try {
      let code = activeCompanies.find(
        (companyFullObject) =>
          companyFullObject?._id?.toString() == userCompany?.toString()
      )?.CompanyCode;

      if (code) {
        // Check if the companyCode is in the blueprint map
        const matchingBlueprint = blueprintMap?.find(
          (blueprint) => blueprint.companyCode === code
        );

        if (matchingBlueprint) {
          setKandiDeviceInfo([]); // Set to [] if a match is found in the blueprint map
        } else {
          // Fetch device information if no match is found
          const response = await Service.Device().getDeviceInfo({
            blueprintName: activeCompanies?.find(
              (companyFullObject) =>
                companyFullObject?._id?.toString() == userCompany?.toString()
            )?.CompanyCode,
          });
          setKandiDeviceInfo(response); // Update the state with the fetched device information
        }
      } else {
        setKandiDeviceInfo([]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Fetch initial data and company-specific information on component mount
  useEffect(() => {
    setLoading(true);
    fetchWidgets();
    if (userCompany) {
      fetchDeviceInfo();
      dispatch(
        getTotalActiveDevices({
          RequestCompanyId: userCompany,
        })
      );
    }
  }, [
    state.filter?.languageType,
    state.filter?.date,
    state.company?.client,
    userCompany,
    activeCompanies,
    state.company?.region,
    state.company?.site,
  ]);

  // Define the class names based on the theme
  const themeClass =
    currentTheme === "dark" ? "dark-theme-class" : "light-theme-class";

  const handleDeviceClick = (widgetTitle) => {
    const queryParams = [];

    if (widgetTitle === "COD") {
      queryParams.push({ dataIdx: "deviceType", status: "Customer" });
    } else if (widgetTitle === "VOD") {
      queryParams.push({ dataIdx: "deviceType", status: "Voyce" });
    }

    // Construct the query string from the queryParams array
    const queryString = queryParams
      .map(
        ({ dataIdx, status }, index) =>
          `dataIdx${index}=${dataIdx}&status${index}=${status}`
      )
      .join("&");

    // Redirect to the Device Usage page with the query parameters
    navigate(`/pages/reports/device-usage?${queryString}`);
  };

  //define widget details
  const [widgets, setWidgets] = useState([
    {
      squareId: 1,
      widget: {
        title: "Minutes Used",
        value: (wd) => Formatter.numberWithCommas(wd?.totalMins),
      },
      size: "medium",
      className: "medium-card",
    },
    {
      squareId: 2,
      widget: {
        title: "Avg. Wait Secs Video",
        value: (wd) => Math.round(parseFloat(wd?.avgWaitTime)),
        hidden: (s) => s?.filter?.languageType === "-1",
        queryParams: [{ dataIdx: "VideoOption", status: "Video" }],
      },
      size: "medium",
      className: "medium-card",
    },
    {
      squareId: 3,
      widget: {
        title: "Avg Wait Secs Video Spanish",
        value: (wd) => Math.round(parseFloat(wd?.avgWaitTimeVideoSp)),
        hidden: (s) => s?.filter?.languageType !== "-1",
        queryParams: [{ dataIdx: "VideoOption", status: "Video" }],
        languageType: "sp",
      },
      size: "medium",
      className: "medium-card",
    },
    {
      squareId: 4,
      widget: {
        title: "Avg, Wait Secs Video LOTS",
        value: (wd) => Math.round(parseFloat(wd?.avgWaitTimeVideoLots)),
        hidden: (s) => s?.filter?.languageType !== "-1",
        queryParams: [{ dataIdx: "VideoOption", status: "Video" }],
        languageType: "LOTS",
      },
      size: "medium",
      className: "medium-card",
    },
    {
      squareId: 5,
      widget: {
        title: "Avg. Call Length Mins",
        value: (wd) => Math.round(parseFloat(wd?.avgCallLength)),
        queryParams: [{ dataIdx: "Status", status: "Serviced" }],
      },
      size: "medium",
      className: "medium-card",
    },
    {
      squareId: 6,
      widget: {
        title: "Total Calls Serviced",
        value: (wd) => Formatter.numberWithCommas(wd?.totalCallServiced),
        queryParams: [{ dataIdx: "Status", status: "Serviced" }],
      },
      size: "medium",
      className: "medium-card",
    },
    {
      squareId: 7,
      widget: {
        title: "Completed Video Calls",
        value: (wd) => Formatter.numberWithCommas(wd?.successVideoCalls),
        queryParams: [
          { dataIdx: "Status", status: "Serviced" },
          { dataIdx: "VideoOption", status: "Video" },
        ],
      },
      size: "medium",
      className: "medium-card",
    },
    {
      squareId: 8,
      widget: {
        title: "Completed Audio Calls",
        value: (wd) => Formatter.numberWithCommas(wd?.successAudioCalls),
        queryParams: [
          { dataIdx: "Status", status: "Serviced" },
          { dataIdx: "VideoOption", status: "Audio" },
        ],
      },
      size: "medium",
      className: "medium-card",
    },
    {
      squareId: 9,
      widget: {
        title: "Total Requests",
        value: (wd) => Formatter.numberWithCommas(wd?.totalCalls),
      },
      size: "medium",
      className: "medium-card",
    },
    {
      squareId: 13,
      widget: {
        title: "Estimated Cost",
        value: (wd) => Formatter.numberWithCommas(wd?.estimatedCost, "$"),
        queryParams: [{ dataIdx: "Status", status: "Serviced" }],
      },
      size: "medium",
      className: "medium-card",
    },
    {
      squareId: 14,
      widget: {
        title: "Total New Requests",
        customClassName: "new-card",
        queryParams: [{ dataIdx: "Status", status: "new" }],
        value: (wd) => Formatter.numberWithCommas(wd?.totalNewCalls),
      },
      className: "medium-card",
      size: "medium",
    },
    {
      squareId: 15,
      widget: {
        title: "Total In-Service Requests",
        customClassName: "inservice-card",
        queryParams: [{ dataIdx: "Status", status: "inservice" }],
        value: (wd) => Formatter.numberWithCommas(wd?.totalInServiceCalls),
      },
      className: "medium-card",
      size: "medium",
    },
    {
      squareId: 17,
      widget: {
        title: <Tooltip title="Customer Owned Device">COD</Tooltip>,
        value: (customerOwnedCount) => (
          <div className="devices-count owned-count">{customerOwnedCount}</div>
        ),
        queryParams: [{ dataIdx: "deviceType", status: "Customer Owned" }],
        path: "/pages/reports/device-usage",
      },
      className: "customer-device-card medium-card",
      size: "small",
    },
    {
      squareId: 18,
      widget: {
        title: <Tooltip title="Voyce Owned Device">VOD</Tooltip>,
        value: (voyceOwnedCount) => (
          <div className="devices-count owned-count">{voyceOwnedCount}</div>
        ),
        queryParams: [{ dataIdx: "deviceType", status: "Voyce Owned" }],
        path: "/pages/reports/device-usage",
      },
      className: "voyce-device-card medium-card",
      size: "small", 
    },
    {
      squareId: 10,
      widget: {
        title: "Requests Cancelled",
        value: (wd) => Formatter.numberWithCommas(wd?.totalCallCancelled),
        queryParams: [{ dataIdx: "Status", status: "Canceled" }],
      },
      size: "medium",
    },

  ]);

  //useeffect called to update widget layout once widgets are dropped.
  useEffect(() => {
    if (drop) {
      swapWidgets(currentDrag, drop);
    }
  }, [drop]);

  //function to handle drag and drop for widgets
  function swapWidgets(sourceSquareId, targetSquareId) {
    setWidgets((prevWidgets) => {
      const updatedWidgets = prevWidgets.map((widget) => {
        if (widget.squareId === sourceSquareId) {
          return {
            ...widget,
            widget: prevWidgets.find((w) => w.squareId === targetSquareId)
              ?.widget,
          };
        } else if (widget.squareId === targetSquareId) {
          return {
            ...widget,
            widget: prevWidgets.find((w) => w.squareId === sourceSquareId)
              ?.widget,
          };
        }
        return widget;
      });

      return updatedWidgets;
    });
  }

  /**
   * Create widget handler.
   * @param {widgets} wd
   */
  const createHandleClick = (wd) => {
    const { queryParams, languageType, onClick, path } = wd;

    if (onClick) {
      return onClick();
    }

    const queryString = (queryParams || [])
      .map(
        ({ dataIdx, status }, index) =>
          `dataIdx${index}=${dataIdx}&status${index}=${status}`
      )
      .join("&");

    // Check the widget's title to determine the redirect
    const URL =
      wd.title === "Total New Requests" ||
      wd.title === "Total Pending Requests" ||
      wd.title === "Total In-Service Requests" // Redirect to pages/activity-monitor for 'Total New Requests' widget
        ? "/pages/activity-monitor"
        : path
        ? path // Redirect to path if custom path is given
        : "/pages/transactions"; // Redirect to pages/transactions for all other widgets

    navigate(`${URL}?${queryString}`);

    // Dispatch the languageType if needed
    if (languageType) {
      dispatch(setLanguageType(languageType));
    }
  };

  //get device count from kandji
  const kandjiCount = kandjiDeviceInfo?.length || 0;

  //function to render widget
  const renderWidgets = () => {
    return widgets
      .filter((square) => square.size === "medium")
      .sort((a, b) => a.squareId - b.squareId)
      .map((square) => {
        const wd = square.widget;

        return (
          <Col
            hidden={wd?.hidden ? wd?.hidden(state) : false}
            style={{ marginBottom: 20 }}
            flex="25%"
            key={square.squareId}
            className="medium-col"
          >
            <Square
              onDrop={setDrop}
              id={square.squareId}
              edit={edit}
              size="medium"
              loading={loading}
              title={wd.title}
            >
              <SquareDrag
                id={square.squareId}
                edit={edit}
                size="medium"
                loading={loading}
                title={wd.title}
                hover={!edit}
                onClick={() => (!edit ? createHandleClick(wd) : {})}
                onDrag={setDrag}
                className={(wd.customClassName ? wd.customClassName : "") + " " + square.className }
              >
                {/* Keep the original content for the "Active Carts" widget */}
                {wd.title === "Active Carts" ? (
                  <div className="devices-count">
                    {voyceOwnedCount} / {kandjiCount}
                  </div>
                ) : wd.title === "Customer Owned Device" ? (
                  // Render the "Customer Owned Device" widget content
                  <div className="customer-owned-device-count">
                    {customerOwnedCount}
                  </div>
                ) : (
                  wd?.value(widget) // For other widgets, render their specific content
                )}
              </SquareDrag>
            </Square>
          </Col>
        );
      });
  };

  //define widget layout for large widget and two small widgets under it
  const largeWidgetSquare = widgets.find(
    (square) => square.squareId === STATIC_WIDGETS_ID.LARGE
  );
  const lSmallWidgetSquare = widgets.find(
    (square) => square.squareId === STATIC_WIDGETS_ID.LEFT_SMALL
  );
  const rSmallWidgetSquare = widgets.find(
    (square) => square.squareId === STATIC_WIDGETS_ID.RIGHT_SMALL
  );
  const avgRating = useMemo(() => {
    const data = starRating;
    const totalStars = data?.filter((e) => e.ans != 0)?.reduce((sum, item) => sum + (item.ans * item.totalCount), 0);
    const totalCounts = data?.filter((e) => e.ans != 0)?.reduce((sum, item) => sum + (item.totalCount), 0);
    return (totalStars/totalCounts)
}, [starRating])


  return (
    <DndProvider backend={HTML5Backend}>
      <Col className="dashboard-container">
        <Row className="charts-row" wrap={false} gutter={20}>
          <Col flex={"auto"} className="call-by-hour-chart">
            <MemoizedHeatMap />
          </Col>
          <Col flex={"37.5%"}>
            <MemoizedTopLanguagesChart />
          </Col>
        </Row>
        <br />

        <Row
          wrap={false}
          gutter={20}
        >
          <Col flex={"30%"}>
            <Row gutter={20}>

            <Col flex={"50%"} xs={24} md={12}>
                {/* Display the "Customer Owned Device" widget here */}
                <Square
                  onDrop={setDrop}
                  id={lSmallWidgetSquare?.squareId}

                  size="medium"
                  loading={loading}
                  title={lSmallWidgetSquare?.widget?.title}
                >
                  <SquareDrag
                    id={lSmallWidgetSquare?.squareId}

                    size="medium"
                    loading={loading}
                    title={lSmallWidgetSquare?.widget?.title}
                    onClick={() =>
                      !edit ? createHandleClick(lSmallWidgetSquare?.widget) : {}
                    }
                    onDrag={setDrag}
                  >
                    {lSmallWidgetSquare?.widget?.value(voyceOwnedCount)}
                  </SquareDrag>
                </Square>
              </Col>

              <Col flex={"50%"} xs={24} md={12}>
                {/* Display the "Voyce Owned Device" widget here */}
                <Square
                  onDrop={setDrop}
                  id={rSmallWidgetSquare?.squareId}
                  size="medium"
                  loading={loading}
                  title={rSmallWidgetSquare?.widget?.title}
                >
                  <SquareDrag
                    id={rSmallWidgetSquare?.squareId}
                    size="medium"
                    loading={loading}
                    title={rSmallWidgetSquare?.widget?.title}
                    onClick={() =>
                      !edit ? createHandleClick(rSmallWidgetSquare?.widget) : {}
                    }
                    onDrag={setDrag}
                  >
                    {rSmallWidgetSquare?.widget?.value(customerOwnedCount)}
                  </SquareDrag>
                </Square>
              </Col>

            </Row>
            <br />
            <Row gutter={20}>
              <Col
                style={{ marginBottom: 20 }}
                flex={"100%"}
                className="star-rating-col"
              >
                <Card
                  className="widget-cards terp-card"
                  bordered={false}
                  style={{ fontSize: 20, cursor: "default" }}
                  loading={loading}
                  title="Star Rating"
                >
                  <Row gutter={20} className="rating-row" style={{marginTop: 14}}>
                    <Col
                      flex={"25%"}
                      className="average-rating-col"
                      style={{ marginRight: -10 }}
                    >
                      <p>
                        {Math.round((avgRating + Number.EPSILON) * 100) /
                          100 +
                          " / 5"}
                      </p>
                    </Col>
                    <Col flex={"75%"}  className="star-rating-graph-col" >
                      <StarRatingBarGroup data={starRating} />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col flex={"70%"}>
            <Row className={edit ? "widget-row glow" : "widget-row"} gutter={20}>{renderWidgets(widget)}</Row>
          </Col>
        </Row>

        <i>
          <p style={{ color: colorText, marginBottom: 0 }}>
            <span style={{ color: colorText, fontSize: 24 }}>*</span> Spanish
            Languages, LOTS - Languages other than Spanish, Sign Languages.
          </p>
        </i>
        <FloatButton
          type="primary"
          onClick={() => setEdit(!edit)}
          icon={edit ? <CloseOutlined /> : <EditFilled />}
          style={{ marginBottom: 15 }}
        />
      </Col>
    </DndProvider>
  );
};

// Export the Dashboard component
export default Dashboard;
