import ActionType from "../action.types";

/*
    Reducer function to manage state changes related to profile actions
*/

// Initial state for the reducer
const initialState = {
    selectedColumns: [],
    unselectedColumns: [],
};

// Reducer function handling profile-related actions
export default function selectedColumns(state = initialState, action) {
    switch (action.type) {
        // Handles action to change columns
        case ActionType.CHANGE_COLUMNS:
            return {
                ...state,
                selectedColumns: action.payload.selectedColumns,
                unselectedColumns: action.payload.unselectedColumns,
            };

        // Default case returns the current state
        default:
            return state;
    }
}
