const InvoiceEnum = {
    ALL: "All",
    PAID: "Paid",
    PENDING: "Pending",
    OVERDUE: "Unpaid"
}

const InvoiceOptions = [
    { label: 'All', value: InvoiceEnum.ALL },
    { label: 'Paid', value: InvoiceEnum.PAID },
    { label: 'Pending', value: InvoiceEnum.PENDING },
    { label: 'Overdue', value: InvoiceEnum.OVERDUE },
];

export { InvoiceEnum, InvoiceOptions };
