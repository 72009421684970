import ActionType from '../action.types';
import Service from '../../services';
import { message } from 'antd';

//action to set Companies
export function setCompanies(companies) {
    return {
        type: ActionType.SET_COMPANIES,
        companies
    };
}

//action to select company
export function selectCompany(defaultClient) {
    return {
        type: ActionType.SELECT_COMPANY,
        client: defaultClient
    };
}

//action to select client
export function selectClient(client) {
    return {
        type: ActionType.SELECT_CLIENT,
        client
    };
}

export function selectRegion(region, site) {
    return {
        type: ActionType.SET_REGION,
        region: region,
        site: site
    };
}

export function selectSite(defaultClient) {
    return {
        type: ActionType.SET_SITE,
        site: defaultClient
    };
}

/**
 * Company action to set company timezone for specified company
 *
 */
export function setCompanyTimeZone({TimeZone}) {
    return {
        type: ActionType.SET_TIMEZONE,
        timezone: TimeZone
    }
}

/**
 * Company action to get all active clients and companies.
 * @returns 
 */
export function getActiveCompanies() {
    return async (dispatch) => {
        try {
            let data = await Service.Company().allActive();
            dispatch(setCompanies(data));
            return data;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

/**
 * Company action to get timezone for all active clients and companies.
 * @returns 
 */
export function getCompanyTimezone(companyId) {
    return async (dispatch) => {
        try {
            let data = await Service.Company().getTimezone(companyId);
            setCompanyTimeZone(data)
            return data;
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}
