import Service from '../../services';
import { message } from 'antd';
import { store } from '../..';

/**
 * Device action to get all devices usage details.
 * @returns 
 */
export function fetchDeviceInfo() {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getTotalActiveDevices(filter);
            return data
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}
