import { Col, Row, theme } from "antd";
import "./auth.scss";
import { Navigate, Outlet } from "react-router-dom";
import backgroundImage from '../../images/background-svg.svg';
import logoDark from '../../images/VOY-Logo-WHITE.png';
import logoLight from '../../images/Logo-voyce.png';
import Spinner from "../../components/spinner";
import { useSelector } from "react-redux";

const Auth = ({}) => {
    // Retrieve necessary data from Redux store and manage component state
    const {token: {colorBgLayout}} = theme.useToken();
    const token = useSelector((state) => state?.profile?.token);
    const user = useSelector((state) => state.profile?.user);
    const role = user?.permissions?.role
    const currentTheme = useSelector((state) => state.profile?.theme);
      // Conditionally set the path for the Navigate component based on the role
    const navigatePath = role === "Super Admin" ? "/pages/role-hierarchy" : "/pages";

    return (
       <div 
            className="auth-container" 
            style={{
                background: colorBgLayout, 
                backgroundImage: `url(${backgroundImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPositionX: 'center',
                backgroundPositionY: 'bottom'
            }}
        >
        <Spinner/>
        {token && <Navigate to={navigatePath} />}
        <Row>
            <img src={currentTheme === 'light' ? logoLight : logoDark} height={25} style={{position: 'absolute', top: 30, left: 30}} />
        </Row>

        <div className="content">
  <Outlet />
</div>                    
        <Col 
            className="troubleshooting"
            >
            <h4>
                Having trouble logging in? <br />
                Access Login Troubleshooting Help.
            </h4>
            <p>
                Please call (866) 745 6525 to speak with
                customer support representative who can help
                with any issues. Access Login
                Troubleshooting Help.
            </p>
        </Col>
       </div>
    );
};

export default Auth;
