import { Row, Col, theme, Table, Button, Input, Space, TimePicker } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import { updateColumns } from "../../store/actions/selectedColumns";
import "./transaction.scss";
import Service from "../../services";
import moment from "moment";
import DropDown from "../../components/dropdown";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import DragnDrop from "./dragndrop";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useIntervalAsync from "../../components/hooks/useInterval";
import Formatter from "../../utils/Formatter";
import "jspdf-autotable";
import CustomFilter from "./customFilter";

dayjs.extend(utc);
dayjs.extend(timezone);

// Initialize constants and set default configurations
const SITE_COLUMN = ["1598"]

dayjs.tz.setDefault("Etc/UTC");

//option in download dropdown
const downloadDropdownType = [
    {
        value: "PDF",
        label: "PDF",
    },
    {
        value: "EXCEL",
        label: "EXCEL",
    },
];

const GetColumnSearchProps = (dataIndex, updateFilters, isLive) => {
  return ({
      filterDropdown: (props) => <CustomFilter 
          dataIndex={dataIndex.dataIndex} 
          showTimeRange={dataIndex.showTimeRange} 
          updateFilters={updateFilters}
          isLive={true}
          {...props} 
          />,
      filterIcon: (filtered) => (
          <SearchOutlined
            style={{
              color: filtered ? '#1890ff' : undefined,
            }}
          />
        ),
  })
};

// Define a function to render additional details in an expanded row
const expandedRowRender = (record) => {
    let data = [
        {
            InterpreterFirstName: record?.InterpreterFirstName,
            InterpreterLastName: record?.InterpreterLastName,
            ServiceStartTime: record?.ServiceStartTime,
            InterpreterId: record?.InterpreterId,
            Timezone: record?.Timezone,
        },
    ];

    // Define expander row columns
    const columns = [
        {
            title: "Interpreter ID",
            dataIndex: "InterpreterId",
            align: "center",
            render: (text) => <div> {text || "-"} </div>,
        },
        {
            title: "Interpreter Name",
            dataIndex: "InterpreterFirstName",
            align: "center",
            width: "30%",
            render: (first, row) => {
                const firstName = first || "";
                const lastName = row?.InterpreterLastName || "";
                return `${firstName} ${lastName}`.trim() || '-';
            },
        },
        {
            title: "Service Start Time",
            dataIndex: "ServiceStartTime",
            render: (text, row) => (
                <div style={{ textAlign: text === null ? "center" : "left" }}>
                    {text ? Formatter.createLocalDateTime(text, row.Timezone)?.format("HH:mm:ss") : "-"}
                </div>
            ),
            width: "35%",
        },
    ];
    return (
        <Table
            key={record?.Id + "_sub"}
            title={() => <b>Call Accepted By</b>}
            size="small"
            className="expanded-table"
            columns={columns}
            dataSource={data}
            width="20px"
            pagination={false}
            rowClassName={(record, index) =>
                record?.AcceptTime ? "accept-row" : "declined-row"
            }
        />
    );
};

//main component
const ActivityMonitor = () => {
  // Define state variables and hooks...
    const location = useLocation();
    const dispatch = useDispatch();
    //handle filter for redirection from dashboard to activity monitor page
    const getDashboardFilters = () => {
        const searchParams = new URLSearchParams(location.search);
        let filters = {};

        for (let i = 0; ; i++) {
            const dataIdx = searchParams.get(`dataIdx${i}`);
            const status = searchParams.get(`status${i}`);

            if (dataIdx && status) {
                filters[dataIdx] = status;
            } else {
                break;
            }
        }

        return filters;
    };
    const dashboardFilters = getDashboardFilters();

    //geth theme token
    const {
        token: { colorBgLayout },
    } = theme.useToken();
    //get data from redux store
    const state = useSelector((state) => state);
    const columnState = useSelector((state) => state.selectedColumns);
    const userId = useSelector((state) => state?.profile?.user?.id);
    const selectedCompany = useSelector(
        (state) => state?.profile?.user?.permissions?.company?.toString()
    );
    const { selectedColumns, unselectedColumns } = columnState;
    const [downloadType, setDownloadType] = useState("EXCEL");
    const [tableLoading, setTableLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
    });
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [filterSearch, setFilterSearch] = useState(dashboardFilters);
    const [params, setParams] = useState();
    const searchInput = useRef(null);
    const [filterValues, setFilterValues] = useState({});
    const updateFilters = useCallback((dataIndex, value) => {
        setFilterValues(prev => ({ ...prev, [dataIndex]: value }));
    }, []);
    //default columns 
    const getDefaultColumns = [
        {
            title: "Transaction ID",
            dataIndex: "ReferenceTransactionId",
            sorter: (a, b) => a.ReferenceTransactionId - b.ReferenceTransactionId,
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "ReferenceTransactionId" }, updateFilters),
            render: (text) => 
            <div>
                {text || "-"}
            </div>,
             width: "130px",
        },
        {
            title: "Request Date",
            dataIndex: "RequestTime",
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "RequestDate" }, updateFilters),
            render: (text, row) => (
                <div>
                    {text ? Formatter.createLocalDateTime(text, row.Timezone)?.format("YYYY-MM-DD") : "-"}
                </div>
            ),
        },
        {
            title: "Request Time",
            dataIndex: "ExtractedTime",
            align: "center",
            sorter: (a, b) => a.ExtractedTime?.localeCompare(b.ExtractedTime),
            ...GetColumnSearchProps({
                dataIndex: "ExtractedTime",
                value: undefined,
                showTimeRange: true,
            }, updateFilters),
            render: (text, row) => (
                <div>
                    {text ? Formatter.createLocalDateTime(row.RequestTime, row.Timezone)?.format("HH:mm:ss") : "-"}
                </div>
            ),
        },
        {
            title: "Client Name",
            dataIndex: "ClientName",
            align: "center",
            //width: "150px",
            sorter: (a, b) => a.ClientName?.localeCompare(b.ClientName),
            ...GetColumnSearchProps({ dataIndex: "ClientName" }, updateFilters),
        },
        {
            title: "Time Zone",
            dataIndex: "Timezone",
            align: "center",
        },
        {
            title: "Target Language",
            dataIndex: "TargetLanguage",
            align: "center",
            sorter: (a, b) => a.TargetLanguage?.localeCompare(b.TargetLanguage),
            ...GetColumnSearchProps({ dataIndex: "TargetLanguage" }, updateFilters),
        },
        {
            title: "Audio/Video",
            dataIndex: "VideoOption",
            align: "center",
            sorter: (a, b) => a.VideoOption?.localeCompare(b.VideoOption),
            ...GetColumnSearchProps({ dataIndex: "VideoOption" }, updateFilters),
            width: "150px",
        },
        {
            title: "Status",
            dataIndex: "Status",
            align: "center",
            sorter: (a, b) => a.Status?.localeCompare(b.Status),
            ...GetColumnSearchProps({dataIndex: 'Status', isDate:false}, updateFilters),
        },
        {
            title: "Waiting Seconds",
            dataIndex: "WaitingSeconds",
            align: "center",
            sorter: (a, b) => a.WaitingSeconds - b.WaitingSeconds,
        },
        {
            title: "Service Minutes",
            dataIndex: "ServiceMinutes",
            align: "center",
            sorter: (a, b) => a.ServiceMinutes - b.ServiceMinutes,
            render: (text) => 
            <div>
                {text || "-"}
            </div>
        },
        {
            title: "Star Rating",
            dataIndex: "CallQualityRatingStar",
            align: "center",
            render: (text) => 
            <div>
                {text || "-"}
            </div>
        },
        {
            title: "Caller Id",
            dataIndex: "CallerID",
            align: "center",
            width: 150,
            ...GetColumnSearchProps({ dataIndex: "CallerID" }, updateFilters),
            render: (text) => 
            <div>
                {text || "-"}
            </div>
        },
    ];

    //additional columns that can be selected
    const getAdditionalColumns = () => {
        let cols = [
            {
                title: "Product Name",
                dataIndex: "RequestProductName",
                align: "center",
                sorter: (a, b) => a.RequestProductName?.localeCompare(b.RequestProductName),
                ...GetColumnSearchProps({ dataIndex: "RequestProductName" }, updateFilters),
                //width: "120px",
            },
            {
                title: "Service Start Time",
                dataIndex: "ServiceStartTime",
                align: "center",
                sorter: (a, b) => a.ServiceStartTime?.localeCompare(b.ServiceStartTime),
                render: (text, row) => (
                    <div>
                        {text ? Formatter.createLocalDateTime(text, row.Timezone)?.format("HH:mm:ss") : "-"}
                    </div>
                ),
                //width: "120px",
            },
            {
                title: "Cancel Time",
                dataIndex: "ServiceCancelTime",
                align: "center",
                sorter: (a, b) => a.ServiceCancelTime - b.ServiceCancelTime,
                //width: "120px",
                render: (text, row) => (
                    <div>
                        {text ? Formatter.createLocalDateTime(text, row.Timezone)?.format("YYYY-MM-DD HH:mm:ss") : "-"}
                    </div>
                ),

            },
            {
                title: "Client User Name",
                dataIndex: "UserName",
                align: "center",
                sorter: (a, b) => a.UserName?.localeCompare(b.UserName),
                ...GetColumnSearchProps({ dataIndex: "UserName" }, updateFilters),
                width: "150px",
            },
            {
                title: "Interpreter ID",
                dataIndex: "InterpreterId",
                align: "center",
                sorter: (a, b) => a.InterpreterId - b.InterpreterId,
                ...GetColumnSearchProps({ dataIndex: "InterpreterId" }, updateFilters),
                render: (text) => 
                <div>
                    {text || "-"}
                </div>,
                width: '150px'
            },
            {
                title: "Interpreter Name",
                dataIndex: "InterpreterFirstName",
                align: "center",
                ...GetColumnSearchProps({ dataIndex: "InterpreterFirstName" }, updateFilters),
                render: (first, row) => {
                    const firstName = first || "";
                    const lastName = row?.InterpreterLastName || "";
                    return `${firstName} ${lastName}`.trim() || '-';
                },
            },
            {
                title: "Serial Number",
                dataIndex: "IOSSerialNumber",
                align: "center",
                width: "150px",
                ...GetColumnSearchProps({ dataIndex: "IOSSerialNumber" }, updateFilters),
                render: (text) => 
                <div>
                    {text || "-"}
                </div>
            },
            {
                title: "Cost",
                dataIndex: "TotalPrice",
                align: "center",
                render: (text) => 
                <div>
                    {(text && `$${Number(text).toFixed(2)}`) || "-"}
                </div>
            },
        ];
        return cols;
    };

    //function to handle missing or nrw column
    const addMissingOrNewColumns = (selectedCols=[], unselectedCols=[]) => {
        const selectedColumns = JSON.parse(JSON.stringify(selectedCols))
        const unselectedColumns = JSON.parse(JSON.stringify(unselectedCols))

        const defaultColumns = getDefaultColumns;
        const additionalColumns = getAdditionalColumns();

        const allCustomerCols = selectedColumns.concat(unselectedColumns);
        const allAvailableCols = defaultColumns.concat(additionalColumns);

        function extractDataIndices(array) {
            return array.map(item => item.dataIndex);
        }
          
        const customerIndices = extractDataIndices(allCustomerCols);
        const availableIndices = extractDataIndices(allAvailableCols);

        const missingColumns = availableIndices.filter(dataIndex => !customerIndices.includes(dataIndex)) || [];
        missingColumns.forEach((indice) => {
            let column = allAvailableCols.find((col) => col.dataIndex == indice);
            if (column) {
                unselectedCols.push(column)
            }
        })
    }

    //function to handle custom column requirment for some company (eg northwestern NM)
    const getCustomColumns = async () => {
        const response = await Service.Auth().getCustomColumns(userId);
        const customColumns = response;
        let { preSelectedColumns, preUnSelectedColumns } = customColumns;
    
        const site_column_obj = {
            title: "Client Site",
            dataIndex: "Site",
            width: "120px",
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "Site" }, updateFilters),
            render: (text) => 
            <div>
                {text || "-"}
            </div>
        }

        const defaultColumns = getDefaultColumns;
        const additionalColumns = getAdditionalColumns();
        if (SITE_COLUMN.includes(selectedCompany)) {
            defaultColumns.splice(3, 0, site_column_obj)
        }

        // create an copy of array
        let col = [...defaultColumns, ...additionalColumns];
        
        if (preSelectedColumns || preUnSelectedColumns) {
            const selectedColumns = preSelectedColumns?.map((dataIndex) =>
                col.find((column) => column?.dataIndex === dataIndex)
            );
            const unselectedColumns = preUnSelectedColumns?.map((dataIndex) =>
                col.find((column) => column?.dataIndex === dataIndex)
            );
            if (SITE_COLUMN.includes(selectedCompany)) {
                if (
                    !selectedColumns.find(e => e.dataIndex === "Site") &&
                    !unselectedColumns.find(e => e.dataIndex === "Site")
                ) {
                    selectedColumns.splice(3, 0, site_column_obj)
                }
            }
            addMissingOrNewColumns(selectedColumns, unselectedColumns);
            dispatch(updateColumns(selectedColumns, unselectedColumns, userId));
            return { selectedColumns, unselectedColumns };
        } else {
            let defaultCol = getDefaultColumns;
            let addCol = getAdditionalColumns();

            dispatch(updateColumns(defaultCol, addCol, userId));
            return { defaultCol, addCol };
        }
    };

    // Create a legend to indicate row background colors
    const legend = (
        <div className="legend-container"> 
        <div className="legend-item"> 
            <div
            className="legend-color-box"
            style={{ backgroundColor: '#00cc66ba' }} // In-service color
            ></div>
            <span>In-Service Transaction</span>
        </div>
        <div className="legend-item">
            <div
            className="legend-color-box"
            style={{ backgroundColor: '#00b4e6be' }} // New transaction color
            ></div>
            <span>New Transaction</span>
        </div>
        <div className="legend-item">
            <div
            className="legend-color-box"
            style={{ backgroundColor: '#ff9933b4' }} // Pending transaction color
            ></div>
            <span>Pending Transaction</span>
        </div>
        </div>
    );

    
    //fetch initial data based on select filters
    const fetchDataCB = useCallback(async () => {
        fetchDataPrivate( params?.filters, params?.sorter);
    }, [params]);

    const fetchData = useIntervalAsync(
        fetchDataCB,
        state?.filter?.fetchInterval
    );


    //fetch data on initial load or when parameter changes
    useEffect(() => {
        if (params) {
            setTableLoading(true)
            fetchData();
        }
    }, [params]);

    useEffect(() => {
        setTableLoading(true);
        setParams({
            filters: tableParams.filters,
            sorter: tableParams.sorter,
        });
    }, [
        state.filter?.languageType,
        state.filter?.date,
        state.filter?.statuses,
        state.company?.client,
        state.company?.region,
        state.company?.site,
        filterSearch,
    ]);

    //define initial selected and unselected column
    const initialColumns = selectedColumns
        ? selectedColumns
        : getDefaultColumns;
    const initialUnselectedColumns = unselectedColumns
        ? unselectedColumns
        : getAdditionalColumns();
    const [columns, setColumns] = useState(initialColumns);
    const [columnsUnselected, setColumnsUnselected] = useState(
        initialUnselectedColumns
    );
   
    //useeffect to fetch data based on selected company
    useEffect(() => {
        if (selectedCompany) {
            getCustomColumns().then(({ selectedColumns, unselectedColumns }) => {
                setColumns(selectedColumns);
                setColumnsUnselected(unselectedColumns);
            });
        }
    }, [selectedCompany]);

    //fetch data if selected or unselected column changes
    useEffect(() => {
        const defaultColumns = getDefaultColumns;
        const additionalColumns = getAdditionalColumns();
        const newColumns =
        selectedColumns && selectedColumns.length > 0
            ? selectedColumns
            : defaultColumns;
        const newColumnsUnselected =
            unselectedColumns && unselectedColumns.length > 0
                ? unselectedColumns
                : additionalColumns;
        if (JSON.stringify(newColumns) !== JSON.stringify(columns)) {
            setColumns(newColumns);
        }
        if (
            JSON.stringify(newColumnsUnselected) !==
            JSON.stringify(columnsUnselected)
        ) {
            setColumnsUnselected(newColumnsUnselected);
        }
    }, [selectedColumns, unselectedColumns]);


    //function to show modal
    const showModal = () => {
        setModalVisible(true);
        console.log("modalVisible: ", modalVisible);
    };

    //handle cancel button functionality
    const handleCancel = () => {
        setModalVisible(false);
    };

    //call function based on download type
    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDFFunction();
        } else if (downloadType === "EXCEL") {
            downloadCSVFunction();
        }
    };

    //function to handle table change
    const handleTableChange = ( filters, sorter) => {
        setParams({  filters, sorter });
    };

    //function to handle search functionality
    const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {
        if(selectedKeys.length > 0 && typeof selectedKeys[0] == "string") {
            selectedKeys[0] = selectedKeys[0]?.trim();

            if (selectedKeys[0] == '') {
                handleReset(clearFilters, dataIndex, confirm);
                return;
            }
        }

        if (selectedKeys.length == 0) {
            handleReset(clearFilters, dataIndex, confirm);
            return;
        }
        
        confirm();
        setSearchText(selectedKeys);
        setSearchedColumn(dataIndex);

        setFilterSearch((prevState) => {
            const updatedFilterSearch = {
                ...prevState,
                [dataIndex]: selectedKeys,
            };
            setParams({
                filters: { ...tableParams.filters, ...updatedFilterSearch },
                sorter: tableParams.sorter,
            });
            return updatedFilterSearch;
        });
    };

    //function to handle reset functionality when reset button is clicked
    const handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters();
        confirm();
        setSearchText("");
        setSearchedColumn(dataIndex);

        setFilterSearch((prevState) => {
            const updatedFilterSearch = JSON.parse(
                JSON.stringify(prevState || {})
            );
            const updatedTableParams = JSON.parse(
                JSON.stringify(tableParams.filters || {})
            );
            delete updatedTableParams[dataIndex];
            delete updatedFilterSearch[dataIndex];
            setParams({
                filters: { ...updatedTableParams, ...updatedFilterSearch },
                sorter: tableParams.sorter,
            });
            setTableParams((pState) => {
                return { ...pState, filters: updatedTableParams };
            });
            return updatedFilterSearch;
        });
    };

    //fetch data
    const fetchDataPrivate = async (filters) => {
        const filter = {
          start: state.filter?.date?.minDate,
          end: state.filter?.date?.maxDate,
          languageType: state.filter?.languageType,
          statuses: state.filter?.statuses,
          filters: {
            ...filterValues,
            ...filters,
            ...Object.assign({}, filterSearch, dashboardFilters),
          },
        };
    
        //call api for getting active calls
        Service.Transaction()
          .getActiveTransactions(
            filter, 
            params.sort,
            loading
          )
          .then((data) => {
            data = data.sort((a, b) => a.Status?.localeCompare(b.Status))
            setData(data?.length > 0 ? data : []);
            setTableLoading(false);
          });
      };
    

    //function to handle CSV download
    const downloadCSVFunction = ( filters) => {
        let params = tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            statuses: state.filter?.statuses,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
        };
        setLoading(true);
        let dataIndexValues = columns.map((column) => column?.dataIndex);
        if (!dataIndexValues.includes('InterpreterId')) {
            dataIndexValues.push('InterpreterId');
        }
        if (!dataIndexValues.includes('InterpreterFirstName')) {
            dataIndexValues.push('InterpreterFirstName');
        }
        Service.Transaction()
            .downloadActivityCSV(filter, params.sort, dataIndexValues)
            .then((data) => {
                setLoading(false);
                const downloadRangeStart = moment(state.filter?.date?.minDate).format('MM-DD-YYYY');
                const downloadRangeEnd = moment(state.filter?.date?.maxDate).format('MM-DD-YYYY');
                const filename = `Activity_Monitor_Records_${downloadRangeStart}--${downloadRangeEnd}.xlsx`;
                saveAs(data, filename);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };

    //function to handle pdf download
    const downloadPDFFunction = ( filters) => {
        let params = tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            statuses: state.filter?.statuses,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
        };
        setLoading(true);
        const dataIndexValues = columns.map((column) => column?.dataIndex);
        Service.Transaction()
            .downloadActivityPDF(filter, params.sort, dataIndexValues)
            .then((data) => {
                setLoading(false);
                const downloadRangeStart = moment(state.filter?.date?.minDate).format('MM-DD-YYYY');
                const downloadRangeEnd = moment(state.filter?.date?.maxDate).format('MM-DD-YYYY');
                const filename = `Activity_Monitor_Records--${downloadRangeStart}--${downloadRangeEnd}`;
                saveAs(data, filename);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };

        // Get dynamic table scroll width
        const totalMinWidth = columns.reduce((acc, column) => {
            if (column?.width) {
              return acc + parseInt(column?.width, 10);
            }
            return acc + 120;
        }, 0);

    return (
        <div className="roles-container">
          <Row className="irh-container">
            <Col flex={"100%"}>
              <Table
                title={() => (
                  <Row className="select-col">
                    <Col flex={"auto"}>
                      <Button onClick={showModal} className="select-col-btn" type="primary">
                        Select Columns
                      </Button>
                    </Col>
                    <Col flex={"auto"} className="legend-container">
                        {legend}
                    </Col>
                    <Col className="dropdown-col">
                      <Row
                        gutter={[8, 8]}
                        justify="end"
                        align="middle"
                        className="dropdown-download-row"
                      >
                        <Col>
                          <DropDown
                            className="dropdown-element"
                            defaultValue={downloadType}
                            dropdownOptions={downloadDropdownType}
                            handleTypeSelect={(downloadType) =>
                              setDownloadType(downloadType)
                            }
                          />
                        </Col>
                        <Col>
                          <Button
                            loading={loading}
                            type="primary"
                            onClick={downloadFile}
                          >
                            Download
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )}
                style={{
                  backgroundColor: colorBgLayout,
                }}
                columns={columns}
                scroll = {{ x : totalMinWidth }}
                rowKey={(record) => record?.Id}
                dataSource={data}
                loading={tableLoading}
                onChange={handleTableChange}
                size="middle"
                pagination={false}
                className="voyce-custom-table responsive-table"
                expandable={{
                  expandedRowRender,
                  defaultExpandAllRows: false,
                }}
                sticky
                rowClassName={(record, index) =>
                  record?.Status == "New"
                    ? "new-row"
                    : record?.Status == "In Service"
                    ? "inservice-row"
                    : record?.Status == "Pending"
                    ? "pending-row"
                    : "other-row"
                }
              />
            </Col>
          </Row>
          <DragnDrop
            defaultColumns={columns}
            additionalColumns={columnsUnselected}
            modalVisible={modalVisible}
            handleCancel={handleCancel}
          />
        </div>
      );
    };
    
    export default ActivityMonitor;
