import { Axios } from "axios";
import constants from "../constants";

class AnalyticsService {

    /**
     * Company Services
     * @param {Axios} request 
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_BASE_URL;
    }

    //function to call api to fetch widget data on dashboard for specified timeframe
    async getTotalAggData(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/agg-by-client/language/total', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    //function to call api to fetch top 5 language data for the chart
    async getTopLanguages(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/agg-by-client/hour/language', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    //function to call api for Language Report and Audio VS Video Report data
    async getLanguagesTotal(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/agg-by-client/language/detail/total', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    //function to fetch Call by hour heatmap total data in the chart
    async getLanguagesTotalTz(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/agg-by-client/language/hour/total', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }

    //function to call api for call by hour data for heatmap chart
    async getCallsByHoursLang(filter, hideLoader) {
        try {
            let response = await this.request.post(
                '/agg-by-client/language/hour', filter,
                {filter: true, hideLoader}
            );
            return response.data;
        } catch(err) {
            console.log(err);
            throw err;
        }
    }
    
    //function to call api to fetch all active devices based on transactions happened in the specified timeframe
    async getTotalActiveDevices(filter, body = {}) {
        try {
          const response = await this.request.post('/get-all-devices', body, {
            filter: true,
            params: {
              start: filter?.start?.toISOString(),
              end: filter?.end?.toISOString(),
            },
          });
          return response.data;
        } catch (err) {
          console.log(err);
          throw err;
        }
    }

    // function to get star rating
    async getStarRatingList(filter, hideLoader) {
        try {
            const response = await this.request.post('/star-rating/by-date', filter, {
                filter: true,
                hideLoader
            });
            return response.data;
        } catch (error) {
            console.log(error);
            throw error;
        }
    }
}

export default AnalyticsService;
