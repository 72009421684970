class Validator {
    // Rules defining password criteria
    static PasswordRules = [
        {name: 'At least 7 characters.', rule: /^.{7,}$/},
        {name: 'At least one number.', rule: /.*[0-9].*/},
        {name: 'At least one special character.', rule: /(?=.*\W)/},
        {name: 'Must contain one uppercase letter.', rule: /(?=.*?[A-Z])/},
        {name: 'Must contain one lowercase letter.', rule: /(?=.*?[a-z])/},
    ];

    // Validates a password against predefined rules
    static validatePassword = (value = '') => {
        for (let i = 0; i < this.PasswordRules.length; i++) {
            // Checks if the password matches the defined rule
            if (!value.match(this.PasswordRules[i].rule)) {
                return; // Returns undefined if the password does not meet a rule
            }
        }
        return true; // Returns true if the password meets all criteria
    }
}

export default Validator;
