import { Card, Col, Divider, Row, Spin, Typography, theme } from "antd";
import EChartsReact from "echarts-for-react";
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getCallsByHoursLanguage } from "../../../store/actions/analytics.action";

import _ from "lodash";

// HeatMap component definition
const HeatMap = ({ setSelectedHour }) => {
  // State variables using React hooks
  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const [languages, setLanguages] = useState([]);
  const [data, setData] = useState([]);
  const [totalData, setTotalData] = useState([]);

  // Fetch data for calls by hours callback
  const fetchCallsByHoursCB = useCallback(async () => {
    setLoading(true);
    dispatch(getCallsByHoursLanguage())
      .then((data) => {
        setLoading(false);
        setDataSource(data || []);
      })
      .catch(() => setLoading(false));
  }, []);

  // Fetch data effect with specified dependencies
  useEffect(() => {
    fetchCallsByHoursCB();
  }, [
    state.filter?.date,
    state.company?.selected,
    state.company?.client,
    state.company?.region,
    state.company?.site,
  ]);

  // Effect to handle data transformation when data source changes
  useEffect(() => {
    const languages = dataSource.map((e) =>
      e?.languageName?.includes("ASL") ? "ASL" : e?.languageName
    );
    const data = [];
    const totalData = [];

    for (let i = 0; i < 10; i++) {
      let lang_index = i;
      for (let j = 0; j < 24; j++) {
        let hour_index = j;
        let hour = dataSource[i]?.hours?.find((e) => e?.hour == hour_index);
        data.push([hour_index, lang_index, hour ? hour.TotalCalls : 0]);
      }
    }

    for (let j = 0; j < 24; j++) {
      let hour_index = j;
      for (let i = 0; i < 10; i++) {
        let lang_index = i;
        let hour = dataSource[lang_index]?.hours?.find(
          (e) => e?.hour == hour_index
        );
        if (totalData[hour_index]) {
          totalData[hour_index][2] += hour ? hour.TotalCalls : 0;
        } else {
          totalData.push([hour_index, lang_index, hour ? hour.TotalCalls : 0]);
        }
      }
    }
    // Update state variables with transformed data
    setData(data);
    setLanguages(languages);
    setTotalData(totalData);
  }, [dataSource]);

  // Theme tokens for color configuration
  const {
    token: { colorText, colorBgLayout },
  } = theme.useToken();


  // Colors for the heatmap
  const colors = ["#74a8cf", "#d2facb", "#54c9e8", "#203870", "#ff876f"];

  // Hour labels
  const hours = [
    "12a",
    "1a",
    "2a",
    "3a",
    "4a",
    "5a",
    "6a",
    "7a",
    "8a",
    "9a",
    "10a",
    "11a",
    "12p",
    "1p",
    "2p",
    "3p",
    "4p",
    "5p",
    "6p",
    "7p",
    "8p",
    "9p",
    "10p",
    "11p",
  ];

  // Formatter for the series of the heatmap
  const seriesFormatter = useCallback((param) => {
    if (param.data[2] === 0) {
      return "";
    }
  }, []);

  // Formatter function for tooltip information
  const formatter1 = useCallback(
    (params) => {
      return (
        "<b>" +
        params.seriesName +
        "</b>" +
        "<br/>" +
        "Language: " +
        languages[params.value[1]] +
        "<br/>" +
        "Hour: " +
        params.name +
        "<br/>" +
        "Total Calls: " +
        params.value[2]
      );
    },
    [languages]
  );

  // Options configuration for the main heatmap chart
  const options = () => ({
    tooltip: {
      position: "top",
      formatter: formatter1,
    },
    grid: {
      height: "90%",
      top: "20",
      right: "1%", //90
      left: "1%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: hours,
      splitArea: {
        show: true,
      },
      position: "top",
      axisLabel: {
        textStyle: {
          color: colorText,
          fontSize: 14,
        },
      },
      axisTick: {
        show: false,
      },
    },
    yAxis: {
      type: "category",
      data: languages,
      splitArea: {
        show: true,
      },
      position: "right",
      axisLabel: {
        textStyle: {
          color: colorText,
          fontSize: 14,
        },
        interval: 0,
        show: true,
        hideOverlap: true,
      },
      axisTick: {
        show: false,
      },
      containLabel: true,
    },
    visualMap: {
      min: 0,
      max: 10,
      calculable: true,
      orient: "horizontal",
      left: "center",
      bottom: "15%",
      show: false,
      inRange: {
        color: colors,
      },
    },
    series: [
      {
        name: "Total calls",
        type: "heatmap",
        data: data,
        label: {
          normal: {
            show: true,
            formatter: seriesFormatter,
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        itemStyle: {
          normal: {
            borderColor: colorBgLayout,
            borderWidth: 4,
            borderCap: "round",
            borderRadius: 6,
          },
        },
        progressive: 1000,
      },
    ],
  });

  // Formatter function for total chart tooltip information
  const formatter2 = useCallback((params) => {
    return (
      "<b>" +
      params.seriesName +
      "</b>" +
      "<br/>" +
      "Hour: " +
      params.name +
      "<br/>" +
      "Total Calls: " +
      params.value[2]
    );
  }, []);

  // Options configuration for the total chart
  const options2 = () => ({
    tooltip: {
      show: true,
      formatter: formatter2,
    },
    grid: {
      height: "22.5",
      top: "5",
      right: "1%", //90
      left: "1%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: hours,
      splitArea: {
        show: true,
      },
      axisLabel: {
        show: false,
        textStyle: {
          color: colorText,
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: colorText,
        },
      },
    },
    yAxis: {
      type: "category",
      data: [
        "Total".padEnd(
          languages.reduce(
            (max, str) => (str.length > max.length ? str : max),
            ""
          ).length + 17,
          " "
        ),
      ],
      position: "right",
      splitArea: {
        show: true,
      },
      axisLabel: {
        show: true,
        textStyle: {
          color: colorText,
        },
      },
      axisTick: {
        show: false,
      },
      axisLine: {
        lineStyle: {
          color: colorText,
        },
      },
    },
    visualMap: {
      min: 0,
      max: 10,
      show: false,
      inRange: {
        color: colors,
      },
    },
    series: [
      {
        name: "Total calls",
        type: "heatmap",
        data: totalData,
        label: {
          normal: {
            show: true,
          },
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowColor: "rgba(0, 0, 0, 0.5)",
          },
        },
        itemStyle: {
          normal: {
            borderColor: colorBgLayout,
            borderWidth: 4,
            borderCap: "round",
            borderRadius: 6,
          },
        },
        progressive: 1000,
      },
    ],
  });

  // Event handler for clicking on the main heatmap chart
  const handleChartClick = useCallback(
    ({ data: Data }) => {
      setSelectedHour({
        language: languages[Data[1]],
        hour: hours[Data[0]],
        data: [dataSource[Data[1]]?.hours?.find((e) => e.hour == Data[0])],
      });
    },
    [languages, dataSource, hours]
  );

  // Event handler for clicking on the total chart
  const handleTotalChartClick = useCallback(
    ({ data: Data }) => {
      setSelectedHour({
        language: undefined,
        hour: hours[Data[0]],
        data: dataSource.map((e) => {
          let hour = e.hours?.find((e) => e.hour == Data[0]);
          return {
            language: e.languageName,
            CallQualityRatingStar: hour?.CallQualityRatingStar || 0,
            CountRatingStarCalls: hour?.CountRatingStarCalls || 0,
            TotalCalls: hour?.TotalCalls || 0,
            ServiceMinutes: hour?.ServiceMinutes || 0,
            CountSuccessAudioCalls: hour?.CountSuccessAudioCalls || 0,
            CountSuccessVideoCalls: hour?.CountSuccessVideoCalls || 0,
            WaitingSeconds: hour?.WaitingSeconds || 0,
          };
        }),
      });
    },
    [languages, dataSource, hours]
  );

  // Event handlers for chart clicks
  const onEvents = {
    click: handleChartClick,
  };

  const onTotalEvents = {
    click: handleTotalChartClick,
  };

  // Return the JSX for rendering the heatmap component
  return (
    <Spin spinning={loading} key={"heatmap-spinner"}>
      <Card
        style={{
          width: "100%",
          borderRadius: 4,
          backgroundColor: colorBgLayout,
        }}
        bodyStyle={{ padding: "5" }}
      >
        <EChartsReact
          key={"main-graph"}
          onEvents={onEvents}
          style={{ height: 264, width: "100%" }}
          option={options()}
        />
        <Divider style={{ margin: 0 }} plain orientation={"center"}>
          {" "}
          Total{" "}
        </Divider>
        <EChartsReact
          key={"total-graph"}
          onEvents={onTotalEvents}
          style={{ height: 33, width: "100%" }}
          option={options2()}
        />
        <Row
          style={{ marginTop: 10, marginLeft: 8 }}
          align={"middle"}
          justify={"start"}
          gutter={10}
        >
          <Col>
            <Typography.Text strong>Less</Typography.Text>
          </Col>
          <Col
            style={{
              backgroundColor: colors[0],
              height: 15,
              width: 15,
              borderRadius: 4,
              marginRight: 3,
            }}
          ></Col>
          <Col
            style={{
              backgroundColor: colors[1],
              height: 15,
              width: 15,
              borderRadius: 4,
              marginRight: 3,
            }}
          ></Col>
          <Col
            style={{
              backgroundColor: colors[2],
              height: 15,
              width: 15,
              borderRadius: 4,
              marginRight: 3,
            }}
          ></Col>
          <Col
            style={{
              backgroundColor: colors[3],
              height: 15,
              width: 15,
              borderRadius: 4,
              marginRight: 3,
            }}
          ></Col>
          <Col
            style={{
              backgroundColor: colors[4],
              height: 15,
              width: 15,
              borderRadius: 4,
              marginRight: 3,
            }}
          ></Col>
          <Col>
            <Typography.Text strong>More</Typography.Text>
          </Col>
          <Divider type={"vertical"} />
          <Col>
            <Typography.Text strong> Calls by hour</Typography.Text>
          </Col>
          {/* <Divider type={"vertical"} />
                    <Col flex={"auto"}><Typography.Text strong italic> ⚠️ click to show minutes</Typography.Text></Col> */}
        </Row>
      </Card>
    </Spin>
  );
};

// Export the memoized HeatMap component
export default memo(HeatMap);
