import ActionType from '../action.types';

/*
    Reducer function responsible for managing invoice-related state changes based on different actions
*/

export default function invoice(state = {
        analytics: {
            paid: {
                count: 0,
                totalAmt: 0
            },
            pending: {
                count: 0,
                totalAmt: 0
            },
            unpaid: {
                count: 0,
                totalAmt: 0
            }
        },
        scatter: [], // Scatter plot data
        bar: [], // Bar chart data
        invoices: [], // Invoice data
        rawData: [] // Raw data
    }, action) {
    switch (action.type) {
        // Set invoice analytics data
        case ActionType.SET_INVOICE_ANALYTICS:
            return { ...state, analytics: action.analytics };

        // Set processed invoice data (scatter, bar, invoices, rawData)
        case ActionType.SET_COOKED_INVOICES:
            return { ...state, scatter: action.scatter, bar: action.bar, invoices: action.invoices, rawData: action.rawData };

        // Set invoices
        case ActionType.SET_INVOICES:
            return { ...state, invoices: action.invoices };

        default:
            return state;
    }
}
