import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { ssoSignin } from "../../store/actions/profile.action";
import { useSelector } from "react-redux";
import { Card, Col, Row, Spin, Typography } from "antd";
import dots from '../../images/dlogo1.png';

const SSOlogin = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.profile.loading);
  const token = useSelector((state) => state?.profile?.token);
  const navigate = useNavigate()

  useEffect(() => {
    if (params.token && !token) {
      dispatch(ssoSignin({ token: params.token }))
      .then((token) => {
        if (!token) {
          navigate("/auth/login")
        }
      }).catch((err) => {
        console.log(err);
        navigate("/auth/login")
      });
    }
  }, [params.token]);

  if (!params.token) {
    return <Navigate to={"/auth/login"} />;
  }

  return (
    <div className="login-container">
      <Row className="login-row" justify={"center"} align={"middle"}>
        <Col style={{ width: "50%", padding: 20 }}>
          <Card bordered={false}>
            <Row
              gutter={20}
              className="title-row"
              justify={"center"}
              align={"middle"}
            >
              <img src={dots} height={24} />
              <Col>VIP Customer</Col>
            </Row>
            <Row align={"middle"} gutter={10} justify={"center"}>
              <Col>
                <Typography.Title level={2}>
                  You are being redirected to VIP Customer.
                </Typography.Title>
              </Col>
            </Row>
            <Row justify={"center"} align={"middle"}>
              <Col>
                <Typography.Paragraph>
                  We are processing your request and redirecting you to the
                  vip-customer. Please be patient while we complete the
                  necessary steps.
                </Typography.Paragraph>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default SSOlogin;
