const ActionType = {
    // Login action types
    LOGIN_ERROR: "LOGIN_ERROR", // Action type for login error
    OPERATION_INPROGRESS: "OPERATION_INPROGRESS", // Action type for login operation in progress
    LOGIN_SUCCESS: "LOGIN_SUCCESS", // Action type for successful login
    LOGOUT: "LOGOUT", // Action type for logging out
    THEME: "THEME", // Action type for changing theme

    // Company Action Types
    SET_COMPANIES: "SET_COMPANIES", // Action type for setting companies
    SELECT_COMPANY: "SELECT_COMPANY", // Action type for selecting a company
    SELECT_CLIENT: "SELECT_CLIENT", // Action type for selecting a client
    SET_TIMEZONE: "SET_TIMEZONE", // Action type for setting timezone
    SET_REGION: "SET_REGION", // Action type for setting timezone
    SET_SITE: "SET_SITE", // Action type for setting timezone

    // Filter Action Types
    SET_LANGUAGE_TYPE: "SET_LANGUAGE_TYPE", // Action type for setting language type
    SET_DATE: "SET_DATE", // Action type for setting date range
    SET_DATE_RANGE: "SET_DATE_RANGE", // Action type for setting date range indicator
    SET_DATE_YEAR: "SET_DATE_YEAR", // Action type for setting year date range
    SET_INVOICE_TYPE: "SET_INVOICE_TYPE", // Action type for setting invoice type
    SET_PREV_TIME_RANGE: "SET_PREV_TIME_RANGE", // Action type for setting prev time range
    RESET_PREV_TIME_RANGE: "RESET_PREV_TIME_RANGE", // Action type for resetting to prev time range

    // Various analytics-related action types
    SET_WIDGETS: 'SET_WIDGETS', // Action type for setting widgets data
    SET_TOP5: 'SET_TOP5', // Action type for setting top 5 data
    SET_PREFERRED_VIDEO: 'SET_PREFERRED_VIDEO', // Action type for setting preferred video
    SET_AVR_CHART: 'SET_AVR_CHART', // Action type for setting average chart data
    SET_CALLS_BY_HOURS: 'SET_CALLS_BY_HOURS', // Action type for setting calls by hours data
    SET_ACTIVE_CARTS: 'SET_ACTIVE_CARTS', // Action type for setting active carts data
    SET_TOTAL_ACTIVE_CARTS: 'SET_TOTAL_ACTIVE_CARTS', // Action type for setting total active carts data
    SET_TERP_POSITION_COUNT: 'SET_TERP_POSITION_COUNT', // Action type for setting TERP position count
    SET_STAR_RATING: 'SET_STAR_RATING', // Action type for star rating
    
    // User Management
    UPDATE_USER: "UPDATE_USER", // Action type for updating user
    GET_USER_LIST: "GET_USER_LIST", // Action type for getting user list
    USER_DELETE_SUCCESS: "USER_DELETE_SUCCESS", // Action type for successful user deletion
    USER_DELETE_ERROR: "USER_DELETE_ERROR", // Action type for user deletion error

    SET_INVOICE_ANALYTICS: "SET_INVOICE_ANALYTICS", // Action type for setting invoice analytics
    SET_COOKED_INVOICES: "SET_COOKED_INVOICES", // Action type for setting cooked invoices
    SET_INVOICES: "SET_INVOICES", // Action type for setting invoices

    // Terp
    FETCH_DATA_REQUEST: 'FETCH_DATA_REQUEST', // Action type for fetching data request
    FETCH_DATA_SUCCESS: 'FETCH_DATA_SUCCESS', // Action type for fetching data success
    FETCH_DATA_FAILURE: 'FETCH_DATA_FAILURE', // Action type for fetching data failure

    // Select Columns
    CHANGE_COLUMNS: 'CHANGE_COLUMNS', // Action type for changing columns

    // Language Actions
    SET_LANGUAGE_ANALYTICS: 'SET_LANGUAGE_ANALYTICS', // Action type for setting language analytics
};

export default ActionType;
