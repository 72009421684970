import { THEME_TYPES } from '../../components/layout/theme.button';
import ActionType from '../action.types';

/*
    This reducer function manages state changes related to user profiles based on various profile action types
*/

export default function profile(state = {isLoading: false, token: null, sessionId: null, user: {}, theme: THEME_TYPES.LIGHT}, action) {
    switch (action.type) {
        // Handles login error action
        case ActionType.LOGIN_ERROR:
            return {...state, error: action.hasError, message: action.message, isLoading: action.isLoading };
        
        // Handles operation in progress action
        case ActionType.OPERATION_INPROGRESS:
            return { ...state, isLoading: action.isLoading };
        
        // Handles successful login action
        case ActionType.LOGIN_SUCCESS:
            return {...state, token: action.token, isLoading: action.isLoading, user: action.user};
        
        // Handles change theme action
        case ActionType.CHANGE_THEME:
            return {...state, theme: action.theme};
        
        // Handles logout action
        case ActionType.LOGOUT:
            return {...state, token: action.token, isLoading: action.isLoading, user: action.user};
        
        // Handles theme change action
        case ActionType.THEME:
            return {...state, theme: action.theme}
        
        // Default case returns the current state
        default:
            return state;
    }
}
