import { Axios } from "axios";
import constants from "../constants";

class DeviceService {

    /**
     * Company Services
     * @param {Axios} request 
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.API_VDMS_BASE_URL;
    }

    //get total active device for a company for a specified timeframe from vdms portal which gets its data from kandji
    async getDeviceInfo( body={}) {
        try {
            let path = '/device-informations'
            let response = await this.request.post(path, body, {filter: true});
            return response.data;

        } catch(err) {
            console.log(err);
            throw err;
        }
    }

}

export default DeviceService