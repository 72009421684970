import * as XLSX from 'xlsx';

function convertDataToArrayOfArrays(data) {
    if (data.length === 0) return [];

    const headers = Object.keys(data[0]);
    const arrayOfArrays = [headers];
    data.forEach(item => {
        const row = headers.map(header => item[header]);
        arrayOfArrays.push(row);
    });
    return arrayOfArrays;
}

function fitToColumn(arrayOfArray) {
    const colWidths = arrayOfArray[0].map((a, i) => ({
        wch: Math.max(...arrayOfArray.map(a2 => (a2[i] ? a2[i].toString().length : 0))) + 2 // Add padding
    }));
    return colWidths;
}

// Function to export data to Excel

export function exportToExcel(data, sheetNames, fileName) {
    const workbook = XLSX.utils.book_new();

    data.forEach((sheetData, index) => {
        
        const arrayOfArrays = convertDataToArrayOfArrays(sheetData);
        if (arrayOfArrays.length === 0) {
            console.error(`exportToExcel - No data to export for sheet: ${sheetNames[index]}`);
            return;
        }

        const worksheet = XLSX.utils.aoa_to_sheet(arrayOfArrays);
        worksheet['!cols'] = fitToColumn(arrayOfArrays);

        XLSX.utils.book_append_sheet(workbook, worksheet, sheetNames[index]);
    });

    XLSX.writeFile(workbook, fileName);
}

export function getSafeTitleName(title) {
    const invalidCharsRegex = /[/\\*'?[\]:]+/g;
    const maxLength = 31;
    let safeName = title.replaceAll(invalidCharsRegex, " ")
                            .replaceAll("  ", " ")
                            .trim();

    if (safeName.length > maxLength)
    {
        safeName = safeName.substring(0, maxLength);
    }
    return safeName
}