const development = {
    API_BASE_URL: 'https://dev.vip.voyceglobal.com/api/',
    AUTH_BASE_URL: 'https://dev.vip.voyceglobal.com/authapi/',
    BASE_URL: 'https://dev.vip.voyceglobal.com/',
    VDMS_BASE_URL: 'https://vdms.voyceglobal.com',
    API_VDMS_BASE_URL: 'https://vdmsapi.voyceglobal.com/'
};

const staging = {
    API_BASE_URL: 'https://api.staging.vip.voyceglobal.com',
    AUTH_BASE_URL: 'https://auth.staging.vip.voyceglobal.com',
    BASE_URL: 'https://staging.vip.voyceglobal.com',
    VDMS_BASE_URL: 'https://vdms.voyceglobal.com',
    API_VDMS_BASE_URL: 'https://vdmsapi.voyceglobal.com/'
}

const production = {
    API_BASE_URL: 'https://v2.api.voyceglobal.com',
    AUTH_BASE_URL: 'https://v2.auth.voyceglobal.com',
    BASE_URL: 'https://v2.vip.voyceglobal.com',
    VDMS_BASE_URL: 'https://vdms.voyceglobal.com',
    API_VDMS_BASE_URL: 'https://vdmsapi.voyceglobal.com/'
}

export default 
    process.env.REACT_APP_ENVIRONMENT == 'staging' ? staging : 
    process.env.REACT_APP_ENVIRONMENT == 'production' ? production : development;
