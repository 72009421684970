// Import necessary dependencies
import { Result, Button, theme } from "antd"; 
import { useNavigate } from "react-router-dom"; 

// Functional component for displaying 404 (Not Found) page
const Page404 = () => {
    // Hook to handle navigation
    const navigate = useNavigate();

    // Destructuring colorBgLayout from theme token
    const { token: { colorBgLayout } } = theme.useToken();

    // Rendering the 404 Not Found page
    return (
        <Result
            style={{ backgroundColor: colorBgLayout, height: '100vh' }}
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
                <Button onClick={() => navigate({ pathname: '/pages' })} type="primary">
                    Back Home
                </Button>
            }
        />
    )
}

export default Page404;
