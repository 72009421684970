import { message } from "antd";
import { Axios } from "axios";
import constants from "../constants";

class AuthService {
    /**
     * Authentication Services
     * @param {Axios} request
     */
    constructor(request) {
        this.request = request;
        this.request.defaults.baseURL = constants.AUTH_BASE_URL;
    }

    async login(email, password) {
        try {
            let response = await this.request.post("/users/login", {
                email,
                password,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            });
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async signup(userObj) {
        try {
            let response = await this.request.post("/users", userObj);
            console.log(response.data);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async forgotPassword(email) {
        try {
            let response = await this.request.post("/forgot-password", {
                email,
                redirectUrl: constants.BASE_URL + "/auth/reset-password",
            });
            console.log(response.data);
            return response.data?.success;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async resetPassword(values) {
        try {
            let response = await this.request.post("/reset-password", values);
            console.log(response.data);
            if (response.data && !response.data.success) {
                message.error(response.data.msg);
            }
            return response.data?.success;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async logout(sessionId) {
        try {
            let response = await this.request.delete("/sessions/" + sessionId);
            return response.data;
        } catch (err) {
            console.log(err);
        }
    }

    async deleteUser(email) {
        try {
            let response = await this.request.post("/users/delete", { email });
            return response.data?.message;
        } catch (err) {
            console.log("deleteUser: ", err);
            throw err;
        }
    }

    async getUsers(userType) {
        try {
            let response = await this.request.get("/users", {
                params: { userType },
            });
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async updateUserInfo(values) {
        try {
            console.log("updateUserInfo request: ", values);

            let response = await this.request.post("/update-user-info", values);
            console.log("updateUserInfo: ", response.data);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async createVDMSToken(route) {
        try {
            let response = await this.request.post("/users/vdms/token", {
                route,
            }, {filter: true});
            console.log(response.data);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async updateCustomColumns(data) {
        try {
            let response = await this.request.post("/users/custom-columns", data);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async getCustomColumns(userId) {
        try {
            let response = await this.request.get(`/users/custom-columns/${userId}`);
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }

    async ssoLogin(token) {
        try {
            let response = await this.request.post("/users/sso/verify", { token: token });
            return response.data;
        } catch (err) {
            console.log(err);
            throw err;
        }
    }
}

export default AuthService;
