import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setLanguageType } from "../../store/actions/filter.action";
import { useLocation } from "react-router-dom";

//langyage options to select from
const languageOptions = [
    { label: 'All', value: '-1' },
    { label: 'Spanish', value: 'sp' },
    { label: 'LOTS', value: 'LOTS' },
    { label: 'ASL', value: 'ASL' },
];

//pages on which this filter will not work
const disabled_pages = [
    '/pages/reports/language-report',
    '/pages/reports/language-report/',
    '/pages/reports/device-usage',
    '/pages/reports/device-usage/',
    '/pages/terp',
    '/pages/terp'
]

const LanguageFilter = (props) => {

    const location = useLocation();

    const dispatch = useDispatch();
    //get data from redux about current languageType
    const type = useSelector((state) => state?.filter?.languageType)

    //Display language filter
    return (
        <Radio.Group 
            buttonStyle="solid"
            options={languageOptions}
            optionType="button"
            value={type}
            onChange={(e) => dispatch(setLanguageType(e.target.value))}
            disabled={disabled_pages.includes(location.pathname)}
        />
    )
}

export default LanguageFilter