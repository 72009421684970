import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import {
    BarChartOutlined,
    DashboardOutlined,
    DollarCircleFilled,
    LogoutOutlined,
    PieChartFilled,
    UserOutlined,
    UsergroupAddOutlined,
    WalletOutlined,
    CustomerServiceFilled
} from "@ant-design/icons";
import { Avatar, Col, Dropdown, Layout, Menu, Row } from "antd";
import SmallLogo from '../../images/dlogo1.png';
import LogoWhite from '../../images/VOY-Logo-WHITE.png';
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/actions/profile.action";
import * as ROLE from "../../role";
import { THEME_TYPES } from "./theme.button";
import Service from "../../services";

const { Sider } = Layout;

const SideMenu = ({collapsed, setCollapsed}) => {
    // Retrieve necessary data from Redux store and manage component state
    const state = useSelector((state) => state);
    const location = useLocation();
    const [path, setPath] = useState(location.pathname);
    const [searchedData, setSearchedData] = useState([]);
    const dispatch = useDispatch();
    const userRole = useSelector((state) => state.profile?.user?.permissions?.role);
    const theme = useSelector((state) => state?.profile?.theme);
    const [items, setItems] = useState([
        getItem(<Link to="/pages">Dashboard</Link>, "/pages", <PieChartFilled />),
        getItem(<Link to="/pages/transactions">Transactions</Link>, "/pages/transactions", <WalletOutlined />),
        getItem(<Link to="/pages/activity-monitor">Activity Monitor</Link>, "/pages/activity-monitor", <DashboardOutlined />),
        getItem("Reports", "/pages/reports", <BarChartOutlined />, [
            getItem(<Link to="/pages/reports/audio-report">Audio vs Video Report</Link>, "/pages/reports/audio-report"),
            getItem(<Link to="/pages/reports/device-usage">Device Usage</Link>, "/pages/reports/device-usage"),
            getItem(<Link to="/pages/reports/language-report">Language Report</Link>, "/pages/reports/language-report"),
        ]),
        getItem(<Link to="/pages/invoices">Invoices</Link>, "/pages/invoices", <DollarCircleFilled/>),
        getItem(<Link to="/pages/terp">Terp</Link>, "/pages/terp", <CustomerServiceFilled />),
    ])

    // Handle useEffect for updating path on location change
    useEffect(() => {
        setPath(location.pathname);
    }, [location.pathname]);

    const dropItems = [
        {
          label: 'Logout',
          key: 'logout',
          icon: <LogoutOutlined />,
          danger: true,
        },
    ];
    
    //function to handle menu click and dispatch logout if menu clicked on is logout
    const handleMenuClick = ({ key }) => {
        switch (key) {
            case "logout":
                dispatch(logout());
                break;
            default:
                return;
        }
    };

    const menuProps = {
        items: dropItems,
        onClick: handleMenuClick,
    };

    //get item data in this format
    function getItem(label, key, icon, children) {
        return {
            key,
            icon,
            children,
            label,
        };
    }

    const handleMenuSelect = ({ key }) => {
    }

    //function to fetch data
    const fetchData = (filters) => {
        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            filters,
        };
      
        Service.Company()
          .getTerpByCompany(filter)
          .then((response) => {
            const Searchdata = response;
            setSearchedData(Searchdata);
          })
          .catch((error) => {
            console.error(error);
          });
    };

    //useEffect to update data if these things change
    useEffect(() => {
        fetchData();
    }, [
        state.filter?.languageType,
        state.filter?.date,
        state.company?.selected,
        state.company?.client,
    ]);

    useEffect(() => {

        // Menu items for all users (excluding Role Hierarchy)
        const commonMenuItems = [
            getItem(<Link to="/pages">Dashboard</Link>, "/pages", <PieChartFilled />),
            getItem(<Link to="/pages/transactions">Transactions</Link>, "/pages/transactions", <WalletOutlined />),
            getItem(<Link to="/pages/activity-monitor">Activity Monitor</Link>, "/pages/activity-monitor", <DashboardOutlined />),
            getItem("Reports", "/pages/reports", <BarChartOutlined />, [
              getItem(<Link to="/pages/reports/audio-report">Audio vs Video Report</Link>, "/pages/reports/audio-report"),
              getItem(<Link to="/pages/reports/device-usage">Device Usage</Link>, "/pages/reports/device-usage"),
              getItem(<Link to="/pages/reports/language-heatmap-report">Language Heatmap Report</Link>, "/pages/reports/language-heatmap-report"),
              getItem(<Link to="/pages/reports/language-report">Language Report</Link>, "/pages/reports/language-report"),
            ]),
        ];
      
        // Menu items for admin (including commonMenuItems and Role Hierarchy, invoices, and terp if searchedData is greater than 0)
        const adminMenuItems = [
            ...commonMenuItems,
            getItem(<Link to="/pages/invoices">Invoices</Link>, "/pages/invoices", <DollarCircleFilled />),
            searchedData.length > 0 && getItem(<Link to="/pages/terp">Interpreter</Link>, "/pages/terp", <CustomerServiceFilled />),
            getItem(<Link to="/pages/role-hierarchy">Role Hierarchy</Link>, "/pages/role-hierarchy", <UsergroupAddOutlined />),
        ].filter(Boolean); // Remove any falsy values
      
        // Menu items for super admin (including only Role Hierarchy)
        const superAdminMenuItems = [
            getItem(<Link to="/pages/role-hierarchy">Role Hierarchy</Link>, "/pages/role-hierarchy", <UsergroupAddOutlined />),
        ];
      
        // Determine the menu items based on userRole
        let menuItems = [];
      
        // Fetch menu items based on userRole
        if (userRole === ROLE.ADMIN) {
            menuItems = adminMenuItems;
        } else if (userRole === ROLE.SUPER_ADMIN) {
            menuItems = superAdminMenuItems;
        } else {
            menuItems = commonMenuItems;
        }
      
        setItems(menuItems);
    }, [userRole, searchedData]); // Add userRole and searchedData as dependencies to useEffect to recompute when the role changes or searchedData is updated
  
    return (
        <Sider
            trigger={null}
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
            className={theme === THEME_TYPES.LIGHT ? "sider-container" : "sider-container-dark"}
            theme={"light"}
            style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}
        >
            {
                !collapsed ? 
                <div style={{margin: 16, height: 26}}>
                    <Row justify={"center"} align={"middle"}>
                        <img style={{ marginTop: 7}} height={18} src={LogoWhite} />
                    </Row>
                </div> : 
                <div style={{margin: 16, height: 26}}>
                    <Row justify={"center"} align={"middle"}>
                        <img style={{filter: 'drop-shadow(0 0 0.65rem #203870)'}} height={26} src={SmallLogo} />
                    </Row>
                </div>
            }
            <Menu
                selectedKeys={[path]}
                defaultSelectedKeys={[path]}
                mode="inline"
                items={items}
                onClick={handleMenuSelect}
            />
            <Row style={{position: "fixed", bottom: 20, left: 20, }} justify={"center"} align={"bottom"} >
                <Col>
                    <Dropdown
                        menu={menuProps}
                        trigger={["click"]}
                        placement="topRight"
                    >
                    <div onClick={e => e.preventDefault()} style={{cursor: 'pointer'}}>
                        <Avatar size="large" icon={<UserOutlined />} />
                    </div>
                    </Dropdown>
                </Col>
            </Row>
            
        </Sider>
    )
}

export default SideMenu;