import moment from 'moment';
import { InvoiceEnum } from '../../invoice.enum';
import ActionType from '../action.types';

/*
    Reducer function responsible for managing the state related to different filter actions
*/

export default function filter(state = {
    date: {
        maxDate: moment().endOf("day").toDate(), // End of the current day
        minDate: moment().startOf("day").toDate(), // Start of the current day
    },
    dateRange: "Today",
    fetchInterval: 12000, // Default fetch interval
    languageType: '-1', // Default language type
    invoiceType: InvoiceEnum.ALL, // Default invoice type
    statuses: ["New", "In Service", "Pending"], // Default statuses
    year: {
        maxDate: moment().endOf("year").endOf("month").endOf("days").utc().toDate(), // End of the year
        minDate: moment().startOf("year").startOf("month").add("days", 1).utc().toDate(), // Start of the year
    },
    prevSelectedTimeRange: null, 
}, action) {
    switch (action.type) {
        // Update date and fetch interval
        case ActionType.SET_DATE:
            return {
                ...state,
                date: {
                    maxDate: action.date.maxDate,
                    minDate: action.date.minDate
                },
                fetchInterval: action.date.fetchInterval
            };
        case ActionType.SET_DATE_RANGE:
            return {
                ...state,
                dateRange: action.dateRange
            }
        // Set year filter
        case ActionType.SET_DATE_YEAR:
            return { ...state, year: action.year };

        // Set invoice type filter
        case ActionType.SET_INVOICE_TYPE:
            return { ...state, invoiceType: action.invoiceType };

        //set prev time range
        case ActionType.SET_PREV_TIME_RANGE:
            return {
            ...state,
            prevSelectedTimeRange: action.timeRange
        };
        
        //reset to prev time range
        case ActionType.RESET_PREV_TIME_RANGE:
            return {
            ...state,
            prevSelectedTimeRange: null
        };
        // Set language type filter
        case ActionType.SET_LANGUAGE_TYPE:
            return { ...state, languageType: action.languageType };

        // Reset filter state on logout
        case ActionType.LOGOUT:
            return {
                date: {
                    maxDate: moment().endOf("day").toDate(),
                    minDate: moment().startOf("day").toDate(),
                },
                languageType: '-1',
                invoiceType: InvoiceEnum.ALL,
                fetchInterval: 12000,
                year: {
                    maxDate: moment('2023').endOf("year").endOf("month").endOf("days").utc().toDate(),
                    minDate: moment('2023').startOf("year").startOf("month").add("days", 1).utc().toDate(),
                }
            };

        default:
            return state;
    }
}
