import { applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk'; // Middleware for handling async actions
import rootReducer from './reducers'; // Root reducer combining all reducers

/**
 * Initializes the Redux store with initial state and middleware.
 * @param {Object} initialState - Initial state for the store
 * @returns {Object} Configured Redux store
 */
export default function initStore(initialState) {
    // Apply middleware to handle asynchronous actions
    const enhancer = applyMiddleware(thunk);

    return configureStore(
        {
            reducer: rootReducer, // Combined root reducer
            preloadedState: initialState // Initial state of the store
        },
        initialState, // Initial state passed while creating the store
        enhancer // Middleware applied to the store
    );
};
