import ActionType from '../action.types';
import Service from '../../services';
import { message } from 'antd';
import { store } from '../..';

//action to set language analytics data for different types of chart and use
export function setLanguageAnalytics(res) {

    const volume = [];
    const distribution = {
        legends: [],
        seriesData: []
    }
    const avgTime = {
        legends: [],
        seriesData: []
    }
    const interpretationType = {
        audio: {
            legends: [],
            seriesData: []
        },
        video: {
            legends: [],
            seriesData: []
        }
    }
    const videoType = {
        video: {
            legends: [],
            seriesData: []
        }
    }
    
    const audioType = {
        audio: {
            legends: [],
            seriesData: []
        }
    }

    res.forEach((element) => {
        volume.push({
            name: element.TargetLanguage,
            value: element.TotalCalls
        });
        if (element.ServiceMinutes > 0) {
            distribution.seriesData.push(element.ServiceMinutes);
            distribution.legends.push(element.TargetLanguage);
    
            interpretationType.audio.seriesData.push(element.CountAudioMinute);
            interpretationType.audio.legends.push(element.TargetLanguage);
    
            interpretationType.video.seriesData.push(element.CountVideoMinute);
            interpretationType.video.legends.push(element.TargetLanguage);
    
            avgTime.seriesData.push(Math.round(element.ServiceMinutes/element.TotalCalls));
            avgTime.legends.push(element.TargetLanguage);
    
            audioType.audio.seriesData.push(element.CountAudioMinute);
            audioType.audio.legends.push(element.TargetLanguage);
    
            videoType.video.seriesData.push(element.CountVideoMinute);
            videoType.video.legends.push(element.TargetLanguage);
        }
    });

    return {
        type: ActionType.SET_LANGUAGE_ANALYTICS,
        volume,
        distribution,
        avgTime,
        interpretationType,
        audioType,
        videoType,
        rawData: res,
    }
}

/**
 * action to get language details
 * @returns 
 */
export function getLanguagesDetails(hideLoader) {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getLanguagesTotal(filter, hideLoader);
            console.log(data)
            dispatch(setLanguageAnalytics(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}

//action to set languag details based on timezone
export function getLanguagesDetailsTz(hideLoader) {
    return async (dispatch) => {
        try {
            let state = store.getState();
            let filter = {
                start: state.filter?.date?.minDate,
                end: state.filter?.date?.maxDate,
                languageType: state.filter.languageType
            }
            let data = await Service.Analytics().getLanguagesTotalTz(filter, hideLoader);
            console.log(data)
            dispatch(setLanguageAnalytics(data));
        } catch(err) {
            let msg = err?.response?.data?.error?.message
            msg && message.error(msg);
        }
    };
}
