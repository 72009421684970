// Import necessary dependencies
import { Card, Spin, theme } from "antd";
import ReactECharts from "echarts-for-react";
import * as echarts from 'echarts/core';
import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTop5Languages } from "../../store/actions/analytics.action";
import Formatter from "../../utils/Formatter";
import useIntervalAsync from "../../components/hooks/useInterval";

// Define the TopLanguagesChart component
const TopLanguagesChart = () => {
    // Extract color tokens from the theme
    const { token: {
        colorText,
        colorBgLayout
    } } = theme.useToken();

    // Extract necessary Redux state variables
    const [loading, setLoading] = useState(false);
    const state = useSelector((s) => s);
    const dispatch = useDispatch();

    // Function to fetch top 10 languages asynchronously
    const fetchLanguagesCB = useCallback(async () => {
        dispatch(getTop5Languages(true))
            .then(() => setLoading(false)) // Set loading to false on successful data fetch
            .catch(() => setLoading(false)); // Set loading to false if fetching encounters an error
    }, []);
    
    const fetchLanguages = useIntervalAsync(fetchLanguagesCB, state?.filter?.fetchInterval);

    useEffect(() => {
      // Trigger data fetching when certain filter criteria changes
      setLoading(true); // Set loading to true on fetching start
      fetchLanguages(); // Fetch top 10 languages data
    }, [
      state.filter?.date,
      state.company?.client,
      state.company?.region,
      state.company?.site,
    ]);

    // Extract data for top languages from Redux state
    const data = useSelector((state) => state?.analytics.top5Languages);

    // Prepare top 5 languages data
    const topLanguages = data?.legends?.slice(0, 5).map(language => 
        language === "American Sign Language (ASL)" ? "ASL" : language
    );
    
    // Function to define ECharts options for the bar chart
    const getOption = () => {
        // Define necessary variables for the chart
        let graphics = echarts.graphic;
        let colors = [
            '#54c9e8',
            '#203870',
            '#ff876f',
            '#61636f'
        ]
        return {
            // Chart tooltip configuration
            tooltip: {
                trigger: 'axis',
                position: function (pt) {
                  return [pt[0] - 50, '10%'];
                },
                axisPointer: {
                  type: 'shadow',
                },
                formatter: ([{data,axisValueLabel}]) => {
                  return `${axisValueLabel}</br>${Formatter.numberWithCommas(Number(data.value))} Minutes`
                }
            },
            // Grid layout configuration
            grid: {
                top: 20,
                left: 10,
                right: 10,
                bottom: 2,
                containLabel: true,
            },
            // X-axis configuration
            xAxis: [
                {
                    type: "category",
                    data: topLanguages,
                    
                    axisTick: {
                        alignWithLabel: true,
                    },
                    axisLabel: {
                        hideOverlap: true,
                        interval: 0,
                        rotate: -15 //If the label names are too long we can rotate the label with this.
                    },
                    axisLine: {
                        lineStyle: {
                          color: colorText, 
                        },
                    },
                },
            ],
            // Y-axis configuration
            yAxis: [
                {
                    type: "value",
                    axisLine: {
                        lineStyle: {
                            color: colorText,
                        },
                    },
                    axisLabel: {
                        textStyle: {
                            color: colorText,
                        },
                    },
                },
            ],
            // Series data for the chart
            series: [
                {
                    name: "count",
                    type: "bar",
                    barWidth: "70%",
                      data: data?.seriesData?.map((val, idx) => {
                        return {
                            value: val,
                            itemStyle: {
                                color: colors[idx % colors.length],
                            },
                        };
                    }),
                }
            ],
        };
    };


    return (
       // Render a spinning Card with ReactECharts displaying the top 5 languages bar chart
       <Spin spinning={loading} >
        <Card style={{height: 373, borderRadius: 4, backgroundColor: colorBgLayout}}>
            <ReactECharts option={getOption()} style={{height: 290}} />
            <p style={{textAlign: 'center'}} >Top 5 Languages</p>
        </Card>
       </Spin>
    )
}

// Export the TopLanguagesChart component and its memoized version
export default TopLanguagesChart;
export const MemoizedTopLanguagesChart = memo(TopLanguagesChart);
