import { Button, Result, Row } from "antd";
import React from "react";

class ErrorBoundary extends React.Component {
    constructor(props) {
		super(props);
		this.state = { hasError: false, errorInfo: undefined };
    }
  
    static getDerivedStateFromError(error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
		// You can also log the error to an error reporting service
		this.setState({errorInfo});
    }
  
    render() {
        if (this.state.hasError) {
            return (
                <Result
                    status="500"
                    title="500"
                    subTitle="Sorry, something went wrong."
                    extra={
                        <>
                            <Row style={{textOverflow: 'ellipsis', wordBreak: 'break-all'}}>
                                {JSON.stringify(this.state.errorInfo)}
                            </Row>
                            <br/>
                            <Button onClick={() => this.props.navigation.navigate({pathname: '/pages'})} type="primary">Back Home</Button>
                        </>
                    }
                />
            )
        }
  
      	return this.props.children; 
    }
}

export default ErrorBoundary