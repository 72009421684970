import { Table } from "antd";
import _ from "lodash";
import { useSelector } from "react-redux";

const CLEAR_COLUMNS = ["1506"];

const IntakeQuestions = ({ record }) => {
  const selectedCompany = useSelector((state) =>
    state?.profile?.user?.permissions?.company?.toString()
  );

  const dataSource = CLEAR_COLUMNS.includes(selectedCompany)
    ? (record?.intakeQuestions && record?.intakeQuestions[0])
    : (record?.intakeQuestions?.reduce((acc, obj) => {
        acc[obj.Name] = obj.Value;
        return acc;
      }, {}));

  const columns = Object.keys(dataSource).map((e) => ({
    title: _.startCase(_.toLower(e)),
    dataIndex: e,
  }));

  return (
    record?.intakeQuestions &&
    record?.intakeQuestions.length > 0 && (
      <Table
        title={() => <b>Intake Questions</b>}
        dataSource={[dataSource]}
        columns={columns}
        size="small"
        pagination={false}
      ></Table>
    )
  );
};

export default IntakeQuestions;
