import { Button, Card, Col, Form, Input, Row } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { signin } from "../../store/actions/profile.action";
import logo from "../../images/dlogo1.png";

// Component for the Login page
const Login = () => {
    // State and refs initialization
    const [width, setWidth] = useState(700); // State to manage width
    const elementRef = useRef(null); // Reference for the element
    const dispatch = useDispatch(); // Redux dispatch function

    // Function to update width state based on elementRef's client width
    useEffect(() => {
        setWidth(elementRef.current.clientWidth); // Update width state on component load or resize
    }, [elementRef.current?.clientWidth]); // Listen for changes in elementRef's clientWidth

    return (
        // Login container
        <div className="login-container">
            {/* Row for login content */}
            <Row className="login-row" justify={"center"} align={"middle"}>
                <Col style={{width: 700, padding: 20}}>
                    {/* Card for login form */}
                    <Card ref={elementRef} bordered={false}>
                        {/* Row for the title */}
                        <Row gutter={20} className="title-row" justify={"center"} align={"middle"} >
                            {/* Logo */}
                            <img src={logo} alt="Logo" height={24} />

                            <Col>VIP Customer</Col>
                        </Row>
                        <br/>
                        {/* Row for login form */}
                        <Row justify={"center"} align={"middle"}>
                            <Col flex={width < 590 ? "auto" : "45%"}>
                                {/* Form for email and password */}
                                <Form onFinish={(creds) => dispatch(signin({...creds}))}>
                                    {/* Email input */}
                                    <Form.Item name={"email"} rules={[{ required: true }]}>
                                        <Input placeholder="Email" />
                                    </Form.Item>
                                    {/* Password input */}
                                    <Form.Item name={"password"} rules={[{ required: true, message: 'Please input your password!' }]}>
                                        <Input.Password placeholder="Password" />
                                    </Form.Item>
                                    {/* Button for sign in */}
                                    <Row justify={"center"}>
                                        <Form.Item>
                                            <Button htmlType="submit" style={{width: 180}} shape="round" type="primary">Sign In</Button>
                                        </Form.Item>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                        <br/>
                        {/* Forgot password link */}
                        <p style={{"textAlign": "center", color: "grey"}}>
                            Don't remember your password? <i><Link to="/auth/forgot-password" >forgot password</Link></i>
                        </p>
                    </Card>
                </Col>
            </Row>
        </div>
    );
}

export default Login;
