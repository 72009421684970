const ROLE = [
    { value: "Super Admin", label: "Super Admin" },
    { value: "Admin", label: "Admin" },
    { value: "User", label: "User" },
];
const USER = "User";
const SUPER_ADMIN = "Super Admin";
const ADMIN = "Admin";

const PROHIBITED_ACCESS = {
    SuperAdmin: [],
    Admin: [],
    User: ["/pages/role-hierarchy"],
};

export default ROLE;
export { USER, SUPER_ADMIN, ADMIN, PROHIBITED_ACCESS };
