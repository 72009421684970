import { useEffect, useState } from "react";
import { Table, Row, Col, Spin, Button } from "antd";
import "./deviceUsage.report.scss";
import { useSelector, useDispatch } from "react-redux";
import GetColumnSearchProps from "../../../components/table/search";
import Formatter from "../../../utils/Formatter";
import DropDown from "../../../components/dropdown";
import exportAsCSV from "../../../services/exportToCVS.service";
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useNavigate} from "react-router-dom";
import { fetchDeviceInfo } from "../../../store/actions/device.action";
import { MemoizedSiteMinuteChart } from "./minutes.bar";
import { setLanguageType } from '../../../store/actions/filter.action';
import { exportToExcel, getSafeTitleName } from "../../../services/exportToExcel";
import moment from "moment";


function formatDataFoPieChartsDevices(d=[], slice) {
    const legends = [],
        transactions = [],
        minutes = [],
        rawData = [];
    let data = JSON.parse(JSON.stringify(d));
    data.forEach(clientSite => clientSite.SerialNumbers.forEach(serialNumber => rawData.push(serialNumber)));
    rawData
    .sort((a, b) => b.ServiceMinutes - a.ServiceMinutes)
    .forEach((serialNumber) => {
        legends.push(serialNumber.IOSSerialNumber);
        transactions.push(serialNumber.TotalTransactions);
        minutes.push(serialNumber.ServiceMinutes);
    })
    return slice ? {
        legends: legends.slice(0, slice), 
        minutes: minutes.slice(0, slice), 
        transactions: transactions.slice(0, slice)
    } : { legends, minutes, transactions };
}


const DeviceSiteUsage = () => {
    // Necessary hooks and states
    const dispatch = useDispatch();
    const state = useSelector((state) => state);
    const [usage, setUsage] = useState([]);
    const [loading, setLoading] = useState(false);
    const [downloadType, setDownloadType] = useState("EXCEL");
    const navigate = useNavigate()

    //fetch data on component mount
    useEffect(() => {
        setLoading(true)
        dispatch(fetchDeviceInfo())
        .then((data=[]) => setUsage(data.sort((a, b) => b.ServiceMinutes - a.ServiceMinutes)))
        .then(() => setLoading(false))
    }, [
        state.filter?.languageType, 
        state.filter?.date, 
        state.company?.client,
        state?.profile?.user?.permissions?.company
    ]);

    // Select Download Type
    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDF();
        } else if (downloadType === "EXCEL") {
            // downloadCSVFunction();
            downloadEXCELFunction();
        }
    };

    //options in download dropdown
    const downloadDropdownType = [
        {
            value: "PDF",
            label: "PDF",
        },
        {
            value: "EXCEL",
            label: "EXCEL",
        },
    ];

    // PDF download functionality
    const downloadPDF = () => {
        let downloadArray = [];
        for (let list of usage) {
        let newList = {
            "Client Site": list?.ClientSite,
            "Minutes Used": Formatter.numberWithCommas(list?.ServiceMinutes),
            "Number of Transactions": list.TotalTransactions,
            "ISOSerialNumber": "Total",
        };
        downloadArray.push(newList);

        for (let serial of list?.SerialNumbers || []) {
            let serialInfo = {
                "Client Site": serial.ClientSite,
                "Minutes Used": Formatter.numberWithCommas(serial.ServiceMinutes),
                "Number of Transactions": serial.TotalTransactions,
                "IOSSerialNumber": serial.IOSSerialNumber,
            };
            downloadArray.push(serialInfo);
        }
        }

        const headerList = [
            "Client Site",
            "Minutes Used",
            "Number of Transactions",
            "ISOSerialNumber",
        ];
        const bodyList = downloadArray.map((item) => Object.values(item).map((value) => String(value)));
        const doc = new jsPDF("landscape", "px", "a4");
        doc.setFontSize(18);
        doc.text("Device Usage Report", 30, 20);
        doc.setFontSize(12);
        doc?.autoTable({
            head: [headerList.map((header) => [header])],
            body: bodyList,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33],
            },
            startY: 50,
        });
        doc.save(new Date().toISOString().split("T")[0]);
    };

    const downloadCSVFunction = () => {
        let downloadArray = [];
        for (let list of usage) {
            let generalInfo = {
                "Client Site": list?.ClientSite,
                "Minutes Used": Formatter.numberWithCommas(list?.ServiceMinutes),
                "Number of Transactions": list.TotalTransactions,
                "IOSSerialNumber": "Total",
            };
            downloadArray.push(generalInfo);
    
            for (let serial of list?.SerialNumbers) {
                let serialInfo = {
                    "Client Site": serial.ClientSite,
                    "Minutes Used": Formatter.numberWithCommas(serial.ServiceMinutes),
                    "Number of Transactions": serial.TotalTransactions,
                    "IOSSerialNumber": serial.IOSSerialNumber,
                };
                downloadArray.push(serialInfo);
            }
        }
    
        exportAsCSV(
            downloadArray,
            "Device Usage Report",
            [ 
                "Client Site",
                "Minutes Used",
                "Number of Transactions",
                "IOSSerialNumber"
            ],
            "Device Usage Report"
        );
    };  
  

    const downloadEXCELFunction = () => {
        let overviewArray = []
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const downloadDateRange = state?.filter?.dateRange;
        const filename = `Device_Report_${downloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}.xlsx`;
    
        const sheetData = [];
        const sheetNames = [];
    
        console.log("Usage is: ", usage);
    
        usage.forEach(list => {
            const clientSheetData = [];
    
            // Add client overview to the overview sheet
            overviewArray.push({"Client Site": list.ClientSite,  "Minutes Used": Formatter.numberWithCommas(list.ServiceMinutes), "Number of Transactions": list.TotalTransactions});
    
            // Add each serial number information
            list.SerialNumbers.forEach(serial => {
                const serialInfo = {
                    "Client Site": serial.ClientSite,
                    "Minutes Used": Formatter.numberWithCommas(serial.ServiceMinutes),
                    "Number of Transactions": serial.TotalTransactions,
                    "IOSSerialNumber": serial.IOSSerialNumber
                };
                clientSheetData.push(serialInfo);
            });
    
            // Add each client's data to the sheetData array
            sheetData.push(clientSheetData);
            sheetNames.push(getSafeTitleName(list.ClientSite));
        });
    
        // Add the overview sheet data
        sheetData.unshift(overviewArray);
        sheetNames.unshift("Overview");
    
        // Export to Excel
        exportToExcel(sheetData, sheetNames, filename);
    };
        
    //function to handle navigation to transaction page based on client site
    const handleNavigationClick = (ClientSite) => {
        dispatch(setLanguageType("-1"));
        navigate(`/pages/transactions?dataIdx0=Site&status0=${ClientSite}`)
    }
   
    //function to handle navigation to transaction page based on IOSSerialNumber of a device
    const handleDeviceNavigationClick = (IOSSerialNumber) => {
        dispatch(setLanguageType("-1"));
        navigate(`/pages/transactions?dataIdx0=IOSSerialNumber&status0=${IOSSerialNumber}`)
    }

    //define expanded row columns
    const expandedRowRender = (records) => {
        const columns = [
            {
                title: "Serial Number",
                dataIndex: "IOSSerialNumber",
                key: "IOSSerialNumber",
                sorter: (a, b) => a.IOSSerialNumber.localeCompare(b.IOSSerialNumber),
          
            },
            {
                title: "Minutes Used",
                dataIndex: "ServiceMinutes",
                key: "ServiceMinutes",
                sorter: (a, b) => a.ServiceMinutes - b.ServiceMinutes,
                defaultSortOrder: 'descend'
            
            },
            {
                title: "Device Owner",
                dataIndex: "deviceType",
                key: "deviceType",
                render: () => "Customer Owned"
            },
            {
                title: "Number Of Transactions",
                dataIndex: "TotalTransactions",
                key: "TotalTransactions",
                sorter: (a, b) => a.TotalTransactions - b.TotalTransactions,
                render: (text, record) => (
                    <a
                        onClick={() => handleDeviceNavigationClick(record.IOSSerialNumber)}
                    >
                        {text}
                    </a>
                ),
            },
        ];
    
        return (
            <>
                <Table
                    size="middle"
                    title={() => <>Devices</>}
                    footer={() => <></>}
                    columns={columns} 
                    dataSource={records?.SerialNumbers || []} 
                    pagination={false} 
                    rowKey={(record) => record?.IOSSerialNumber}
                />
            </>
        )
        ;
    };

    // Define the columns for the table
    const columns = [
        {
            title: "Client Site",
            dataIndex: "ClientSite",
            key: "ClientSite",
            sorter: (a, b) => a.ClientSite.localeCompare(b.ClientSite),
            ...GetColumnSearchProps({dataIndex: 'ClientSite', isDate:false}),
        },
        {
            title: "Minutes Used",
            dataIndex: "ServiceMinutes",
            key: "ServiceMinutes",
            sorter: (a, b) => a.ServiceMinutes - b.ServiceMinutes,
            defaultSortOrder: 'descend'
        
        },
        {
            title: "Number Of Transactions",
            dataIndex: "TotalTransactions",
            key: "TotalTransactions",
            sorter: (a, b) => a.TotalTransactions - b.TotalTransactions,
            render: (text, record) => (
                <a
                    onClick={() => handleNavigationClick(record.ClientSite)}
                >
                    {text}
                </a>
            ),
        }
    ];

    return (
    <Row>
        <Col className='language-col' flex={"auto"}>
        {loading ? ( // Check if loading state is true
            <div className="loading-container">
            <Spin size="large" />
            <span className="loading-text">Cooking up your data...</span>
            </div> // Show the loader while data is being fetched
        ) : (
            <>
            <Row wrap={false} gutter={20}>
                <Col flex={"50%"}>
                    <MemoizedSiteMinuteChart
                        {...{
                            legends: usage?.map(e => e.ClientSite),
                            transactions: usage?.map(e => e.TotalTransactions),
                            minutes: usage?.map(e => e.ServiceMinutes),
                            loading
                        }}
                        heroTitle={"Usage by Client Site"}
                    />
                </Col>
                <Col flex={"50%"}>
                    <MemoizedSiteMinuteChart
                        {...formatDataFoPieChartsDevices(usage, 10)}
                        heroTitle={"Usage by Top Devices"}
                        loading={loading}
                    />
                </Col>
            </Row>
            <br/>
            <Row>
                <Col flex={"auto"}>
                <Table
                    size="middle"
                    title={() => (
                        <Row
                            gutter={[8, 8]}
                            justify="end"
                            align="middle"
                        >   
                            <Col>
                                <DropDown
                                    defaultValue={downloadType}
                                    dropdownOptions={
                                        downloadDropdownType
                                    }
                                    handleTypeSelect={(
                                        downloadType
                                    ) =>
                                        setDownloadType(
                                            downloadType
                                        )
                                    }
                                />
                            </Col>
                            <Col>
                                <Button
                                    loading={loading}
                                    type="primary"
                                    onClick={downloadFile}
                                >
                                    Download
                                </Button>
                            </Col>
                        </Row>
                    )}
                    footer={() => <></>}
                    sticky
                    dataSource={usage}
                    columns={columns}
                    pagination={false}
                    rowKey={(record) => record?.ClientSite}
                    expandable={{
                        expandedRowRender,
                        defaultExpandAllRows: false
                    }}
                />
                </Col>
            </Row>
            </>
        )}             
        </Col>
    </Row>
)
};

export default DeviceSiteUsage; // Export the DeviceSiteUsage component
