import { theme } from "antd";

//dark theme configuration
export const DarkTheme = {
    algorithm: theme.darkAlgorithm,
    token: {
        colorPrimary: "#203870",
        fontFamily: "Montserrat, sans-serif"

    }
}

//light theme configuration
export const LightTheme = {
    algorithm: theme.defaultAlgorithm,
    token: {
        colorPrimary: "#203870",
        fontFamily: "Montserrat, sans-serif"
    },
    "components": {
        "Card": {
          "colorBorderSecondary": "rgba(0, 0, 0, 0.25)"
        }
    }
}
/**
 * maroon: #590232
 * navy-blue: #034C8C
 * red: #BF0436
 * brown: #BF0436
 * black: #0D0000
 * purple: #4C55C8
 */

/**
 * #226885
 * #0f9191
 * #61636f
 */