import ActionType from "../action.types"; // Importing action types
import Service from "../../services"; // Importing service for user-related functionalities
import jwtDecode from "jwt-decode"; // Library to decode JWT tokens
import { message } from "antd"; // Ant Design message component for displaying notifications
import { store } from "../.."; // Importing Redux store

// Action creator for setting user list
export function setUserList(userList) {
    return {
        type: ActionType.GET_USER_LIST,
        userList: userList,
    };
}

// Action creators for successful and failed user deletion
export function deleteSuccess(bool, message) {
    return {
        type: ActionType.USER_DELETE_SUCCESS,
        hasError: bool,
        message,
        isLoading: false,
    };
}

export function deleteError(bool, message) {
    return {
        type: ActionType.USER_DELETE_ERROR,
        hasError: bool,
        message,
        isLoading: false,
    };
}

// Action creator for updating user information
export function updateUser(userList) {
    return {
        type: ActionType.UPDATE_USER,
        userList,
    };
}

/**
 * Signup action for creating a user.
 * @param {*} userObj
 * @returns 
 */
export function addUser(userObj) {
    return async (dispatch) => {
        try {
            // Prepare user object for signup
            userObj.designation = "portal-signin";
            userObj.name = `${userObj.firstname} ${userObj.lastname}`;
            userObj.comment = "Create using VIP Admin portal";
            delete userObj.firstname;
            delete userObj.lastname;
            delete userObj.confirm;
            
            // Extract required fields for user creation
            const newUserObj = {
                ...userObj,
                company: userObj.company,
                clients: userObj.clients,
                selectedCompanies: userObj.selectedCompanyObjects,
            };
            delete newUserObj.selectedCompanyObjects;

            // Call service to sign up the new user
            let data = await Service.Auth().signup(newUserObj);

            // Handle error response from the API
            if (data.statusCode === 400) {
                message.error(data.message);
                return;
            }

            // Update user list in Redux state upon successful addition
            let state = store.getState();
            let users = JSON.parse(JSON.stringify(state.userManagement.userList));
            dispatch(setUserList([...users, newUserObj]));

            return message.success(data.message);
        } catch (err) {
            let msg =
                err?.response?.data?.message ||
                err?.response?.data?.error?.message;
            msg && message.error(msg);
        }
    };
}

// Action creator to fetch user list based on role
export function getUserList(role) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().getUsers(role);
            dispatch(setUserList(data));
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
        }
    };
}

// Action creator to update user information
export function updateUserInfo(values) {
    return async (dispatch) => {
        try {
            let data = await Service.Auth().updateUserInfo(values);
            if (data) {
                // Update user list in Redux state upon successful update
                let state = store.getState();
                let users = JSON.parse(JSON.stringify(state.userManagement.userList));
                let updatedList = users.map((user) => {
                    if (user.email === values.email) {
                        if (values.firstname && values.lastname) {
                            values.name = values.firstname + " " + values.lastname;
                        }
                        const updatedUser = { ...user, ...values, selectedCompanies: values.selectedCompanyObjects, company: values.company, clients: values.clients };
                        return updatedUser;
                    }
                    return user;
                });
                dispatch(updateUser(updatedList));
            }
            return data;
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
        }
    };
}

// Action creator to delete a user
export function deleteUser(email) {
    return async (dispatch) => {
        try {
            let message = await Service.Auth().deleteUser(email);
            let state = store.getState();
            let users = JSON.parse(JSON.stringify(state.userManagement.userList));
            dispatch(setUserList(users.filter((user) => user.email !== email)));
        } catch (err) {
            let msg = err?.response?.data?.error?.message;
            msg && message.error(msg);
            dispatch(deleteError(true, msg));
        }
    };
}
