import { Row, Col, theme, Table, Button, Input, Space, TimePicker, Spin } from "antd";
import { useCallback, useEffect, useRef, useState, useReducer } from "react";
import { SearchOutlined } from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { useSelector, useDispatch } from "react-redux";
import { updateColumns } from "../../store/actions/selectedColumns";
import "./transaction.scss";
import Service from "../../services";
import moment from "moment";
import DropDown from "../../components/dropdown";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";
import DragnDrop from "./dragndrop";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import useIntervalAsync from "../../components/hooks/useInterval";
import Formatter from "../../utils/Formatter";
import "jspdf-autotable";
import InfiniteScroll from 'react-infinite-scroll-component'
import CustomFilter from "./customFilter";
import IntakeQuestions from "./intakeQueations";
import _ from 'lodash'

dayjs.extend(utc);
dayjs.extend(timezone);

// Initialize constants and set default configurations
dayjs.tz.setDefault("Etc/UTC");

const SITE_COLUMN = ["1598", "1506", "1899", "9001", "2102"]
const DEPARTMENT_COLUMN = ["1899"]

const GetColumnSearchProps = (dataIndex, updateFilters) => {
    return ({
        filterDropdown: (props) => <CustomFilter 
            dataIndex={dataIndex.dataIndex} 
            showTimeRange={dataIndex.showTimeRange} 
            updateFilters={updateFilters}
            {...props} 
            />,
        filterIcon: (filtered) => (
            <SearchOutlined
              style={{
                color: filtered ? '#1890ff' : undefined,
              }}
            />
          ),
    })
};

//option in download dropdown
const downloadDropdownType = [
    {
        value: "PDF",
        label: "PDF",
    },
    {
        value: "EXCEL",
        label: "EXCEL",
    },
];

// Define a function to render additional details in an expanded row
const expandedRowRender = (record) => {
    let data = [
        {
            InterpreterFirstName: record?.InterpreterFirstName,
            InterpreterLastName: record?.InterpreterLastName,
            ServiceStartTime: record?.ServiceStartTime,
            InterpreterId: record?.InterpreterId,
            Timezone: record?.Timezone,
        },
    ];
    // Define expander row columns
    const columns = [
        {
            title: "Interpreter ID",
            dataIndex: "InterpreterId",
            align: "center",
            render: (text) => <div> {text || "-"} </div>,
        },
        {
            title: "Interpreter Name",
            dataIndex: "InterpreterFirstName",
            align: "center",
            width: "30%",
            render: (first, row) => {
                const firstName = first || "";
                const lastName = row?.InterpreterLastName || "";
                return `${firstName} ${lastName}`.trim() || '-';
            },
        },
        {
            title: "Service Start Time",
            dataIndex: "ServiceStartTime",
            render: (text, row) =>
                text ? Formatter.createLocalDateTime(text, row.Timezone)?.format("HH:mm:ss") : "-",
            width: "35%",
        },
    ];
    return (
        <Col>
        <Table
            key={record?.Id + "_sub"}
            title={() => <b>Call Accepted By</b>}
            size="small"
            className="expanded-table"
            columns={columns}
            dataSource={data}
            width="20px"
            pagination={false}
            rowClassName={(record, index) =>
                record?.AcceptTime ? "accept-row" : "declined-row"
            }
        />
        <IntakeQuestions record={record} key={record?.Id + "_sub_sub"} />
        </Col>
    );
};
//main component
const Transactions = () => {
    // Define state variables and hooks...
    const location = useLocation();
    const dispatch = useDispatch();
    //handle filter for redirection from dashboard to transactiion page
    const getDashboardFilters = () => {
        const searchParams = new URLSearchParams(location.search);
        let filters = {};

        for (let i = 0; ; i++) {
            const dataIdx = searchParams.get(`dataIdx${i}`);
            const status = searchParams.get(`status${i}`);

            if (dataIdx && status) {
                filters[dataIdx] = status;
            } else {
                break;
            }
        }

        if (filters?.ExtractedTime) {
            let hours = filters.ExtractedTime.split("-");
            filters.ExtractedTime = [
                dayjs().hour(hours[0]).minute(0).second(0).format("YYYY-MM-DDTHH:mm:ss"),
                dayjs().hour(hours[1]).minute(0).second(0).format("YYYY-MM-DDTHH:mm:ss"),
            ]
        }

        return filters;
    };
    const dashboardFilters = getDashboardFilters();
    //geth theme token
    const {
        token: { colorBgLayout },
    } = theme.useToken();
    //get data from redux store
    const state = useSelector((state) => state);
    const columnState = useSelector((state) => state.selectedColumns);
    const userId = useSelector((state) => state?.profile?.user?.id);
    const selectedCompany = useSelector(
        (state) => state?.profile?.user?.permissions?.company?.toString()
    );
    const { selectedColumns, unselectedColumns } = columnState;
    const [downloadType, setDownloadType] = useState("EXCEL");
    const [tableLoading, setTableLoading] = useState(false);
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 100,
        },
    });
    const [data, setData] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const [searchText, setSearchText] = useState("");
    const [searchedColumn, setSearchedColumn] = useState("");
    const [filterSearch, setFilterSearch] = useState(dashboardFilters);
    const [params, setParams] = useState();
    const [currentSorter, setCurrentSorter] = useState({});

    const searchInput = useRef(null);

    const createSorter = (dataIndex) => (a, b) => {};

    const [filterValues, setFilterValues] = useState({});
    const updateFilters = useCallback((dataIndex, value) => {
        setFilterValues(prev => ({ ...prev, [dataIndex]: value }));
    }, []);

    //default columns 
    const getDefaultColumns = [
            {
                title: "Transaction ID",
                dataIndex: "ReferenceTransactionId",
                align: "center",
                sorter: createSorter("ReferenceTransactionId"),
                ...GetColumnSearchProps({ dataIndex: "ReferenceTransactionId" }, updateFilters),
                width: "130px",
            },
            {
                title: "Request Date",
                dataIndex: "RequestTime",
                align: "center",
                sorter: createSorter("RequestTime"),
                ...GetColumnSearchProps({ dataIndex: "RequestDate" }, updateFilters),
                render: (text, row) => Formatter.createLocalDateTime(text, row.Timezone)?.format("YYYY-MM-DD"),
            },
            {
                title: "Request Time",
                dataIndex: "ExtractedTime",
                align: "center",
                ...GetColumnSearchProps({
                    dataIndex: "ExtractedTime",
                    value: undefined,
                    showTimeRange: true,
                }, updateFilters),
                render: (text, row) => Formatter.createLocalDateTime(row.RequestTime, row.Timezone)?.format("HH:mm:ss"),
            },
            {
                title: "Client Name",
                dataIndex: "ClientName",
                align: "center",
                //width: "150px",
                sorter: createSorter("ClientName"),
                ...GetColumnSearchProps({ dataIndex: "ClientName" }, updateFilters),
            },
            {
                title: "Time Zone",
                dataIndex: "Timezone",
                align: "center",
            },
            {
                title: "Target Language",
                dataIndex: "TargetLanguage",
                align: "center",
                sorter: createSorter("TargetLanguage"),
                ...GetColumnSearchProps({ dataIndex: "TargetLanguage" }, updateFilters),
            },
            {
                title: "Audio/Video",
                dataIndex: "VideoOption",
                width: "150px",
                align: "center",
                sorter: createSorter("VideoOption"),
                ...GetColumnSearchProps({ dataIndex: "VideoOption" }, updateFilters),
            },
            {
                title: "Status",
                dataIndex: "Status",
                align: "center",
                ...GetColumnSearchProps({ dataIndex: "Status" }, updateFilters),
            },
            {
                title: "Waiting Seconds",
                dataIndex: "WaitingSeconds",
                align: "center",
                sorter: createSorter("WaitingSeconds"),
            },
            {
                title: "Service Minutes",
                dataIndex: "ServiceMinutes",
                align: "center",
                sorter: createSorter("ServiceMinutes"),
                render: (text) => 
                <div>
                    {text || "-"}
                </div>
            },
            {
                title: "Star Rating",
                dataIndex: "CallQualityRatingStar",
                align: "center",
                sorter: createSorter("CallQualityRatingStar"),
                ...GetColumnSearchProps({ dataIndex: "CallQualityRatingStar" }, updateFilters),
                render: (text) => 
                <div>
                    {text !== null ? text : '-'}
                </div>
            },
            {
                title: "Caller Id",
                dataIndex: "CallerID",
                align: "center",
                width: 150,
                ...GetColumnSearchProps({ dataIndex: "CallerID" }, updateFilters),
                render: (text) => 
                <div>
                    {text || "-"}
                </div>
            },
        ];
    
    //additional columns that can be selected
    const getAdditionalColumns = [
        {
            title: "Product Name",
            dataIndex: "RequestProductName",
            align: "center",
            sorter: createSorter("RequestProductName"),
            ...GetColumnSearchProps({ dataIndex: "RequestProductName" }, updateFilters),
            //width: "120px",
        },
        {
            title: "Service Start Time",
            dataIndex: "ServiceStartTime",
            sorter: createSorter("ServiceStartTime"),
            align: "center",
            render: (text, row) =>
                <div>
                    {text ? Formatter.createLocalDateTime(text, row.Timezone)?.format("HH:mm:ss") : "-"}
                </div>
            //width: "120px",
        },
        {
            title: "Cancel Time",
            dataIndex: "ServiceCancelTime",
            align: "center",
            sorter: createSorter("ServiceCancelTime"),
            //width: "120px",
            render: (text, row) => (
                <div>
                    {text ? Formatter.createLocalDateTime(text, row.Timezone)?.format("YYYY-MM-DD HH:mm:ss") : "-"}
                </div>
            ),
        },
        
        {
            title: "Client User Name",
            dataIndex: "UserName",
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "UserName" }, updateFilters),
            width: "150px",
        },
        {
            title: "Interpreter ID",
            dataIndex: "InterpreterId",
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "InterpreterId" }, updateFilters),
            render: (text) => 
            <div>
                {text || "-"}
            </div>
        },
        {
            title: "Interpreter Name",
            dataIndex: "InterpreterFirstName",
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "InterpreterFirstName" }, updateFilters),
            render: (first, row) => {
                const firstName = first || "";
                const lastName = row?.InterpreterLastName || "";
                return `${firstName} ${lastName}`.trim() || '-';
            },
        },
        {
            title: "Serial Number",
            dataIndex: "IOSSerialNumber",
            align: "center",
            width: "150px",
            ...GetColumnSearchProps({ dataIndex: "IOSSerialNumber" }, updateFilters),
            render: (text) => 
                <div>
                    {text || "-"}
                </div>
        },
        {
            title: "Cost",
            dataIndex: "TotalPrice",
            align: "center",
            sorter: createSorter("TotalPrice"),
            render: (text) => 
            <div>
                {(text && `$${Number(text).toFixed(2)}`) || "-"}
            </div>
        },
    ];

    //function to handle missing or nrw column
    const addMissingOrNewColumns = (selectedCols=[], unselectedCols=[]) => {
        const selectedColumns = JSON.parse(JSON.stringify(selectedCols))
        const unselectedColumns = JSON.parse(JSON.stringify(unselectedCols))

        const defaultColumns = getDefaultColumns;
        const additionalColumns = getAdditionalColumns;

        const allCustomerCols = selectedColumns.concat(unselectedColumns);
        const allAvailableCols = defaultColumns.concat(additionalColumns);

        function extractDataIndices(array) {
            return array.map(item => item.dataIndex);
        }
          
        const customerIndices = extractDataIndices(_.compact(allCustomerCols));
        const availableIndices = extractDataIndices(_.compact(allAvailableCols));

        const missingColumns = availableIndices.filter(dataIndex => !customerIndices.includes(dataIndex)) || [];
        _.compact(missingColumns).forEach((indice) => {
            let column = allAvailableCols.find((col) => col.dataIndex == indice);
            if (column) {
                unselectedCols.push(column)
            }
        })
    }

    //function to handle custom column requirment for some company (eg northwestern NM)
    const getCustomColumns = async () => {
        const response = await Service.Auth().getCustomColumns(userId);
        const customColumns = response;
        let { preSelectedColumns, preUnSelectedColumns } = customColumns;
        
        const site_column_obj = {
            title: "Client Site",
            dataIndex: "Site",
            width: "120px",
            align: "center",
            ...GetColumnSearchProps({ dataIndex: "Site" }, updateFilters),
            render: (text) => 
            <div>
                {text || "-"}
            </div>
        }
        const deaprtment_column_obj = {
            title: "Client Department",
            dataIndex: "Department",
            width: "120px",
            align: "center",
            // ...GetColumnSearchProps({ dataIndex: "Site" }, updateFilters),
            render: (text) => 
            <div>
                {text || "-"}
            </div>
        }
    
        const defaultColumns = getDefaultColumns;
        const additionalColumns = getAdditionalColumns;

        if (SITE_COLUMN.includes(selectedCompany)) {
            defaultColumns.splice(3, 0, site_column_obj)
        }

        if (DEPARTMENT_COLUMN.includes(selectedColumns)) {
            defaultColumns.splice(4, 0, deaprtment_column_obj)
        }

        // create an copy of array
        let col = [...defaultColumns, ...additionalColumns];
        
        if (preSelectedColumns || preUnSelectedColumns) {
            const selectedColumns = preSelectedColumns?.map((dataIndex) =>
                col.find((column) => column.dataIndex === dataIndex)
            ).filter((e) => e);
            const unselectedColumns = preUnSelectedColumns?.map((dataIndex) =>
                col.find((column) => column.dataIndex === dataIndex)
            ).filter((e) => e);
            if (SITE_COLUMN.includes(selectedCompany)) {
                if (
                    !selectedColumns.find(e => e?.dataIndex === "Site") &&
                    !unselectedColumns.find(e => e?.dataIndex === "Site")
                ) {
                    selectedColumns.splice(3, 0, site_column_obj)
                }
            }
            if (DEPARTMENT_COLUMN.includes(selectedCompany)) {
                if (
                    !selectedColumns.find(e => e?.dataIndex === "Department") &&
                    !unselectedColumns.find(e => e?.dataIndex === "Department")
                ) {
                    selectedColumns.splice(3, 0, deaprtment_column_obj)
                }
            }
            addMissingOrNewColumns(selectedColumns, unselectedColumns);
            dispatch(updateColumns(selectedColumns, unselectedColumns, userId));
            return { selectedColumns, unselectedColumns };
        } else {
            let defaultCol = getDefaultColumns;
            let addCol = getAdditionalColumns;

            dispatch(updateColumns(defaultCol, addCol, userId));
            return { defaultCol, addCol };
        }
    };
    
    //fetch initial data based on select filters
    const fetchDataCB = useCallback(async () => {
        fetchDataPrivate(params?.pagination, params?.filters, params?.sorter);
    }, [params]);

    const fetchData = useIntervalAsync(
        fetchDataCB,
        state?.filter?.fetchInterval
    );

    //fetch data on initial load or when parameter changes
    useEffect(() => {
        if (params) {
            setTableLoading(true)
            fetchData();
        }
    }, [params]);

    useEffect(() => {
        setTableLoading(true);
        setParams({
            pagination: tableParams.pagination,
            filters: tableParams.filters,
            sorter: currentSorter,
        });
    }, [
        state.filter?.languageType,
        state.filter?.date,
        state.company?.client,
        state.company?.region,
        state.company?.site,
        filterSearch,
    ]);

    //define initial selected and unselected column
    const initialColumns = selectedColumns
        ? selectedColumns
        : getDefaultColumns;
    const initialUnselectedColumns = unselectedColumns
        ? unselectedColumns
        : getAdditionalColumns;
    const [columns, setColumns] = useState(initialColumns);
    const [columnsUnselected, setColumnsUnselected] = useState(
        initialUnselectedColumns
    );
   
    //useeffect to fetch data based on selected company
    useEffect(() => {
        if (selectedCompany) {
            getCustomColumns().then(({ selectedColumns, unselectedColumns }) => {
                setColumns(selectedColumns);
                setColumnsUnselected(unselectedColumns);
            });
        }
    }, [selectedCompany]);

    //fetch data if selected or unselected column changes
    useEffect(() => {
        const defaultColumns = getDefaultColumns;
        const additionalColumns = getAdditionalColumns;
        const newColumns =
        selectedColumns && selectedColumns.length > 0
            ? selectedColumns
            : defaultColumns;
        const newColumnsUnselected =
            unselectedColumns && unselectedColumns.length > 0
                ? unselectedColumns
                : additionalColumns;
        if (JSON.stringify(newColumns) !== JSON.stringify(columns)) {
            setColumns(newColumns);
        }
        if (
            JSON.stringify(newColumnsUnselected) !==
            JSON.stringify(columnsUnselected)
        ) {
            setColumnsUnselected(newColumnsUnselected);
        }
    }, [selectedColumns, unselectedColumns]);

    //function to get total number of transactions for pagination purpose
    const getTotalRows = async (params, fltr) => {
        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: fltr,
        };

        //api call for getting total transaction number for the specific time range
        await Service.Transaction()
            .getTransactionsTotal(filter, true)
            .then((data) => {
                setTableParams({
                    ...params,
                    pagination: {
                        ...params.pagination,
                        total: data.total,
                    },
                });
            });
    };

    //function to show modal
    const showModal = () => {
        setModalVisible(true);
        console.log("modalVisible: ", modalVisible);
    };

    //handle cancel button functionality
    const handleCancel = () => {
        setModalVisible(false);
    };

    //call function based on download type
    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDFFunction();
        } else if (downloadType === "EXCEL") {
            downloadCSVFunction();
        }
    };

    //function to handle table change
    const handleTableChange = (pagination, filters, sorter) => {
        setCurrentSorter(sorter);
        setParams({ pagination, filters, sorter });
    };

    //function to handle search functionality
    const handleSearch = (selectedKeys, confirm, dataIndex, clearFilters) => {

        if(selectedKeys.length > 0 && typeof selectedKeys[0] == "string") {
            selectedKeys[0] = selectedKeys[0]?.trim();

            if (selectedKeys[0] == '') {
                handleReset(clearFilters, dataIndex, confirm);
                return;
            }
        }

        if (selectedKeys.length == 0) {
            handleReset(clearFilters, dataIndex, confirm);
            return;
        }

        confirm();
        setSearchText(selectedKeys);
        setSearchedColumn(dataIndex);

        setFilterSearch((prevState) => {
            const updatedFilterSearch = {
                ...prevState,
                [dataIndex]: selectedKeys,
            };
            setParams({
                pagination: tableParams.pagination,
                filters: { ...tableParams.filters, ...updatedFilterSearch },
                sorter: tableParams.sorter,
            });
            return updatedFilterSearch;
        });
    };

    //function to handle reset functionality when reset button is clicked
    const handleReset = (clearFilters, dataIndex, confirm) => {
        clearFilters();
        confirm();
        setSearchText("");
        setSearchedColumn(dataIndex);

        setFilterSearch((prevState) => {
            const updatedFilterSearch = JSON.parse(
                JSON.stringify(prevState || {})
            );
            const updatedTableParams = JSON.parse(
                JSON.stringify(tableParams.filters || {})
            );
            delete updatedTableParams[dataIndex];
            delete updatedFilterSearch[dataIndex];
            setParams({
                pagination: tableParams.pagination,
                filters: { ...updatedTableParams, ...updatedFilterSearch },
                sorter: tableParams.sorter,
            });
            setTableParams((pState) => {
                return { ...pState, filters: updatedTableParams };
            });
            return updatedFilterSearch;
        });
    };

    //fetch data
    const fetchDataPrivate = async (pagination, filters, sorter = {}) => {
        let params = pagination
            ? {
                  pagination,
                  filters,
                  sort: {
                      [sorter.field || "defaultField"]: sorter.order || "asc",
                  },
              }
            : tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
        };

        await getTotalRows(params, filter.filters);

        //call api for getting completed transaction data
        Service.Transaction()
            .getTransactions(
                filter,
                (params.pagination.current - 1) * params.pagination.pageSize,
                params.pagination.pageSize,
                params.sort,
                loading
            )
            .then((data) => {
                setData(data?.length > 0 ? data : []);
                setTableLoading(false);
            });
    };

    //function to handle CSV download
    const downloadCSVFunction = (pagination, filters, sorter) => {
        let params = pagination
            ? {
                  pagination,
                  filters: filterSearch,
                  sort: {
                      [sorter.field || "defaultField"]: sorter.order || "asc",
                  },
              }
            : tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
        };
        setLoading(true);
        const dataIndexValues = columns.map((column) => column.dataIndex);
        if (!dataIndexValues.includes('InterpreterId')) {
            dataIndexValues.push('InterpreterId');
        }
        if (!dataIndexValues.includes('InterpreterFirstName')) {
            dataIndexValues.push('InterpreterFirstName');
        }
        Service.Transaction()
            .downloadEXCEL(filter, params.sort, dataIndexValues)
            .then((data) => {
                setLoading(false);
                const downloadRangeStart = moment(state.filter?.date?.minDate).format('MM-DD-YYYY');
                const downloadRangeEnd = moment(state.filter?.date?.maxDate).format('MM-DD-YYYY');
                const donwloadDateRange = state?.filter?.dateRange;
                const filename = `Transaction_Records_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}.xlsx`;
                saveAs(data, filename);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };

     //function to handle pdf download
    const downloadPDFFunction = (pagination, filters, sorter) => {
        let params = pagination
            ? {
                  pagination,
                  filters: filterSearch,
                  sort: {
                      [sorter.field || "defaultField"]: sorter.order || "asc",
                  },
              }
            : tableParams;

        const filter = {
            start: state.filter?.date?.minDate,
            end: state.filter?.date?.maxDate,
            languageType: state.filter?.languageType,
            filters: {
                ...filterValues,
                ...filters,
                ...Object.assign({}, filterSearch, dashboardFilters),
            },
        };
        setLoading(true);
        const dataIndexValues = columns.map((column) => column.dataIndex);
        Service.Transaction()
            .downloadPDF(filter, params.sort, dataIndexValues)
            .then((data) => {
                setLoading(false);
                const downloadRangeStart = moment(state.filter?.date?.minDate).format('MM-DD-YYYY');
                const downloadRangeEnd = moment(state.filter?.date?.maxDate).format('MM-DD-YYYY');
                const donwloadDateRange = state?.filter?.dateRange;
                const filename = `Transaction_Records_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}`;
                saveAs(data, filename);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    };

    // Get dynamic table scroll width
    const totalMinWidth = columns?.reduce((acc, column) => {
        if (column?.width) {
          return acc + parseInt(column?.width, 10);
        }
        return acc + 120;
    }, 0);

    return (
        <div className="roles-container">
            <Row className="irh-container">
                <Col flex={"100%"}>
                    <Table
                        title={() => (
                            <Row className="select-col">
                                <Col flex={"auto"}>
                                    <Button
                                        onClick={showModal}
                                        className="select-col-btn"
                                        type="primary"
                                    >
                                        Select Columns
                                    </Button>
                                </Col>
                                <Col className="dropdown-col">
                                    <Row
                                        gutter={[8, 8]}
                                        justify="end"
                                        align="middle"
                                        className="dropdown-download-row"
                                    >
                                        <Col>
                                            <DropDown
                                                className="dropdown-element"
                                                defaultValue={downloadType}
                                                dropdownOptions={
                                                    downloadDropdownType
                                                }
                                                handleTypeSelect={(
                                                    downloadType
                                                ) =>
                                                    setDownloadType(
                                                        downloadType
                                                    )
                                                }
                                            />
                                        </Col>
                                        <Col>
                                            <Button
                                                loading={loading}
                                                type="primary"
                                                onClick={downloadFile}
                                            >
                                                Download
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        )}
                        style={{
                            backgroundColor: colorBgLayout,
                        }}
                        scroll = {{ x : totalMinWidth }}
                        columns={columns}
                        rowKey={(record) => record.Id}
                        dataSource={data}
                        pagination={tableParams.pagination}
                        loading={tableLoading}
                        onChange={handleTableChange}
                        size="middle"
                        className="voyce-custom-table responsive-table"
                        expandable={{
                            expandedRowRender,
                            defaultExpandAllRows: false,
                        }}
                        sticky
                        rowClassName={(record, index) =>
                            record?.Status == "New"
                                ? "new-row"
                                : record?.Status == "In Service"
                                ? "inservice-row"
                                : record?.Status == "Pending"
                                ? "pending-row"
                                : "other-row"
                        }
                    />
                </Col>
            </Row>
            <DragnDrop
                defaultColumns={columns}
                additionalColumns={columnsUnselected}
                modalVisible={modalVisible}
                handleCancel={handleCancel}
            />
        </div>
    );
};

export default Transactions;
