import { Card } from 'antd';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd'

const Square = ({ size, loading, id, children, onDrop, edit, title }) => {

    // Use the 'useDrop' hook from react-dnd to handle dropping functionality
    const [{ isOver }, drop] = useDrop(() => ({
        accept: "widgets",
        drop: () => onDrop(id),
        collect: monitor => ({
          isOver: !!monitor.isOver(),
        }),
    }), [id])

    return (
        <Card
            // Reference drop if in edit mode, otherwise undefined
            ref={edit ? drop : undefined}
            // Set class names based on size, edit mode, and isOver state
            className={
                (size === "large" ? "active-devices-card" :
                size === "medium" ? "widget-cards" :
                size === "small" ? "calls-card" : "stars-card")
                + " widget-cards-no-hover" // Add class for no hover effect
                + (isOver ? " widget-cards-highlight" : "") // Add class if square is being hovered over
            }
            bordered={false}
            loading={loading}
            title={title}
            bodyStyle={{padding: 0}}
            style={!edit ? {background: "transparent"} : {}}
        >
            {isOver ? null : children}    
        </Card>
    )
}

// Prop type definitions for Square component
Square.propTypes = {
    size: PropTypes.oneOf(['large', 'small', 'medium']),  // Size of the square
    loading: PropTypes.bool.isRequired                   // Loading state
}

export default Square;   // Export the Square component