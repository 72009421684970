import { Radio } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setTheme } from "../../store/actions/profile.action";

export const THEME_TYPES = {
    LIGHT: "light",
    DARK: "dark"
}

//theme options to select from
const THEME_OPTIONS = [
    { label: 'Light', value: THEME_TYPES.LIGHT },
    { label: 'Dark', value: THEME_TYPES.DARK },
];

const ThemeFilter = (props) => {

    const dispatch = useDispatch();
    //get current theme from redux
    const currentTheme = useSelector((state) => state?.profile?.theme);

    //display theme component
    return (
        <Radio.Group 
            buttonStyle="solid"
            options={THEME_OPTIONS}
            optionType="button"
            value={currentTheme}
            defaultValue={currentTheme}
            onChange={(e) => dispatch(setTheme(e.target.value))}
        />
    )
}

export default ThemeFilter
