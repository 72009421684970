// Import necessary libraries and utilities
import ReactECharts from 'echarts-for-react';
import chroma from 'chroma-js';
import PropTypes from 'prop-types';
import Formatter from '../../../utils/Formatter';

// TreeMapChart component definition
const TreeMapChart = ({ data }) => {
  // Generate colors for the treemap based on data length
  const colors = chroma
    .scale(['#203870', '#54c9e8', '#ff876f', '#226885'])
    .colors(data.length || 200);

  // Define the options for the treemap chart
  const option = {
    tooltip: {
      // Display tooltip information
      trigger: 'item',
      formatter: ({ name, value }) => {
        return `${name}</br>${Formatter.numberWithCommas(Number(value))} Calls`;
      }
    },
    series: [
      {
        // Configuration for the treemap series
        type: 'treemap',
        // Map data to the treemap items with assigned colors
        data: data?.map((e, idx) => ({ ...e, itemStyle: { color: colors[idx] } })) || [],
        color: colors,
        leafDepth: 1,
        label: {
          // Settings for label display within the treemap
          show: true,
          formatter: '{b}',
          position: 'inside',
          distance: 2,
          align: 'center',
          verticalAlign: 'middle',
          padding: 10,
        },
        itemStyle: {
          // Styling for treemap items
          borderWidth: 1,
          borderColor: 'black',
          gapWidth: 1,
        },
        emphasis: {
          // Emphasis styling for labels on hover
          label: {
            show: true,
            fontSize: 14,
            fontWeight: 'bold',
          },
        },
        upperLabel: {
          // Settings for upper label display
          show: true,
          height: 22,
          color: '#fff',
          formatter: function (params) {
            return (
              '{a|' +
              params.name +
              '}\n{b|' +
              params.value +
              '}\n{c|' +
              params.percent +
              '%}'
            );
          },
          rich: {
            a: {
              fontSize: 16,
              lineHeight: 22,
              align: 'center',
            },
            b: {
              fontSize: 14,
              lineHeight: 22,
              align: 'center',
            },
            c: {
              fontSize: 14,
              lineHeight: 22,
              align: 'center',
            },
          },
        },
        roam: 'move',
        breadcrumb: {
          show: false,
        },
        width: '100%',
        height: '130px',
        left: 0,
        top: -20,
        bottom: 0,
        right: 0,
      },
    ],
  };

  // Render the treemap chart using ReactECharts component
  return <ReactECharts option={option} style={{ height: '110px', padding: 0 }} />;
};

// Define prop types for TreeMapChart component
TreeMapChart.propTypes = {
  data: PropTypes.array.isRequired, // Ensure 'data' prop is an array and is required
};

export default TreeMapChart;
