import { Table, Spin, Row, Col, Card, theme, Button, Space, Select, Input, Form } from 'antd';
import DropDown from "../../components/dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import React, { useState , useEffect} from "react";
import jsPDF from "jspdf";
import Service from "../../services";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import Formatter from "../../utils/Formatter";
import './terp.scss'
import { DownloadOutlined, SearchOutlined, LinkOutlined } from '@ant-design/icons';
import exportAsCSV from "../../services/exportToCVS.service";
import { setLanguageType } from '../../store/actions/filter.action';
import { Link } from "react-router-dom";
import { exportToExcel } from '../../services/exportToExcel';
import moment from 'moment';

const { Option } = Select;

const TerpTable = () => {

    const location = useLocation();
    const dispatch = useDispatch();
    const navigate= useNavigate();
    const getDashboardFilters = () => {
        const searchParams = new URLSearchParams(location.search);
        let filters = {};

        for (let i = 0; ; i++) {
            const dataIdx = searchParams.get(`dataIdx${i}`);
            const status = searchParams.get(`status${i}`);

            if (dataIdx && status) {
                filters[dataIdx] = status;
            } else {
                break;
            }
        }
        return filters;
    };
    const dashboardFilters = getDashboardFilters();
    const {


        token: { colorBgLayout, colorText },

    } = theme.useToken();
  const state = useSelector((state) => state);
  const [searchedData, setSearchedData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [downloadType, setDownloadType] = useState("EXCEL");
  const [searchColumn, setSearchColumn] = useState('');
  const [searchText, setSearchText] = useState('');

  // Event handler for navigating to transaction details by language
  const handleNavigationClick = (record) => {
    let targetLanguage = record.TargetLanguages[0];
    dispatch(setLanguageType(
        targetLanguage?.match(/spanish/i) ? "-1" : 
        targetLanguage?.match(/ASL/i) ? "-1": "-1"
    ));
    navigate(`/pages/transactions?dataIdx0=Status&status${0}=Serviced&dataIdx1=InterpreterId&status${1}=${record.InterpreterId}`);
  }


//columns
const originalColumn = () => [
    {
      title: "Interpreter ID",
      dataIndex: "InterpreterId",
      sorter: (a, b) => a.InterpreterId - b.InterpreterId,
      className: "header-table",
    },
    {
      title: "Interpreter Name",
      dataIndex: "InterpreterName",
      sorter: (a, b) => {
        const aValue = a?.InterpreterName;
        const bValue = b?.InterpreterName;
        if (typeof aValue === "string" && typeof bValue === "string") {
          return aValue.localeCompare(bValue);
        } else {
          return 0;
        }
      },
      className: "header-table",
      
    },
    {
      title: "Language",
      dataIndex: "TargetLanguages",
      key: "TargetLanguages",
      render: TargetLanguages => TargetLanguages ? TargetLanguages.join(', ') : '',
      sorter: (a, b) => (a.TargetLanguages || []).join(',').localeCompare((b.TargetLanguages || []).join(',')),
    },
    {
      title: "# Calls",
      dataIndex: "TotalCalls",
      render: (text) => Formatter.numberWithCommas(text),
      sorter: (a, b) => a.TotalCalls - b.TotalCalls,
    },
    {
      title: "Answered",
      dataIndex: "TotalCallsAnswered",
      sorter: (a, b) => a.TotalCallsAnswered - b.TotalCallsAnswered,
    },
    {
      title: "Missed %",
      dataIndex: "TotalCallsMissedPercentage",
      sorter: (a, b) => a.TotalCallsMissedPercentage - b.TotalCallsMissedPercentage,
    },
    {
      title: "Avg WT",
      dataIndex: "TotalWaitTime",
      sorter: (a, b) => a.TotalWaitTime - b.TotalWaitTime,
    },
    {
      title: "Serviced Mins",
      dataIndex: "TotalServiceMinutes",
      sorter: (a, b) => a.TotalServiceMinutes - b.TotalServiceMinutes,
      render: (text, record) => 
      <a 
          onClick={() => handleNavigationClick(record)}
          style={{textDecoration: "underline"}}>
          {Formatter.numberWithCommas((record?.TotalServiceMinutes))} <LinkOutlined />
      </a>
    },
    
  ];


  const [columns, setColumns] = useState(originalColumn());
  const [visibleColumns, setVisibleColumns] = useState(['InterpreterID', 'InterpreterFirstName', 'InterpreterLastName', 'TotalCalls', 'TotalCallsAnsweered, TotalCallsMissedPercentage, TotalServiceMinutes, TotalWaitTIme']);

  // Select type of download
  const downloadDropdownType = [
    {
        value: "PDF",
        label: "PDF",
    },
    {
        value: "EXCEL",
        label: "EXCEL",
    },
];

 //Get per terp aggregated data from backend
 const getDataSource = () => {
  let filteredData = searchedData
    ?.sort((a, b) => b.TotalCalls - a.TotalCalls)
    ?.map((item, index) => {
      return {
        Rank: index + 1,
        InterpreterId: item.InterpreterId,
        InterpreterName: item.InterpreterName,
        TotalCalls: item.TotalCalls,
        TotalCallsAnswered: item.TotalCallsAnswered,
        TotalCallsMissed: item.TotalCallsMissed,
        TotalWaitTime: Formatter.numberWithCommas(Math.round(item?.TotalWaitTime / item?.TotalCalls)),
        WaitTime: item?.TotalWaitTime,
        TargetLanguages: item.UniqueTargetLanguages,
        TotalServiceMinutes: item?.TotalServiceMinutes,
        TotalCallsMissedPercentage: Math.round((item?.TotalCallsMissed / item?.TotalCalls) * 100),
        
      };
    });

    if (searchColumn && searchColumn !== '') {
      filteredData = filteredData.filter((item) => {
        const columnValue = item[searchColumn]?.toString().toLowerCase();
        const searchTextValue = searchText.toLowerCase();
        return columnValue && (columnValue.includes(searchTextValue) || columnValue.indexOf(searchTextValue) !== -1);
      });
    }




  return filteredData
};



    // Calculate sums for each column
    const sumTotalCalls = getDataSource()?.reduce((acc, curr) => acc + curr.TotalCalls, 0);
    const sumTotalWaitTime = getDataSource()?.reduce((acc, curr) => acc + parseInt(curr.WaitTime || 0), 0);
    const sumTotalCallsAnswered = getDataSource()?.reduce((acc, curr) => acc + parseInt(curr.TotalCallsAnswered || 0), 0);
    const sumTotalCallsMissed = getDataSource()?.reduce((acc, curr) => acc + curr.TotalCallsMissed, 0);
      const sumTotalServiceMinutes = getDataSource()?.reduce((acc, curr) => acc + parseInt(curr.TotalServiceMinutes || 0), 0) || 0;
    
    // Calculate average answer time
    const averageAnswerTime = sumTotalCallsAnswered > 0 ? sumTotalWaitTime / sumTotalCallsAnswered : 0;
    
    // Calculate abandonment percentage
    const abandonmentPercentage = Math.round((sumTotalCallsMissed / sumTotalCalls) * 100) || 0;

  //select function to call based on download type
  const downloadFile = () => {
    if (downloadType === "PDF") {
        //PDF converter
        downloadPDF()
        //   props?.downloadPDF(range);
    } else if (downloadType === "EXCEL") {
        // downloadCSVFunction(tableParams.pagination, filterSearch, tableParams.sorter);
        downloadEXCELFunction(); 
        
        //CSV converter
        //   props?.downloadCSV(range);
    }
};

  const handleSearch = (values) => {
    setSearchColumn(values?.column ? values?.column: '');
    setSearchText(values?.text ? values?.text?.trim() : '');
  };

//handle reset functionality
    const handleReset = () => {
      setSearchColumn('');
      setSearchText('');
      setVisibleColumns(['InterpreterID', 'InterpreterFirstName', 'InterpreterLastName', 'TotalCalls', 'TotalCallsAnsweered, TotalCallsMissedPercentage, TotalServiceMinutes, TotalWaitTIme']);
    };

  //function to fetch data
  const fetchData = (filters) => {
    setTableLoading(true);
    const filter = {
        start: state.filter?.date?.minDate,
        end: state.filter?.date?.maxDate,
        filters,
    };
  
    Service.Company()
      .getTerpByCompany(filter)
      .then((response) => {
        const Searchdata = response;
        setSearchedData(Searchdata);
        setTableLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setTableLoading(false);
      });

  };
  

  //useEffect to update data if these things change
  useEffect(() => {
    fetchData();
  }, [
    state.filter?.languageType,
    state.filter?.date,
    state.company?.selected,
    state.company?.client,
  ]);


  //download PDF function 
  const downloadPDF = () => {
    if (!searchedData || !getDataSource()?.length) {
      return alert("No data to download");
    }
  
    const doc = new jsPDF("landscape", "px", "a4");
    doc.setFontSize(18);
    doc.text("Interpreter Report", 30, 20);
    doc.setFontSize(12);
  
    const headerList = [
      "Interpreter ID",
      "Name",
      "Language",
      "# Calls",
      "Answered",
      "Missed %",
      "Serviced Mins",
      "Avg WT",
    ];
  
    const bodyList = getDataSource().sort((a, b) => b.TotalCalls - a.TotalCalls).map(item => [
      item.InterpreterId,
      item.InterpreterName,
      item.TargetLanguages?.join(', ') || '',
      Formatter.numberWithCommas(item.TotalCalls),
      item.TotalCallsAnswered,
      item.TotalCallsMissedPercentage,
      Formatter.numberWithCommas(item.TotalServiceMinutes),
      item.TotalWaitTime,
    ].map(value => String(value)));
  
    doc.autoTable({
      head: [headerList.map(header => [header])],
      body: bodyList,
      headStyles: {
        fillColor: [220, 220, 220],
        textColor: [33, 33, 33],
      },
      startY: 50
    });
  
    doc.save(new Date().toISOString().split("T")[0]);
  };
  

  //download CSV function
  const downloadCSVFunction = () => {
    if (!searchedData || !getDataSource()?.length) {
      return alert("No data to download");
    }
  
    const downloadArray = getDataSource().sort((a, b) => b.TotalCalls - a.TotalCalls).map(item => ({
      "Interpreter ID": item.InterpreterId,
      "Name": item.InterpreterName,
      "Language": item.TargetLanguages?.join(', ') || '',
      "# Calls": Formatter.numberWithCommas(item.TotalCalls),
      "Answered": item.TotalCallsAnswered,
      "Missed %": item.TotalCallsMissedPercentage,
      "Serviced Mins": Formatter.numberWithCommas(item.TotalServiceMinutes),
      "Avg WT": item.TotalWaitTime,
    }));
  
    exportAsCSV(
      downloadArray,
      "Interpreter Report",
      [
        "Interpreter ID",
        "Name",
        "Language",
        "# Calls",
        "Answered",
        "Missed %",
        "Serviced Mins",
        "Avg WT",
      ],
      "Interpreter_Report"
    );
  };

  const downloadEXCELFunction = () => {
    if (!searchedData || !getDataSource()?.length) {
      return alert("No data to download");
    }
  
    const downloadArray = getDataSource().sort((a, b) => b.TotalCalls - a.TotalCalls).map(item => ({
      "Interpreter ID": item.InterpreterId,
      "Name": item.InterpreterName,
      "Language": item.TargetLanguages?.join(', ') || '',
      "# Calls": Formatter.numberWithCommas(item.TotalCalls),
      "Answered": item.TotalCallsAnswered,
      "Missed %": item.TotalCallsMissedPercentage,
      "Serviced Mins": Formatter.numberWithCommas(item.TotalServiceMinutes),
      "Avg WT": item.TotalWaitTime,
    }));
      const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
      const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
      const donwloadDateRange = state?.filter?.dateRange;
      const filename = `Interpreter_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}.xlsx`;
      const sheetData = [downloadArray];
      const sheetNames = ["Interpreter Dashboard Report"];

      exportToExcel(sheetData, sheetNames, filename);
  };
  
  

    return (
         <div style={{padding:24, marginLeft: '5%'}}>
          <Spin spinning={tableLoading} tip="Cooking up your data...">
          <Row style={{ display: 'flex', justifyContent: 'space-between'}}>
          <Form onFinish={handleSearch} onReset={handleReset} className="search-form">
          <Space>
          <div className="search-form">
            <Form.Item name="column">
              <Select
                placeholder="Select a column"
                style={{ width: 200 }}
              >
                {columns.map((column) => (
                  <Option value={column.dataIndex} key={column.key}>
                    {column.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name="text">
              <Input
                placeholder="Enter text to search"
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item className="form-button">
              <Button htmlType="submit" type="primary" icon={<SearchOutlined />}>
                Search
              </Button>
            </Form.Item>
            <Form.Item>
            <Button htmlType="reset" 
             className="form-button"
                  >
                  Reset
            </Button>
            </Form.Item>
            </div>
            </Space>
            
          </Form>
          <Col style={{marginLeft: 'auto', marginRight: 10}} >
              <DropDown
                  defaultValue={downloadType}
                  dropdownOptions={
                      downloadDropdownType
                  }
                  handleTypeSelect={(
                      downloadType
                  ) =>
                      setDownloadType(
                          downloadType
                      )
                  }
                  style={{ marginRight:'10px'}}
              />
            </Col>
            <Col>
              <Button 
                type="primary"
                className="download-button"
                onClick={downloadFile}>
              <DownloadOutlined/>
                  Download
              </Button>
            </Col>
          </Row>
          <div className="site-card-wrapper" style={{ display: 'flex', justifyContent: 'center', padding: '20px 0' }}>
            <Row justify="space-around" style={{ width: '100%', maxWidth: '1200px' }}>
                <Col span={10}>
                <Card
                    className="TerpCard"
                    title="AAT Seconds"
                    bordered={false}
                    style={{ textAlign: 'center', fontSize: '24px', width: '100%', marginBottom: '20px' }}
                >
                    {averageAnswerTime.toFixed(2)}
                </Card>
                </Col>
                <Col span={10}>
                <Card
                    className="TerpCard"
                    title="Abandonment"
                    bordered={false}
                    style={{ textAlign: 'center', fontSize: '24px', width: '100%', marginBottom: '20px' }}
                >
                    {abandonmentPercentage}%
                </Card>
                </Col>
            </Row>
          </div>
          <Table columns={columns} dataSource={getDataSource()} 
              size="middle"
              className="terp-table" 
              rowKey={(record) => record.Rank}
              pagination={false}
              style={{
                width: "100%",
                backgroundColor: colorBgLayout,
                overflowX: "auto",
            
              }}
              footer={() => (
                <Row gutter={[16, 0]} style={{ justifyContent: 'space-between', backgroundColor: "#203870", color: "white" }}>
                  <Col span={4}>Total Calls: {Formatter.numberWithCommas(sumTotalCalls)}</Col>
                  <Col span={4}>Total Answered: {Formatter.numberWithCommas(sumTotalCallsAnswered)}</Col>
                  <Col span={4}>Avg Missed %: {Math.round(abandonmentPercentage) || 0}%</Col>
                  <Col span={4}>Avg WT: {Formatter.numberWithCommas(averageAnswerTime.toFixed(2))}</Col>
                  <Col span={4}>Total Serviced Mins: {Formatter.numberWithCommas(sumTotalServiceMinutes)}</Col>
                </Row>
              )}
          />
        </Spin>
      </div>
    );
  };
  
  export default TerpTable;
  