import { Button, Card, Col, Row, Spin, Table, theme } from 'antd';
import { LinkOutlined } from "@ant-design/icons";
import './audioVideo.report.scss';
import LanguageDistributionBar from './distribution.chart';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { getLanguagesDetails } from '../../../store/actions/language.action';
import Formatter from '../../../utils/Formatter';
import { useNavigate } from 'react-router-dom';
import DropDown from '../../../components/dropdown';
import exportAsCSV from "../../../services/exportToCVS.service"
import jsPDF from "jspdf";
import GetColumnSearchProps from '../../../components/table/search';
import { setLanguageType } from '../../../store/actions/filter.action';
import useIntervalAsync from '../../../components/hooks/useInterval';
import "jspdf-autotable";
import moment from "moment";
import { exportToExcel } from '../../../services/exportToExcel';

const AudioVideoReport = () => {
    // Extracting necessary values from the Ant Design theme
    const { token: {
        colorBgLayout
    } } = theme.useToken();
    // State to manage loading state
    const [loading, setLoading] = useState(false);

    // Redux selectors to retrieve data from the store
    const filter = useSelector((state) => state.filter);
    const company = useSelector((state) => state.company);
    const language = useSelector((state) => state.language);

    // State to manage the type of file to download (CSV or PDF)
    const [downloadType, setDownloadType] = useState("EXCEL");

    // Redux dispatcher to dispatch actions
    const dispatch = useDispatch();

    // Hook for programmatic navigation
    const navigate = useNavigate();

    // Callback function to fetch language details
    const fetchLanguagesCB = useCallback(async () => {
        dispatch(getLanguagesDetails(true))
            .then(() => setLoading(false))
            .catch(() => setLoading(false));
    }, []);

    // Custom hook to handle intervals for fetching language details
    const fetchLanguages = useIntervalAsync(fetchLanguagesCB, filter?.fetchInterval);
    const state = useSelector((state) => state);
    // Effect hook to initiate fetching language details
    useEffect(() => {
      setLoading(true);
      fetchLanguages();
    }, [filter?.date, company?.client, company?.region, company?.site]);


    let sumTotalCalls             = 0;
    let sumTotalMinutes           = 0;
    let sumTotalAudioCalls        = 0;
    let sumTotalVideoCalls        = 0;
    let sumTotalAudioMinutes      = 0;
    let sumTotalVideoMinutes      = 0;
    let sumTotalCompletedCalls    = 0;

    // Formatting the raw data for the table
    let dataSource = language.rawData?.map((list) => {
        // Formatting various fields in the data
        const avg = list.CallQualityRatingStar / list.CountRatingStarCalls;
        
        sumTotalCalls           += list?.TotalCalls;
        sumTotalMinutes         += list?.ServiceMinutes;
        sumTotalAudioMinutes    += Math.round(list?.CountAudioMinute);
        sumTotalVideoMinutes    += Math.round(list?.CountVideoMinute);
        sumTotalAudioCalls      += list?.CountSuccessAudioCalls;
        sumTotalVideoCalls      += list?.CountSuccessVideoCalls;
        sumTotalCompletedCalls  += (list?.CountSuccessAudioCalls + list?.CountSuccessVideoCalls)

       return {
            ...list,
            "TargetLanguage": list?.TargetLanguage,
            "totalCalls": Formatter.numberWithCommas(list?.TotalCalls),
            "serviceMins":  Formatter.numberWithCommas(list?.ServiceMinutes),
            "CountSuccessAudioCalls": Formatter.numberWithCommas(list?.CountSuccessAudioCalls),
            "CountSuccessVideoCalls": Formatter.numberWithCommas(list?.CountSuccessVideoCalls),
            "CountAudioMinute": `${Formatter.numberWithCommas(Math.round(list?.CountAudioMinute))} minutes`,
            "CountVideoMinute": `${Formatter.numberWithCommas(Math.round(list?.CountVideoMinute))} minutes`,
            "avgRating": avg,
            "CompletedCalls" : Formatter.numberWithCommas(list?.CountSuccessAudioCalls + list?.CountSuccessVideoCalls),
        }
    })

    // Array containing options for file download type (PDF or CSV)
    const downloadDropdownType = [
        {
            value: "PDF",
            label: "PDF",
        },
        {
            value: "EXCEL",
            label: "EXCEL",
        },
    ];

    // Function to download PDF file
    const downloadPDF = () => {
        let downloadArray = [];
        
        for (let list of language.rawData) {
            const avg = list.CallQualityRatingStar / list.CountRatingStarCalls;
            const totalAudioCall = list.CountSuccessAudioCalls;
            const totalVideoCall = list.CountSuccessVideoCalls;
            let newList = {
                "Language Name": list?.TargetLanguage,
                "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
                "Total Minutes":  Formatter.numberWithCommas(list?.ServiceMinutes),
                "Calls by Audio": Formatter.numberWithCommas(totalAudioCall),
                "Calls by Video": Formatter.numberWithCommas(totalVideoCall),
                "Minutes by Audio":`${Formatter.numberWithCommas(Math.round(list?.CountAudioMinute))} minutes`,
                "Minutes by Video": `${Formatter.numberWithCommas(Math.round(list?.CountVideoMinute))} minutes`,
            };
            downloadArray.push(newList);
        }
      
        const headerList = [
            "Language Name",
            "Total Calls",
            "Total Minutes",
            "Calls by Audio",
            "Calls by Video",
            "Minutes by Audio",
            "Minutes by Video",
        ];
        const bodyList = downloadArray.map(item => Object.values(item).map(value => String(value)));
        const doc = new jsPDF("landscape", "px", "a4");
        doc.setFontSize(18);
        const downloadRangeStart = moment(state.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state.filter?.date?.maxDate).format('MM-DD-YYYY');
        const filename = `Audio_Video_Report_${downloadRangeStart}-${downloadRangeEnd}`;
        doc.text(`Audio_Video_Report_${downloadRangeStart}-${downloadRangeEnd}`, 30, 20);
        doc.setFontSize(12);
        doc.autoTable({
            head: [headerList.map(header => [header])],
            body: bodyList,
            headStyles: {
                fillColor: [220, 220, 220],
                textColor: [33, 33, 33],
            },
            startY: 50
        });
        doc.save(filename);
    };
      
    
    // Function to download CSV file
    const downloadCSVFunction = () => {
        let downloadArray = [];
        for (let list of language.rawData) {
            const avg = list.CallQualityRatingStar / list.CountRatingStarCalls;
            const totalAudioCall = list.CountSuccessAudioCalls;
            const totalVideoCall = list.CountSuccessVideoCalls;
            let newList = {
                "Language Name": list?.TargetLanguage,
                "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
                "Total Minutes":  Formatter.numberWithCommas(list?.ServiceMinutes),
                "Calls by Audio": Formatter.numberWithCommas(totalAudioCall),
                "Calls by Video": Formatter.numberWithCommas(totalVideoCall),
                "Minutes by Audio":`${Formatter.numberWithCommas(Math.round(list?.CountAudioMinute))} minutes`,
                "Minutes by Video": `${Formatter.numberWithCommas(Math.round(list?.CountVideoMinute))} minutes`,
            };
            downloadArray.push(newList);
        }
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.dateRange;
        const filename = `Audio_Video_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}`;
        exportAsCSV(
            downloadArray,
            filename,
            //new Date().toISOString().split("T")[0],
            [
                "Language Name",
                "Total Calls",
                "Total Minutes",
                "Calls by Audio",
                "Calls by Video",
                "Minutes by Audio",
                "Minutes by Video",
            ],
            "Language Report"
        );
    };

    const downloadEXCELFunction = () => {
        let downloadArray = [];
        for (let list of language.rawData) {
            const avg = list.CallQualityRatingStar / list.CountRatingStarCalls;
            const totalAudioCall = list.CountSuccessAudioCalls;
            const totalVideoCall = list.CountSuccessVideoCalls;
            let newList = {
                "Language Name": list?.TargetLanguage,
                "Total Calls": Formatter.numberWithCommas(list?.TotalCalls),
                "Total Minutes":  Formatter.numberWithCommas(list?.ServiceMinutes),
                "Calls by Audio": Formatter.numberWithCommas(totalAudioCall),
                "Calls by Video": Formatter.numberWithCommas(totalVideoCall),
                "Minutes by Audio":`${Formatter.numberWithCommas(Math.round(list?.CountAudioMinute))} minutes`,
                "Minutes by Video": `${Formatter.numberWithCommas(Math.round(list?.CountVideoMinute))} minutes`,
            };
            downloadArray.push(newList);
        }
        
        const downloadRangeStart = moment(state?.filter?.date?.minDate).format('MM-DD-YYYY');
        const downloadRangeEnd = moment(state?.filter?.date?.maxDate).format('MM-DD-YYYY');
        const donwloadDateRange = state?.filter?.dateRange;
        const filename = `Audio_Video_Report_${donwloadDateRange}--${downloadRangeStart}--${downloadRangeEnd}.xlsx`;
        
        const sheetData = [downloadArray];
        const sheetNames = ["Audio Video Report"];
        exportToExcel(sheetData, sheetNames, filename);

    };

    // Function to handle file download based on selected type (PDF or CSV)
    const downloadFile = () => {
        if (downloadType === "PDF") {
            downloadPDF()
        } else if (downloadType === "EXCEL") {
            downloadEXCELFunction();
        }
    };

    // Function to handle navigation based on language type
    const handleNavigationClick = (text) => {
        dispatch(setLanguageType(
            text.match(/spanish/i) ? "sp" : 
                text.match(/ASL/i) ? "ASL": "LOTS"
        ));
        navigate(`/pages/transactions?dataIdx0=languageType&status${0}=${text}&dataIdx1=Status&status${1}=Serviced`)
    }


    const parseValue = (value) => {
        if (value === null || value === undefined) {
            return 0;
        }
        if (typeof value === 'string') {
            return parseFloat(value.replace(/,/g, ''));
        }
        return value;
    };
    
    const parseSort = (index) => {
        return (a, b) => {
            const aValue = parseValue(a[index]);
            const bValue = parseValue(b[index]);
            return aValue - bValue;
        };
    };

    // Columns configuration for the table
    const columns = [
        {
            title: 'Language Name',
            dataIndex: 'TargetLanguage',
            sorter: (a, b) => a?.TargetLanguage?.localeCompare(b?.TargetLanguage),
            ...GetColumnSearchProps({dataIndex: 'TargetLanguage', isDate:false})
        },
        {
            title: 'Total Calls',
            dataIndex: 'totalCalls',
            sorter: parseSort('totalCalls'),
            defaultSortOrder: 'descend'
        },
        {
            title: 'Total Minutes',
            dataIndex: 'serviceMins',
            sorter: parseSort('serviceMins'),
            render: (text) => {
                return (
                    <div>
                        { text ? text : '0' }
                    </div>
                    
                )
            }
        },
        {
            title: 'Calls by Audio',
            dataIndex: 'CountSuccessAudioCalls',
            sorter: parseSort('CountSuccessAudioCalls'),
        },
        {
            title: 'Calls by Video',
            dataIndex: 'CountSuccessVideoCalls',
            sorter: parseSort('CountSuccessVideoCalls'),
        },
        {
            title: 'Minutes by Audio',
            dataIndex: 'CountAudioMinute',
            sorter: parseSort('CountAudioMinute'),
        },
        {
            title: 'Minutes by Video',
            dataIndex: 'CountVideoMinute',
            sorter: parseSort('CountVideoMinute'),
        },
        {
            title: 'Completed Calls',
            dataIndex: 'TargetLanguage',
            align: "center",
            render: (text, record) => 
                <a 
                    onClick={() => handleNavigationClick(text)}
                    style={{textDecoration: "underline"}}>
                    {record?.CompletedCalls} <LinkOutlined />
                </a>,
        },
    ];

    return (
        <Row>
            <Col className='language-col' flex={"auto"}>
                <Row wrap={false} gutter={20}>
                    <Col className='volume-card' flex={"100%"}>
                       <Spin spinning={loading}>
                        <Card style={{borderRadius: 4, backgroundColor: colorBgLayout}}>
                                <LanguageDistributionBar 
                                    data={language?.distribution || []} 
                                    audioType={language?.audioType || {}}
                                    videoType={language?.videoType || {}}
                                />
                            </Card>
                       </Spin>
                    </Col>
                </Row>
                <br/>
                <Row>
                    <Col flex={"auto"}>
                        <Table
                            title={() => (
                                        <Row
                                            gutter={[8, 8]}
                                            justify="end"
                                            align="middle"
                                        >
                                            <Col>
                                                <DropDown
                                                    defaultValue={downloadType}
                                                    dropdownOptions={
                                                        downloadDropdownType
                                                    }
                                                    handleTypeSelect={(
                                                        downloadType
                                                    ) =>
                                                        setDownloadType(
                                                            downloadType
                                                        )
                                                    }
                                                />
                                            </Col>
                                            <Col>
                                                <Button
                                                    loading={loading}
                                                    type="primary"
                                                    onClick={downloadFile}
                                                >
                                                    Download
                                                </Button>
                                            </Col>
                                        </Row>
                            )}
                            className="voyce-custom-table"
                            style={{width: '100%', backgroundColor: colorBgLayout}}
                            dataSource={dataSource}
                            columns={columns}
                            size="small"
                            pagination={false}
                            loading={loading}
                            sticky
                            footer={() => (
                                <Row gutter={[16, 0]} style={{ justifyContent: 'space-between', backgroundColor: "#203870", color: "white" }}>
                                  <Col flex={"auto"}>Total Calls: {Formatter.numberWithCommas(sumTotalCalls)}</Col>
                                  <Col flex={"auto"}>Total Minutes: {Formatter.numberWithCommas(sumTotalMinutes)}</Col>

                                  <Col flex={"auto"}>Total Audio Calls: {Formatter.numberWithCommas(sumTotalAudioCalls)}</Col>
                                  <Col flex={"auto"}>Total Video Calls: {Formatter.numberWithCommas(sumTotalVideoCalls)}</Col>

                                  <Col flex={"auto"}>Total Audio Minutes: {Formatter.numberWithCommas(sumTotalAudioMinutes)}</Col>
                                  <Col flex={"auto"}>Total Video Minutes: {Formatter.numberWithCommas(sumTotalVideoMinutes)}</Col>

                                  <Col  flex={"auto"}>Total Completed Calls: {Formatter.numberWithCommas(sumTotalCompletedCalls)}</Col>
                                </Row>
                            )}
                        />
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}

export default AudioVideoReport; // Exporting the AudioVideoReport component