import ActionType from '../action.types';

/*
    Reducer function responsible for managing state related to different Company action types
*/

export default function company(state = {
    active: [],                     // List of active companies
    selected: undefined,            // Currently selected company
    client: { ClientId: '-1' },     // Default client ID
    timezone: undefined,            // Timezone information
    region: '-1',
    site: {SiteId: '-1'}
}, action) {
    switch (action.type) {
        // Update the list of active companies in the state
        case ActionType.SET_COMPANIES:
            return { ...state, active: action.companies };

        // Select a specific company and set it as 'selected'
        case ActionType.SELECT_COMPANY:
            return {
                ...state,
                selected: action.selected,
                client: action.client
            };

        // Select a specific client within the company
        case ActionType.SELECT_CLIENT:
            return { ...state, client: action.client };

        // Set the timezone information in the state
        case ActionType.SET_TIMEZONE:
            return { ...state, timezone: action.timezone };

        // Set the timezone information in the state
        case ActionType.SET_REGION:
            return { ...state, region: action.region, site: action.site };

        // Set the timezone information in the state
        case ActionType.SET_SITE:
            return { ...state, site: action.site };

        default:
            return state;
    }
}
