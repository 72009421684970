import ActionType from '../action.types';

/*
    The reducer defines initial state and handles various actions to update the state
*/

export default function analytics(state = {
    widgets: {},
    top5Languages: { legends: [], seriesData: [] },
    callsByHours: { legends: [], seriesData: [] },
    terpPositionCount: {
        position1: 0,
        position2: 0,
        position3: 0,
        position4plus: 0,
        rawData: []
    },
    totalActiveCarts: [],
    activeCarts: 0,
    avrBarChart: {
        legends: [],
        total: [],
        failed: [],
        success: []
    },
    avrPieChart: [],
    starRating: [],
}, action) {
    switch (action.type) {
        // Update widgets data in the state
        case ActionType.SET_WIDGETS:
            return { ...state, widgets: action.widgets };

        // Update top 10 languages data in the state
        case ActionType.SET_TOP5:
            return { ...state, top5Languages: action.top5Languages };

        // Update preferred video data in the state
        case ActionType.SET_PREFERRED_VIDEO:
            return { ...state, preferredVideo: action.preferredVideo };

        // Update calls by hours data in the state
        case ActionType.SET_CALLS_BY_HOURS:
            return { ...state, callsByHours: action.callsByHours };

        // Update active carts data in the state
        case ActionType.SET_ACTIVE_CARTS:
            return { ...state, activeCarts: action.activeCarts };

        // Update total active carts data in the state
        case ActionType.SET_TOTAL_ACTIVE_CARTS:
            return { ...state, totalActiveCarts: action.totalActiveCarts };

        // Update terp position count data in the state
        case ActionType.SET_TERP_POSITION_COUNT:
            return { ...state, terpPositionCount: action.terpPositionCount };

        // Update avr chart data in the state
        case ActionType.SET_AVR_CHART:
            return {
                ...state,
                avrBarChart: action.avrBarChart,
                avrPieChart: action.avrPieChart
            };

        // Update star rating data in the state
        case ActionType.SET_STAR_RATING: 
            return {
                ...state,
                starRating: action.starRating
            }
        
        // Reset state to initial values on logout
        case ActionType.LOGOUT:
            return {
                widgets: {},
                top10Languages: { legends: [], seriesData: [] },
                callsByHours: { legends: [], seriesData: [] },
                terpPositionCount: {
                    position1: 0,
                    position2: 0,
                    position3: 0,
                    position4plus: 0,
                    rawData: []
                },
                avrBarChart: {
                    legends: [],
                    total: [],
                    failed: [],
                    success: []
                },
                avrPieChart: []
            };

        default:
            return state;
    }
}
